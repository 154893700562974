
let key = "user";

export function defineValue(val) {
    let items = localStorage.getItem(key);
    if (items == null) {
        localUpdate(val);
    }
    return
}
export function getValue(){
    return JSON.parse(localStorage.getItem(key))
}
export function cleaningUser(){
    localStorage.removeItem(key)
}
export function localUpdate(item) {
    localStorage.setItem(key, JSON.stringify(item))
}
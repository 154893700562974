<template>
  <div class="main-content">
    <div class="container">
      <h2 class="gradient-color mb-4">Ayarlar</h2>
      <form @submit.prevent="submit">
        <div class="row">
          <h3 class="col-12 mb-4">Şifre Güncelleme</h3>
          <div class="col-12 col-md-4">
            <b-form-group class="input-icon" label="Eski Şifre">
              <i class="ri-lock-line"></i>
              <b-form-input
                @focus="show = false"
                :state="validateState($v.oldPassword)"
                v-model="$v.oldPassword.$model"
                id="old-password"
                :type="oldVisibility2"
                autocomplete="on"
              ></b-form-input>
              <i
                class="ri-eye-off-line"
                @click="showOldPassword()"
                v-if="oldVisibility2 == 'password'"
              ></i>
              <i
                class="ri-eye-line"
                @click="hideOldPassword()"
                v-if="oldVisibility2 == 'text'"
              ></i>
              <!--<ShowError
                v-model="show"
                :text="errorResponse.message ? (errorResponse.message.oldPassword ? errorResponse.message.oldPassword[0] : '') : ''
                "
              ></ShowError>-->
            </b-form-group>
          </div>
          <div class="col-12 col-md-4">
            <b-form-group class="input-icon" label="Yeni Şifre">
              <i class="ri-lock-line"></i>
              <b-form-input
                @focus="show = false"
                :state="validateState($v.password)"
                v-model="$v.password.$model"
                id="new-password"
                :type="visibility"
                autocomplete="on"
              ></b-form-input>
              <i
                class="ri-eye-off-line"
                @click="showPassword()"
                v-if="visibility == 'password'"
              ></i>
              <i
                class="ri-eye-line"
                @click="hidePassword()"
                v-if="visibility == 'text'"
              ></i>
              <!--<ShowError
                v-model="show"
                :text="
                  errorResponse.message ? (errorResponse.message.password ? errorResponse.message.password[0] : '') :''
                "
              ></ShowError>-->
            </b-form-group>
          </div>
          <div class="col-12 col-md-4">
            <b-form-group class="input-icon" label="Yeni Şifre Tekrar">
              <i class="ri-lock-line"></i>
              <b-form-input
                @focus="show = false"
                :state="validateState($v.repeatPassword)"
                v-model="$v.repeatPassword.$model"
                id="input-repeatPassword"
                :type="RepeatVisibility"
                autocomplete="on"
              ></b-form-input>
              <i
                class="ri-eye-off-line"
                @click="showRepeatPassword()"
                v-if="RepeatVisibility == 'password'"
              ></i>
              <i
                class="ri-eye-line"
                @click="hideRepeatPassword()"
                v-if="RepeatVisibility == 'text'"
              ></i>
              <!--<ShowError
                v-model="show"
                :text="
                  errorResponse.message ? (errorResponse.message.password ? errorResponse.message.password[0] : ''):''
                "
              ></ShowError>-->
            </b-form-group>
          </div>
        </div>
        <div class="text-right">
          <button
            type="submit"
            @click="passwordChange()"
            class="btn btn-primary"
          >
          <b-spinner
              label="Spinning"
              class="align-middle top-minus-1 mr-3"
              small
              v-if="loading"
            ></b-spinner>
            {{loading?"":"Şifre Değiştir"}}
          </button>
        </div>
        <!--<div class="row">
          <h3 class="col-12 mb-4">Bölge ve Dil</h3>
          <div class="col-12 col-md-6">
            <b-form-group label="Bölge">
              <template>
                <div>
                  <multiselect
                    v-on:input="validFunc"
                    :class="
                      valid
                        ? {
                            'is-valid': valid == 'valid',
                            'is-invalid': valid == 'invalid',
                          }
                        : ''
                    "
                    placeholder="Seçiniz"
                    v-model="selected"
                    :options="options1"
                    :select-label="''"
                    :selected-label="''"
                    deselect-label=""
                    :allow-empty="false"
                    :searchable="false"
                  >
                    <span slot="noResult"> Sonuç bulunamadı </span>
                  </multiselect>
                </div>
              </template>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group label="Dil">
              <template>
                <div>
                  <multiselect
                    v-on:input="valid2Func"
                    :class="
                      valid2
                        ? {
                            'is-valid': valid2 == 'valid',
                            'is-invalid': valid2 == 'invalid',
                          }
                        : ''
                    "
                    placeholder="Seçiniz"
                    v-model="selected2"
                    :options="options2"
                    :select-label="''"
                    :selected-label="''"
                    deselect-label=""
                    :allow-empty="false"
                    :searchable="false"
                  >
                    <span slot="noResult"> Sonuç bulunamadı </span>
                  </multiselect>
                </div>
              </template>
            </b-form-group>
          </div>
        </div>
        <div class="text-right">
          <button
            type="submit"
            @click="reglangUpdate()"
            class="btn btn-primary"
          >
            Güncelle
          </button>
        </div>-->

        <!-- <div class="row">
           <h3 class="col-12 mb-4">Öğrenim Planı</h3>
               <div class="col-12">
                 <b-form-group>
                    <template>
                      <div>
                         <multiselect 
                        v-on:input="valid3Func" :class="{'is-valid':valid3=='valid', 'is-invalid':valid3=='invalid'}"
                          placeholder="Seçiniz"
                          v-model="selected3"
                          :options="options3" 
                          :select-label="''" 
                          :selected-label="''" 
                          deselect-label="" 
                          :allow-empty="false"
                          :searchable="false"
                        >
                          <span slot="noResult">
                            Sonuç bulunamadı
                          </span>
                        </multiselect>
                      </div>
                    </template>
                </b-form-group>
            </div>
         </div> -->

        <div class="row">
          <h3 class="col-12 mb-4">Bildirim Ayarları</h3>
          <!-- <div v-if="error != null" class="col-12 alert alert-error mb-4" role="alert">
        <div class="alert-content w-100 d-inline-block">
          <div class="my-1">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </div>
      <div v-if="success != null" class="col-12 alert alert-success mb-4" role="alert">
        <div class="alert-content w-100 d-inline-block">
          <div class="my-1">
            <strong>
              {{ success }}
            </strong>
          </div>
        </div>
      </div> -->
          <div class="col-12">
            <div class="row custom-switch-grid">
              <div
                class="col-12 col-md-6 mb-4"
                v-for="(item, index) in settings"
                :key="index"
              >
                <!--
          <h3 class="mb-4">{{ item.type }}</h3>
          -->
                <h4 class="mb-4">
                  {{ fieldNameTr[index] }}
                </h4>
                <div class="form-group custom-control custom-switch pl-0">
                  <label
                    :for="item.type + 'email'"
                    class="mr-3 pt-1 text-label"
                  >
                    E-Posta
                  </label>
                  <input
                    :id="item.type + 'email'"
                    v-model="item.value.send_email"
                    true-value="on"
                    false-value="off"
                    @input="
                      saveSettings(item, 'send_email', item.value.send_email)
                    "
                    type="checkbox"
                    class="custom-control-input"
                  />
                  <label
                    :for="item.type + 'email'"
                    class="custom-control-label"
                  ></label>
                </div>
                <div class="form-group custom-control custom-switch pl-0">
                  <label :for="item.type + 'sms'" class="mr-3 pt-1 text-label">
                    Sms
                  </label>
                  <input
                    :id="item.type + 'sms'"
                    v-model="item.value.send_sms"
                    true-value="on"
                    false-value="off"
                    @input="saveSettings(item, 'send_sms', item.value.send_sms)"
                    type="checkbox"
                    class="custom-control-input"
                  />
                  <label
                    :for="item.type + 'sms'"
                    class="custom-control-label"
                  ></label>
                </div>
                <div class="form-group custom-control custom-switch pl-0">
                  <label
                    :for="item.type + 'activity'"
                    class="mr-3 pt-1 text-label"
                  >
                    Bildirim
                  </label>
                  <input
                    :id="item.type + 'activity'"
                    v-model="item.value.instantly_send"
                    true-value="on"
                    false-value="off"
                    @input="
                      saveSettings(
                        item,
                        'instantly_send',
                        item.value.instantly_send
                      )
                    "
                    type="checkbox"
                    class="custom-control-input"
                  />
                  <label
                    :for="item.type + 'activity'"
                    class="custom-control-label"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <AlertDialogModal v-model="visible">
      <template v-slot:AlertDialogContent>
        <div v-if="errorStatus == 'success'">
          <i
            class="
              ri-checkbox-circle-fill
              text-success
              mr-4 mr-sm-3
              align-middle
              ri-lg
            "
          ></i
          >Değişiklikler başarıyla kaydedildi.
        </div>
          <div
            v-if="errorStatus == 'error'"
            role="alert"
          >
            <div class="">
              <div
                class="my-1"
                v-for="(error, eIndex) of error.message"
                :key="`error_${eIndex}`"
              >
                <strong>
                  <i
            class="
              ri-close-circle-fill
              text-danger
              mr-4 mr-sm-3
              align-middle
              ri-lg
            "
          ></i>{{ error || "Bir hata oluştu, lütfen tekrar deneyin." }}
                </strong>
              </div>
            </div>
        </div>
      </template>
    </AlertDialogModal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import { not, required, minLength, sameAs } from "vuelidate/lib/validators";
import { flattenDeep } from "lodash";

export default {
  name: "Ayarlar",

  Modalcomponent: {
    Multiselect,
  },
  mixins: [validationMixin],
  data() {
    return {
      loading:false,
      visible: false,
      show: false,
      errorResponse: {},
      valid: "",
      valid2: "",
      valid3: "",
      size: null,
      options1: [],
      options2: [],
      options3: ["Seçenek 1", "Seçenek 2", "Seçenek 3"],
      settings: null,
      fieldNameTr: ["Yorum", "Hatırlatma", "Video", "Genel"],
      password: null,
      repeatPassword: null,
      oldPassword: null,
      type: "password",
      email: null,
      visibility: "password",
      RepeatVisibility: "password",
      oldVisibility2: "password",
      selected: null,
      selected2: null,
      selected3: null,
      inputEvaluated: null,
      inputEvaluated2: null,
      inputEvaluated3: null,
      error: [],
      errorStatus:""
    };
  },
  validations: {
    oldPassword: {
      required,
      minLength: minLength(6),
    },
    password: {
      required,
      minLength: minLength(6),
      sameAsNotPassword: not(sameAs("oldPassword")),
    },
    repeatPassword: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs("password"),
      sameAsNotPassword: not(sameAs("oldPassword")),
    },
  },
  created() {
    this.fetchSettings();
    this.getRegionAndLang();
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    showPassword() {
      this.visibility = "text";
    },
    hidePassword() {
      this.visibility = "password";
    },
    showRepeatPassword() {
      this.RepeatVisibility = "text";
    },
    hideRepeatPassword() {
      this.RepeatVisibility = "password";
    },
    showOldPassword() {
      this.oldVisibility2 = "text";
    },
    hideOldPassword() {
      this.oldVisibility2 = "password";
    },
    validFunc: function () {
      if (this.inputEvaluated) {
        this.valid = "invalid";
      } else {
        this.valid = "valid";
      }
    },
    valid2Func: function () {
      if (this.inputEvaluated2) {
        this.valid2 = "invalid";
      } else {
        this.valid2 = "valid";
      }
    },
    valid3Func: function () {
      if (this.inputEvaluated3) {
        this.valid3 = "invalid";
      } else {
        this.valid3 = "valid";
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
      if (this.valid == "valid") {
        this.valid = "valid";
      } else {
        this.valid = "invalid";
      }
      if (this.valid2 == "valid") {
        this.valid2 = "valid";
      } else {
        this.valid2 = "invalid";
      }
      if (this.valid3 == "valid") {
        this.valid3 = "valid";
      } else {
        this.valid3 = "invalid";
      }
      if (this.termCheck == "invalid") {
        this.termCheck = "invalid";
      } else {
        this.termCheck = "valid";
      }
    },
    successModal() {
      this.$bvModal.show("SuccessModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("SuccessModal");
      }, modalTimeoutSeconds * 1000);
    },
    dangerModal() {
      this.$bvModal.show("DangerModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("DangerModal");
      }, modalTimeoutSeconds * 1000);
    },
    passwordChange() {
      this.loading = true;
      this.axios
        .put("/user/password/change", {
          oldPassword: this.oldPassword,
          password: this.password,
          password_confirmation: this.repeatPassword,
        })
        .then((response) => {
          console.log(response);
          this.show = true;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.loading = false;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.status == "error") {
              error.response.data.message = flattenDeep(
                Object.keys(error.response.data.message).map((key) => {
                  return error.response.data.message[key];
                })
              );
              error.response.data.message.splice(1,error.response.data.message.length);
              this.error = error.response.data;
              this.errorStatus = error.response.data.status
              console.log(this.error)
            } else {
              return;
            }
          }

          this.visible = true;
          setTimeout(() => {
            this.visible = false;
          }, 2000);
          setTimeout(() => {
            this.errorStatus = "";
          }, 3000);
          this.show = true;
        });
    },
    getRegionAndLang() {
      this.axios
        .get("/user/settings/regional-and-language")
        .then((response) => {
          response.data.data.countries.map((country) =>
            this.options1.push(country.name)
          );
          response.data.data.languages.map((lang) =>
            this.options2.push(lang.name)
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reglangUpdate() {
      console.log("api bekleniyor");
    },
    async fetchSettings() {
      await this.axios("/notification/settings")
        .then((response) => {
          this.settings = response.data.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    async saveSettings(item, field, value) {
      await this.axios
        .post("/notification/settings", {
          type: item.type,
          field: field,
          value: value == "on" ? "off" : "on",
        })
        .then((response) => {
          console.log(response.data);
          this.fetchSettings();
          this.successModal();
        })
        .catch((error) => {
          console.log(error);
          this.dangerModal();
        });
    },
  },
};
</script>



export const Getters = {
  getUserData(state) {
    return state.user;
  },
  getSettings: (state) => (key) => {
    let data = state.settings
    let selected = [];
    if (data) {
      selected = data.filter(x => {
        if (x.key == key) {
          return true
        }
      })
      if (selected.length == 0 || selected == null) return
      let result = { key: selected[0].key, value: selected[0].value }
      return result
    }
  },
  checkId: (state) => (id) => {
    let data = state.coursesId
    if (data == null || data.length == 0) return false;
    return data.includes(id)
  }
};

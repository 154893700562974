<template>
  <div class="main-content">
    <div class="container">
      <h2 class="gradient-color mb-4">Kurslarım</h2>
      <div>
        <b-tabs content-class="py-4">
          <b-tab title="Tüm Kurslar" active>
            <div>
              <div class="row mb-3 md-lg-2">
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                  <div class="search-input position-relative w-100 form-group">
                    <b-form-input class="mr-sm-2 w-100" placeholder="Kurslarımda Ara" v-model="filter.search" @input="fetchFilteredCourses"></b-form-input>
                    <button class="btn">
                      <i class="ri-search-line"></i>
                    </button>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                  <b-form-group>
                    <template>
                      <div>
                        <multiselect placeholder="Sırala" v-model="filter.order.selected" :options="filter.order.options" :select-label="''" :selected-label="''" deselect-label :allow-empty="false" label="name" @input="fetchFilteredCourses">
                          <span slot="noResult">Sonuç bulunamadı</span>
                        </multiselect>
                      </div>
                    </template>
                  </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <b-form-group>
                    <template>
                      <div>
                        <multiselect placeholder="Kategori" v-model="filter.category.selected" :options="filter.category.options" :select-label="''" :selected-label="''" deselect-label :allow-empty="false" label="name" @input="fetchFilteredCourses">
                          <span slot="noResult">Sonuç bulunamadı</span>
                        </multiselect>
                      </div>
                    </template>
                  </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <b-form-group>
                    <template>
                      <div>
                        <multiselect placeholder="İlerleme Durumu" v-model="filter.progress.selected" :options="filter.progress.options" :select-label="''" :selected-label="''" deselect-label :allow-empty="false" label="name" @input="fetchFilteredCourses">
                          <span slot="noResult">Sonuç bulunamadı</span>
                        </multiselect>
                      </div>
                    </template>
                  </b-form-group>
                </div>
                <!--  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <b-form-group>
                    <template>
                      <div>
                        <multiselect 
                          placeholder="Eğitmen"
                          v-model="filter.instructor.selected"
                          :options="filter.instructor.options" 
                          :select-label="''" 
                          :selected-label="''" 
                          deselect-label="" 
                          :allow-empty="false"
                          @input="fetchFilteredCourses"
                        >
                          <span slot="noResult">Sonuç bulunamadı</span>
                        </multiselect>
                      </div>
                    </template>
                  </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <b-form-group>
                    <template>
                      <div>
                        <multiselect 
                          placeholder="Durum"
                          v-model="filter.status.selected"
                          :options="filter.status.options" 
                          :select-label="''" 
                          :selected-label="''" 
                          deselect-label="" 
                          :allow-empty="false"
                          label="name"
                          @input="fetchFilteredCourses"
                        >
                          <span slot="noResult">Sonuç bulunamadı</span>
                        </multiselect>
                      </div>
                    </template>
                  </b-form-group>
                </div>-->
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <button type="button" class="btn d-flex btn-link btn-block justify-content-center" @click="fetchCourses">Sıfırla</button>
                </div>
              </div>
              <div class="row" v-if="courses.length > 0">
                <CourseCard v-for="course in courses" :key="course.id" :course="course" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-30"></CourseCard>

                <!-- row end -->
              </div>
              <SmallLoader :courses="courses">
                <template #text>Henüz kurs bulunmuyor.</template>
              </SmallLoader>
              <div class="overflow-auto mt-5">
                <b-pagination-nav v-model="currentPage" :number-of-pages="page || 1" :per-page="16" :link-gen="linkGen" use-router align="center" first-text prev-text next-text last-text></b-pagination-nav>
              </div>
            </div>
          </b-tab>
          <b-tab title="Arşiv" @click="fetchArchivedCourses">
            <div v-if="archivedCoursesActive.length > 0" class="row">
              <CourseCard v-for="archivedCourse in archivedCoursesActive" :key="'-' + archivedCourse.id" :course="archivedCourse" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-30"></CourseCard>

              <!--  <div class="text-capitalize text-truncate">
                      <a href="#" class="color-second"> John Doe </a>
                    </div>
                    <div
                      class="
                        text-capitalize
                        color-second
                        text-truncate text-small
                        mb-3
                      "
                    >
                      Game Designer
              </div>-->
              <!-- <div class="mb-1">
                      <b-progress
                        :value="course.progress.percentage"
                        :max="max"
                        class="bg-third"
                        variant="success"
                      ></b-progress>
                    </div>
                    <div
                      class="
                        card-star-rating
                        d-flex
                        justify-content-between
                        align-items-center
                      "
                    >
                      <b class="mb-0 pt-2">
                        %{{
                          course.progress ? course.progress.percentage : 0
                        }}</b
                      >
                       <star-rating
                          v-model="course.participant.rating"
                          @rating-selected="setRating(course.id, course.participant.rating)"
                          :star-points="[
                            23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50,
                            36, 34, 46, 19, 31, 17,
                          ]"
                        ></star-rating>
              </div>-->
            </div>
            <SmallLoader :courses="archivedCoursesActive">
              <template #text>Henüz arşivde kurs bulunmuyor.</template>
              <template #ico>
                <i class="ri-star-line ri-5x color-third"></i>
              </template>
            </SmallLoader>
             <div class="overflow-auto mt-5">
                <b-pagination-nav
                  v-model="currentPageArchive"
                  :number-of-pages="page2 || 1"
                  :per-page="16"
                  :link-gen="linkGen"
                  use-router
                  align="center"
                  first-text=""
                  prev-text=""
                  next-text=""
                  last-text=""
                ></b-pagination-nav>
              </div>
          </b-tab>
          <b-tab title="Favoriler" @click="fetchFavoritedCourses">
            <div>
              <div v-if="
                  typeof favoritedCourses === 'object'
                    ? favoritedCoursesActive.length > 0
                    : false
                " class="row">
                <CourseCard v-for="favoritedCourse in favoritedCoursesActive" :key="favoritedCourse.id" :course="favoritedCourse" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-30"></CourseCard>

                <!--  <div class="text-capitalize text-truncate">
                      <a href="#" class="color-second"> John Doe </a>
                    </div>
                    <div
                      class="
                        text-capitalize
                        color-second
                        text-truncate text-small
                        mb-3
                      "
                    >
                      Game Designer
                </div>-->
              </div>
            </div>
            <SmallLoader :courses="favoritedCourses">
              <template #text>Henüz favorilerde kurs bulunmuyor.</template>
              <template #ico>
                <i class="ri-star-line ri-5x color-third"></i>
              </template>
            </SmallLoader>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!-- Modal -->
    <SuccessModal></SuccessModal>
    <DangerModal></DangerModal>
  </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Kurslarim",
  data() {
    return {
      currentPage: 1,
      currentPageArchive:1,
      success: null,
      error: null,
      courses: [],
      courseSize: "",
      archivedCourses: {},
      archivedCoursesActive: [],
      favoritedCoursesActive: [],
      favoritedCourses: undefined,
      keyPage: 1,
      numberPage: null,
      page:1,
      page2:1,
      filter: {
        order: {
          options: [
            {
              name: "Yakın Zamanda Erişilen",
              value: "last_watched",
            },
            {
              name: "Yakın Zamanda Kayıt Yapılan",
              value: "last_record",
            },
            {
              name: "Başlık: A-Z",
              value: "a_z",
            },
            {
              name: "Başlık: Z-A",
              value: "z_a",
            },
          ],
          selected: "",
        },
        category: {
          options: [],
          selected: "",
        },
        progress: {
          options: [
            {
              name: "Henüz Başlanmayan",
              value: 0,
            },
            {
              name: "Devam Eden",
              value: 50,
            },
            {
              name: "Tamamlanan",
              value: 100,
            },
          ],
          selected: "",
        },
        instructor: {
          options: [],
          selected: "",
        },
        status: {
          options: [
            {
              name: "Aktif",
              value: "A",
            },
            {
              name: "Pasif",
              value: "P",
            },
          ],
          selected: "",
        },
        search: "",
      },
      value: 32.333333333,
      settings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  created() {
    this.fetchCourses();
    this.fetchCategories();
    this.$store.dispatch("fetchFavoritedCourses");
  },
  methods: {
    successModal() {
      this.$bvModal.show("SuccessModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("SuccessModal");
      }, modalTimeoutSeconds * 1000);
    },
    dangerModal() {
      this.$bvModal.show("DangerModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("DangerModal");
      }, modalTimeoutSeconds * 1000);
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    fetchCourses() {
      this.axios
        .get("/course/my-courses?perPage=16&page=" + this.currentPage)
        .then((response) => {
          this.courses = response.data.data.data;
          this.page = Math.ceil(
            response.data.data.total / response.data.data.per_page
          );
          this.courseSize = Object.keys(this.courses).length;
          this.numberPage = response.data.data.total;
        })
        .catch((error) => {
          console.log(error.response.data || error);
        });

      this.filter.order.selected = "";
      this.filter.category.selected = "";
      this.filter.progress.selected = "";
      this.filter.instructor.selected = "";
      this.filter.status.selected = "";
      this.filter.search = "";
    },
    fetchCategories() {
      this.axios
        .get("/course/categories")
        .then((response) => {
          if (response.data && response.data && response.data.data.length) {
            this.filter.category.options = response.data.data.map((item) => ({
              name: item.category,
              value: item.id,
            }));
          } else {
            this.filter.category.options = [];
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    setRating(id, rating) {
      this.axios
        .post("/course/rating", {
          course_id: id,
          rating: rating,
        })
        .then((response) => {
          this.rating = response.data.data;
          this.successModal();
        })
        .catch((error) => {
          console.log(error.response.data);
          this.dangerModal();
        });
    },
    fetchFavoriteInfo(id) {
      this.axios
        .get("/course/" + id + "/favorite")
        .then((response) => {
          this.courses.find((course) => course.id === id).is_favorite =
            response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },

    fetchArchiveInfo(id) {
      this.axios
        .get("/course/" + id + "/archive")
        .then((response) => {
          this.courses.find((course) => course.id === id).is_archived =
            response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    addToArchive(id) {
      this.axios
        .post("/course/archive", {
          params: {
            course_id: id,
            archive: true,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.fetchArchiveInfo(id);
          this.successModal();
        })
        .catch((error) => {
          console.log(error.response.data);
          this.dangerModal();
        });
    },
    removeFromArchive(id) {
      this.axios
        .delete("/course/archive", {
          params: {
            course_id: id,
          },
        })
        .then((response) => {
          response;
          this.fetchArchiveInfo(id);
          this.fetchArchivedCourses();
          this.successModal();
        })
        .catch((error) => {
          console.log(error.response.data);
          this.dangerModal();
        });
    },
    fetchArchivedCourses() {
      this.axios.get("/course/my-archive?perPage=16&page=" + this.currentPageArchive).then((response) => {
        
        let resData = response.data.data.data;
        this.page2 =Math.ceil(response.data.data.total/response.data.data.per_page);
        this.archivedCoursesActive = Object.values(resData);
      });
    },
    fetchFavoritedCourses() {
      this.axios
        .get("/course/my-favorites")
        .then((response) => {
          this.favoritedCourses = response.data.data;
          let values = Object.values(this.favoritedCourses);
          this.favoritedCoursesActive = values;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    fetchFilteredCourses() {
      this.axios
        .get("/course/my-courses", {
          params: {
            order: this.filter.order.selected.value,
            category: this.filter.category.selected.value,
            progress: this.filter.progress.selected.value,
            instructor: this.filter.instructor.selected.id,
            status: this.filter.status.selected.value,
            search: this.filter.search,
          },
        })
        .then((response) => {
          this.courses = response.data.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    nameWithLang() {
      return `${this.filter.category.options.category}`;
    },
  },
  computed: {
    changeValue() {
      return this.$store.state.coursesId;
    },
  },
  watch: {
    changeValue() {
      setTimeout(() => {
        this.fetchFavoritedCourses();
        this.fetchCourses();
      }, 500);
    },
    currentPage() {
      this.fetchCourses();
    },
    currentPageArchive(){
      this.fetchArchivedCourses()
    }
  },
};
</script>
import SectionAccordion from "./SectionsAccordion"
import Modal from "./modal/Modal"
import AlertModal from "./modal/AlertModal"
import CropperModal from "./modal/CropperModal"
import DangerModal from "./modal/DangerModal"
import DeleteModal from "./modal/DeleteModal"
import ExamEnd from "./modal/ExamEnd"
import ForgorPassword from "./modal/ForgotPassword"
import GeneralModal from "./modal/GeneralModal"
import LoginModal from "./modal/LoginModal"
import ModalXl from "./modal/ModalXl"
import Questions from "./modal/Questions"
import RegisterModal from "./modal/RegisterModal"
import ShareModal from "./modal/shareModal"
import SuccessModal from "./modal/SuccessModal"
import SpinnerModal from "./modal/SpinnerModal"
import ShowError from "./ShowError"
import CourseCard from "./CourseCard"
import SmallLoader from "./SmallLoader"
import Search from "./Search"
import AlertDialogModal from "./modal/AlertDialogModal"
import LoginRegisterModal from "./modal/LoginRegisterModal"
import BasketCard from "./BasketCard"

export default{
    install:function(Vue){
        Vue.component('sections', SectionAccordion)
        Vue.component('modal',Modal)
        Vue.component('AlertModal',AlertModal)
        Vue.component('CropperModal',CropperModal)
        Vue.component('DangerModal',DangerModal)
        Vue.component('DeleteModal',DeleteModal)
        Vue.component('ExamEnd',ExamEnd)
        Vue.component('ForgotPassword',ForgorPassword)
        Vue.component('GeneralModal',GeneralModal)
        Vue.component('LoginModal',LoginModal)
        Vue.component('ModalXl',ModalXl)
        Vue.component('Questions',Questions)
        Vue.component('RegisterModal',RegisterModal)
        Vue.component('ShareModal',ShareModal)
        Vue.component('SuccessModal',SuccessModal)
        Vue.component("SpinnerModal",SpinnerModal)
        Vue.component("ShowError",ShowError)
        Vue.component("CourseCard",CourseCard)
        Vue.component("SmallLoader",SmallLoader)
        Vue.component("Search",Search)
        Vue.component("AlertDialogModal",AlertDialogModal)
        Vue.component("LoginRegisterModal",LoginRegisterModal)
        Vue.component("BasketCard",BasketCard)
    }
}
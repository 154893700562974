<template>
  <div class="main-content">
    <div class="container">
      <h2 class="gradient-color mb-4">Ödeme Bilgileri</h2>
      <!-- odeme baslangic -->
      <div>
        <!-- Gostermek icin d-flex eklenmeli -->
        <!-- <div class="spinner over bg-transparent py-5 mt-5 position-relative d-none">
                  <b-spinner label="Spinning" class="brand-color mt-5 mb-1"></b-spinner>
        </div>-->
        <!-- basarılı durumunda d-none yerine d-flex gelmeli -->
        <div v-if="status == 'purchased'" class="pt-5 text-center align-items-center justify-content-center d-flex show">
          <div>
            <div>
              <i class="ri-checkbox-circle-fill ri-6x color-green"></i>
            </div>
            <div class="h3 d-block mb-5 pb-4">Ödeme başarıyla gerçekleşti.</div>
            <router-link to="/">
              <u>Anasayfaya Dön</u>
            </router-link>
          </div>
        </div>
        <!-- basarisiz durumunda d-none yerine d-flex gelmeli -->
        <div v-else-if="status == 'errored'" class="pt-5 text-center align-items-center justify-content-center">
          <div>
            <div>
              <i class="ri-close-circle-fill ri-6x color-red"></i>
            </div>
            <div class="h3 d-block mb-5 pb-4">Ödeme başarısız oldu.</div>
            <a>
              <u>Lütfen tekrar deneyin</u>
            </a>
          </div>
        </div>
        <!-- bos sepet -->
        <form v-if="status == 'started'" @submit.prevent="submit" class="fadeIn d-block show">
          <div class="row">
            <!-- spinner gosterildiginde d-none gelmeli -->
            <div class="col-12 col-lg-9">
              <div class="mb-5">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <b-form-group class="input-icon" label="Kart Numarası">
                      <i class="ri-bank-card-2-line"></i>
                      <b-form-input @input="onInput" @focus="show = false" :state="validateState($v.cardNumber)" v-model="$v.cardNumber.$model" v-mask="'#### #### #### ####'"></b-form-input>
                      <!--<ShowError
                        v-model="show"
                        :text="
                          errContent.message
                            ? errContent.message.cardNumber
                              ? errContent.message.cardNumber[0]
                              : ''
                            : ''
                        "
                      ></ShowError>-->
                    </b-form-group>
                  </div>
                  <div class="col-12 col-md-6">
                    <b-form-group class="input-icon" label="Kart Üzerindeki İsim">
                      <i class="ri-user-3-line"></i>
                      <b-form-input @focus="show = false" :state="validateState($v.cardName)" v-model="$v.cardName.$model" id="nameOnCard" v-on:keyup="validateAlpha()"></b-form-input>
                      <!--<ShowError
                        v-model="show"
                        :text="
                          errContent.message
                            ? errContent.message.holderName
                              ? errContent.message.holderName[0]
                              : ''
                            : ''
                        "
                      ></ShowError>-->
                    </b-form-group>
                  </div>
                  <div class="col-12 col-md-4">
                    <b-form-group label="Ay">
                      <template>
                        <div>
                          <b-form-input @focus="show = false" v-on:input="validFunc" :class="{
                              'is-valid': valid == 'valid',
                              'is-invalid': valid == 'invalid',
                            }" placeholder="12" v-model="selected">
                            <span slot="noResult">Sonuç bulunamadı</span>
                          </b-form-input>
                          <!--<ShowError
                            v-model="show"
                            :text="
                              errContent.message
                                ? errContent.message.expriy
                                  ? errContent.message.expriy[0]
                                  : ''
                                : ''
                            "
                          ></ShowError>-->
                        </div>
                      </template>
                    </b-form-group>
                  </div>
                  <div class="col-12 col-md-4">
                    <b-form-group label="Yıl">
                      <template>
                        <div>
                          <b-form-input @focus="show = false" v-on:input="valid2Func" :class="{
                              'is-valid': valid2 == 'valid',
                              'is-invalid': valid2 == 'invalid',
                            }" placeholder="2020" v-model="selected2">
                            <span slot="noResult">Sonuç bulunamadı</span>
                          </b-form-input>
                          <!--<ShowError
                            v-model="show"
                            :text="
                              errContent.message
                                ? errContent.message.expiryYear
                                  ? errContent.message.expiryYear[0]
                                  : ''
                                : ''
                            "
                          ></ShowError>-->
                        </div>
                      </template>
                    </b-form-group>
                  </div>
                  <div class="col-12 col-md-4">
                    <b-form-group class="input-icon" label="CVC">
                      <i class="ri-bank-card-line"></i>
                      <b-form-input @focus="show = false" :state="validateState($v.cardCvc)" v-model="$v.cardCvc.$model" v-mask="'###'"></b-form-input>
                      <!--<ShowError
                        v-model="show"
                        :text="
                          errContent.message
                            ? errContent.message.cvc
                              ? errContent.message.cvc[0]
                              : ''
                            : ''
                        "
                      ></ShowError>-->
                    </b-form-group>
                  </div>
                  <div class="col-12" v-show="tkstKontrol.length === 6" id="taksit-sayisi">
                    <b-form-group label="Taksit Sayısı">
                      <template>
                        <div>
                          <multiselect v-on:input="valid3Func" :class="{
                              'is-valid': valid3 == 'valid',
                              'is-invalid': valid3 == 'invalid',
                            }" placeholder="Seçiniz" v-model="selected3" :options="installments" :select-label="''" :selected-label="''" deselect-label :custom-label="installmentCalc" :searchable="false">
                            <span slot="noResult">Sonuç bulunamadı</span>
                          </multiselect>
                        </div>
                        <div v-if="show" class="show-error">
                          {{
                          statusContent.message
                          ? statusContent.message.installment
                          ? statusContent.message.installment[0]
                          : ""
                          : ""
                          }}
                        </div>
                      </template>
                    </b-form-group>
                  </div>
                  <div class="col-12">
                    <div class="checkbox-group reverse-hover">
                      <input ref="checkLabel" type="checkbox" class="check-group" name="check" :true-value="true" :false-value="false" v-on:input="termCheckFunc" :class="{
                          'is-valid': termCheck == 'invalid',
                          'is-invalid': termCheck == 'valid',
                        }" />
                      <label class="form-check-label" for="check">
                        <span class="check"></span>
                        <span class="box"></span>
                        <a href="#" class="position-relative z-index-top d-inline-block" v-b-modal.Odeme @click="preInfo()">
                          <u>Ön Bilgilendirme</u>
                        </a>,
                        <a class="position-relative z-index-top d-inline-block" v-b-modal.Odeme @click="saleInfo()">
                          <u>Mesafeli Satış Sözleşmesi</u>
                        </a>
                        ve
                        <a class="position-relative z-index-top d-inline-block" v-b-modal.Odeme @click="returnInfo()">
                          <u>İptal-İade Şartları</u>
                        </a>nı okudum, kabul ediyorum.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-items mb-5">
                <h3 class="mb-4">Sipariş Detayı</h3>
                <div class="row mb-5" v-for="item in allBasketData" :key="item.id">
                  <div class="col-12 col-sm-3 mb-3 mb-sm-0 pr-3 pr-sm-1 pr-md-3">
                    <a href="#">
                      <div class="bg-image embed-responsive embed-responsive-16by9 rounded" :style="{
                          backgroundImage: 'url(' + item.picture_link + ')',
                        }"></div>
                    </a>
                  </div>
                  <div class="col-12 col-sm-9 d-md-flex justify-content-between">
                    <div class="cart-item-info d-flex flex-column pr-md-4">
                      <div class="font-weight-bold ellipsis-1 mb-2">
                        <a href="#" class="d-block">{{ item.title }}</a>
                      </div>
                      <div class="text-capitalize text-truncate ellipsis-2 mb-2 color-second white-space text-small">
                        <a href="#" class="color-second" v-for="(instructor, i) in item.instructors" :key="i">Eğitmen: {{ instructor.user.full_name }}</a>
                      </div>
                    </div>
                    <div class="cart-items-price pr-0 pr-md-3">
                      <div class="align-items-center d-flex d-md-block mt-2 mt-sm-3 mt-md-0">
                        <!--<div class="h3 color-second mb-0 mr-3 mr-md-0">
                          <s>59,99₺</s>
                        </div>-->
                        <div class="h3 mb-0">{{ item.price }}₺</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="mb-3 pb-1">
                <div class="mb-2 pb-1 font-weight-bold">
                  <!--Tutar: {{ allCoursePrice }}₺-->
                </div>
                <div class="mb-2 pb-1 font-weight-bold">
                  <!-- Kurs İndirimi
                  <span class="color-second text-small">(%20)</span>:
                  <span class="color-green ml-2">20₺</span>
                  -->
                </div>
                <!-- Kupon indirimi olduğu zaman bu alanda gözükmelidir -->
                <!-- <div class="mb-2 pb-1 font-weight-bold">Kupon İndirimi: <span class="color-green ml-2">10₺</span></div> -->
                <div class="h3 mb-0 d-inline-block">Toplam Tutar: {{ allCoursePrice }}₺</div>
              </div>
              <div class="mb-3">
                <button class="btn btn-primary btn-block" @click="paymentComplete()" type="submit">
                  <b-spinner label="Spinning" class="top-minus-1 align-middle mr-3" small v-if="showSpinner"></b-spinner>
                  {{ !showSpinner ? "Ödemeyi Tamamla" : "" }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- odeme bitis -->
    </div>
    <GeneralModal :id="'Odeme'">
      <template v-slot:GeneralModalTitle>
        <div v-if="generalModalMode == 'preInfo'">
          <h3>Ön Bilgilendirme</h3>
        </div>
        <div v-if="generalModalMode == 'saleInfo'">
          <h3>Mesafeli Satış Sözleşmesi</h3>
        </div>
        <div v-if="generalModalMode == 'returnInfo'">
          <h3>İptal-İade Şartları</h3>
        </div>
      </template>
      <template v-slot:GeneralModalContent>
        <div v-if="generalModalMode == 'preInfo'" v-html="preInfoHtml.value ? preInfoHtml.value : ''"></div>
        <div v-if="generalModalMode == 'saleInfo'" v-html="saleInfoHtml.value ? saleInfoHtml.value : ''"></div>
        <div v-if="generalModalMode == 'returnInfo'" v-html="cancelInfoHtml.value ? cancelInfoHtml.value : ''"></div>
      </template>
    </GeneralModal>
    <SpinnerModal v-if="showLoader" />
    <div>
      <!-- <b-button id="show-btn" @click="showModal">Open Modal</b-button>
      <b-button id="toggle-btn" @click="toggleModal">Toggle Modal</b-button>-->

      <b-modal ref="my-modal" hide-footer @hidden="iyzicoModal = false">
        <div class="d-block text-center">
          <iframe :srcdoc="html" scrolling="no" frameborder="3" style="height: 300px; width: 400px"></iframe>
        </div>
        <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button>
        <b-button class="mt-2" variant="outline-warning" block @click="toggleModal">Toggle Me</b-button>-->
      </b-modal>
    </div>
    <modal v-if="showErrorModal" v-model="showErrorModal" :content="statusContent"></modal>
  </div>
</template>

    <script>
import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { getOrDefine, cleaningBasket } from "../core/basket";

export default {
  name: "Odeme",
  mixins: [validationMixin],
  components: {
    Multiselect,
  },
  data() {
    return {
      showSpinner: false,
      show: false,
      loading: false,
      showLoader: false,
      user: {},
      allBasketData: [],
      allCoursePrice: 0,
      cardNumber: "",
      cardName: "",
      cardCvc: "",
      selected: "",
      selected2: "",
      selected3: null,
      inputEvaluated: null,
      inputEvaluated2: null,
      inputEvaluated3: null,
      generalModalMode: null,
      termCheck: null,
      valid: null,
      valid2: null,
      valid3: null,
      submitStatus: null,
      value: 65,
      courseId: [],
      installments: [],
      html: "",
      status: "started",
      tkstKontrol: "",
      statusContent: {},
      showErrorModal: false,
      iyzicoModal: false,
      debounce: null,
    };
  },
  validations: {
    cardNumber: {
      required,
      minLength: minLength(19),
    },
    cardName: {
      required,
      minLength: minLength(5),
    },
    cardCvc: {
      required,
      minLength: minLength(3),
    },
  },
  mounted() {
    this.basketData();
    this.$store.commit("SET_LOADER", false);
  },
  destroyed() {
    this.$store.commit("SET_LOADER", true);
  },
  computed: {
    preInfoHtml: {
      get() {
        return this.$store.getters.getSettings("preliminary_information");
      },
    },
    saleInfoHtml: {
      get() {
        return this.$store.getters.getSettings("distance_selling_contract");
      },
    },
    cancelInfoHtml: {
      get() {
        return this.$store.getters.getSettings("cancellation_policy");
      },
    },
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    installmentCalc({ total, installment, installmentPrice }) {
      return `${installment} x ${installmentPrice}₺ = ${total}₺`;
    },
    termCheckFunc: function () {
      if (this.termCheck == "invalid") {
        this.termCheck = "valid";
        console.log("test2");
      } else {
        this.termCheck = "invalid";
        console.log("test3");
      }
    },
    validFunc: function () {
      if (this.inputEvaluated) {
        this.valid = "invalid";
      } else {
        this.valid = "valid";
      }
    },
    valid2Func: function () {
      if (this.inputEvaluated2) {
        this.valid2 = "invalid";
      } else {
        this.valid2 = "valid";
      }
    },
    valid3Func: function () {
      if (this.inputEvaluated3) {
        this.valid3 = "invalid";
      } else {
        this.valid3 = "valid";
      }
    },
    validateAlpha() {
      var textInput = document.getElementById("nameOnCard").value;
      textInput = textInput.replace(/[^A-Za-zğüşıöçĞÜŞİÖÇ, ]/g, "");
      document.getElementById("nameOnCard").value = textInput;
    },
    preInfo() {
      this.generalModalMode = "preInfo";
    },
    saleInfo() {
      this.generalModalMode = "saleInfo";
    },
    returnInfo() {
      this.generalModalMode = "returnInfo";
    },

    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
      if (this.valid == "valid") {
        this.valid = "valid";
      } else {
        this.valid = "invalid";
      }
      if (this.valid2 == "valid") {
        this.valid2 = "valid";
      } else {
        this.valid2 = "invalid";
      }
      if (this.valid3 == "valid") {
        this.valid3 = "valid";
      } else {
        this.valid3 = "invalid";
      }
      if (this.termCheck == "invalid") {
        this.termCheck = "invalid";
      } else {
        this.termCheck = "valid";
      }
    },
    basketData() {
      this.allBasketData = getOrDefine();
      if (this.allBasketData == null || this.allBasketData.length == 0) return;
      this.allCoursePrice = 0;
      this.allCoursePrice = this.allBasketData
        .map((course) => course.price)
        .reduce((total, price) => total + price);
      this.courseId = this.allBasketData.map((course) => course.id);
    },
    onInput(e) {
      //if (e.length == 7) {
      this.tkstKontrol = e.slice(0, 7).replace(/ /g, "");
      console.log(this.tkstKontrol.length);
      console.log("hellllo", this.tkstKontrol);
      if (this.tkstKontrol.length === 6) {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.axios
            .post("/payment/installments", {
              courseId: this.courseId,
              binNumber: parseInt(e.slice(0, 7).replace(/ /g, "")),
            })
            .then((response) => {
              this.installments = response.data.installments;
            })
            .catch((error) => {
              console.log(error);
            });
        }, 1000);
      }
    },
    getStatus(id) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.axios
          .get("/payment/status/" + id)
          .then((response) => {
            this.status = response.data.status;
            if (this.status == "purchased") {
              this.$refs["my-modal"].hide();
              cleaningBasket();
            }
            if (this.status == "errored") {
              this.showErrorModal = true;
              this.statusContent = {
                status: this.status,
                message: response.data["error_resp"],
              };
            }
            if (this.status == "verificated") {
              this.$refs["my-modal"].hide();
              this.showSpinner = true;
              this.getStatus(id);
            }
            if (this.status == "started") {
              this.getStatus(id);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 1000);
    },
    paymentComplete() {
      this.showSpinner = true;
      this.show = true;
      this.iyzicoModal = true;
      let fixed = this.selected3 != null ? this.selected3.installment : null;
      if (this.$refs.checkLabel.checked == false) {
        this.showErrorModal = true;
        this.showSpinner = false;
        this.statusContent = {
          message: "Sözleşmeyi kabul etmeniz gerekmektedir.",
          status: "error",
        };
        return;
      }
      if (
        this.slected &&
        this.selected2 &&
        this.selected.length != 2 &&
        this.selected2.length != 4
      ) {
        this.showErrorModal = true;
        this.showSpinner = false;
        this.statusContent = {
          message: "Doğru ay ve yıl formatında giriniz.",
          status: "error",
        };
        return;
      }
      this.axios
        .post("/payment/get3D", {
          courseId: this.courseId,
          cardNumber: parseInt(this.cardNumber.replace(/ /g, "")),
          holderName: this.cardName,
          expriy: this.selected,
          expiryYear: this.selected2,
          cvc: this.cardCvc,
          installment: fixed,
        })
        .then((response) => {
          this.showLoader = false;
          this.conversationId = response.data.conversationId;
          this.html = response.data.html;
          this.$refs["my-modal"].show();

          if (this.status === "started") {
            this.getStatus(response.data.conversationId);
          }
          if (this.status === "purchased" || this.status === "success") {
            this.showSpinner = false;
            this.loading = false;
          }
          if (this.status === "errored") {
            this.showSpinner = false;
            this.loading = false;
          }
        })
        .catch(this.showError);
    },
    showError(err) {
      this.showErrorModal = true;
      this.showSpinner = false;
      if (err.response && err.response.data) {
        let configDataMessage = err.response.data.message;
        let firstValue = Object.values(configDataMessage)[0][0];
        this.statusContent = { message: firstValue, status: "error" };
      } else {
        return;
      }
      return;
    },
  },
};
</script>


<template>
  <div class="main-content">
    <div class="container pt-4">
      <!-- Başarılı -->
      <div class="text-center py-5 my-4" v-if="result">
        <i class="ri-checkbox-circle-fill ri-8x color-green"></i>
        <h3 class="mb-5">Aktivasyon başarıyla gerçekleşti.</h3>
        <router-link :to="{ name: 'Anasayfa' }" class="brand-color"
          ><i class="ri-arrow-left-s-line align-bottom brand-color"></i>
          Giriş Yapabilirsiniz</router-link
        >
      </div>
      <!-- End -->

      <!-- Başarısız -->
      <div class="text-center py-5 my-4" v-if="result != null && !result">
        <i class="ri-close-circle-fill ri-8x color-red"></i>
        <h3 class="mb-5">Aktivasyon başarısız oldu.</h3>
        <router-link :to="{ name: 'Anasayfa' }" class="brand-color"
          ><i class="ri-arrow-left-s-line align-bottom brand-color"></i> Lütfen
          Tekrar Deneyin</router-link
        >
      </div>
      <Loader v-if="loading"></Loader>
      <!-- Başarısız End-->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loader from "../views/Progress.vue";
export default {
  name: "Aktivasyon",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      result: null,
    };
  },
  created() {
    this.loading = true;
  },
  mounted() {
    axios
      .put("/user/activation", {
        email: this.$route.params.email,
        activation_code: this.$route.params.code,
      })
      .then(async (res) => {
        if (res.data.status == "success") {
          await this.loader();
          this.result = true;
        }
      })
      .catch(async (err) => {
        if (err.response.data.status == "error") {
          await this.loader();
          this.result = false;
        }
      });
  },
  methods: {
    async loader() {
      return new Promise((res) =>
        setTimeout(() => {
          this.loading = false;
          res(this.loading);
        }, 3500)
      );
    },
  },
};
</script>

<template>
  <div>
    <div
      v-if="typeof courses === 'object' ? courses.length === 0 : false"
      class="my-3 text-center color-third"
    >
      <div class="mb-1">
        <slot name="ico"><i class="align-bottom top-minus-1 ri-5x ri-video-line"></i></slot>
      </div>
      <div class="h4"><slot name="text"></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courses: null,
    coursesActive: [],
  },
};
</script>
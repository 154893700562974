<template>
  <b-form-invalid-feedback
    id="input-live-feedback"
    class="position-absolute"
    v-if="show"
  >
    {{text}}
  </b-form-invalid-feedback>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      defaultValue: {value:false,text:""},
    },
    text:{
      type:String,
      defaultValue:""
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
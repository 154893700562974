<template>
  <div class="main-content">
    <div class="container">
      <h2 class="gradient-color mb-4">Tüm Kurslar</h2>
      <div>
        <div>
          <div class="row mb-3 md-lg-2">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
              <div class="search-input position-relative form-group">
                <b-form-input
                  class="mr-sm-2 w-100"
                  placeholder="Arama..."
                  v-model="filter.search"
                  @input="fetchFilteredAllCourses"
                ></b-form-input>
                <button class="btn"><i class="ri-search-line"></i></button>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
              <b-form-group>
                <template>
                  <div>
                    <multiselect
                      placeholder="Sırala"
                      v-model="filter.order.selected"
                      :options="filter.order.options"
                      :select-label="''"
                      :selected-label="''"
                      deselect-label=""
                      :allow-empty="false"
                      label="name"
                      @input="fetchFilteredAllCourses"
                    >
                      <span slot="noResult">Sonuç bulunamadı</span>
                    </multiselect>
                  </div>
                </template>
              </b-form-group>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
              <b-form-group>
                <template>
                  <div>
                    <multiselect
                      placeholder="Kategori"
                      v-model="filter.category.selected"
                      :options="filter.category.options"
                      :select-label="''"
                      :selected-label="''"
                      deselect-label=""
                      :allow-empty="false"
                      label="name"
                      @input="fetchFilteredAllCourses"
                    >
                      <span slot="noResult">Sonuç bulunamadı</span>
                    </multiselect>
                  </div>
                </template>
              </b-form-group>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
              <button
                type="button"
                class="btn d-flex btn-link btn-block justify-content-center"
                @click="resetAllCourses"
              >
                Sıfırla
              </button>
            </div>
          </div>
          <div class="row" v-if="allCourses.length > 0">
            <CourseCard
              v-for="course in allCourses"
              :key="course.id"
              v-bind:course="course"
              class="col-12 col-sm-6 col-md-4 col-lg-3 mb-30"
            >
            </CourseCard>
          </div>
          <SmallLoader :courses="allCourses">
              <template #text> Henüz kurs bulunmuyor. </template>
            </SmallLoader>
          <!-- row end -->
          <div
            class="overflow-auto mt-5"
            v-if="page != null && page != 0"
          >
            <b-pagination-nav
              v-model="currentPage"
              :number-of-pages="page || 1"
              :per-page="16"
              :link-gen="linkGen"
              use-router
              align="center"
              first-text=""
              prev-text=""
              next-text=""
              last-text=""
            ></b-pagination-nav>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
export default {
  name: "TümKurslar",
  data() {
    return {
      favCheck: false,
      favVal: false,
      selected: null,
      options1: ["Seçenek 1", "Seçenek 2", "Seçenek 3"],
      value: 32.333333333,
      courses:[],
      page:1,
      filter: {
        order: {
          options: [
            {
              name: "İlk Zamanda Eklenen",
              value: "first_record",
            },
            {
              name: "Son Zamanda Eklenen",
              value: "last_record",
            },
            {
              name: "Başlık: A-Z",
              value: "a_z",
            },
            {
              name: "Başlık: Z-A",
              value: "z_a",
            },
            {
              name: "Yüksek Fiyat",
              value: "max_price",
            },
            {
              name: "Düşük Fiyat",
              value: "min_price",
            },
          ],
          selected:{value:"last_record",name:"Son Zamanda Eklenen"},
        },
        category: {
          options: [],
          selected: "",
        },
        search: "",
      },
      allCourses: [],
      currentPage: 1,
      numberPage: null,
    };
  },
  created() {
    if (this.$route.params.query) {
      let query = this.$route.params.query;
      this.filter.category.selected = {};
      if (this.$route.params.id) {
        this.filter.category.selected.value = "";
        this.filter.category.selected.name = query;
      } else {
        this.filter.category.selected.value = query;
        this.filter.category.selected.name = query;
      }
    }
    if (this.$route.params.last_record) {
      this.filter.order.selected = {};
      this.filter.order.selected.value = this.$route.params.last_record;
      this.filter.order.selected.name = "Son Zamanda Eklenen";
    }
    this.fetchFilteredAllCourses();
    this.fetchCategories();
  },
  methods: {
    favEkle() {
      this.favCheck = true;
      this.favVal = false;
      var that = this;
      setTimeout(function () {
        (that.favCheck = false), (that.favVal = true);
      }, 500);
    },
    favCikar() {
      this.favVal = true;
      this.favCheck = true;
      var that = this;
      setTimeout(function () {
        (that.favCheck = false), (that.favVal = false);
      }, 500);
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    fetchCategories() {
      this.axios
        .get("/course/categories")
        .then((response) => {
          if (response.data && response.data && response.data.data.length) {
            this.filter.category.options = response.data.data.map((item) => ({
              name: item.category,
              value: item.id,
            }));
          } else {
            this.filter.category.options = [];
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    fetchFilteredAllCourses() {
      this.axios
        .get("/course/all-courses?perPage=16&page=" + this.currentPage, {
          params: {
            order: this.filter.order.selected.value,
            search: this.filter.search,
            category:
              this.filter.category.selected.value || this.$route.params.id,
          },
        })
        .then((response) => {
          this.allCourses = response.data.data.data;
          this.page =Math.ceil(response.data.data.total/response.data.data.per_page);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    resetAllCourses() {
      this.axios
        .get("/course/all-courses", {
          params: {
            order: "",
            search: "",
            category: "",
          },
        })
        .then((response) => {
          this.allCourses = response.data.data.data;
          this.filter.order.selected = "";
          this.filter.search = "";
          this.filter.category.selected = "";
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
  },
  watch: {
    currentPage() {
      this.fetchFilteredAllCourses();
    },
  },
};
</script>
<template>
  <div>
    <b-modal
      id="modal-register"
      entrance-transition="animated fadeIn"
      leave-transition="animated bounceOut"
      title="Kayıt Ol"
      centered
      :hide-footer="true"
      @change="change"
    >
      <div
        v-if="registered == true"
        class="alert alert-success mb-4 float-bottom"
        role="alert"
      >
        <div class="alert-content w-100 d-inline-block">
          <div class="my-1">
            <strong>
              Üyeliğin başarıyla gerçekleşti! E-mail adresine gelen linke
              tıklayarak üyeliğini onaylamalısın.
            </strong>
          </div>
        </div>
      </div>
      <div
        v-if="errors && errors.status == 'error'"
        class="alert alert-error mb-4"
        role="alert"
      >
        <div class="alert-content w-100 d-inline-block">
          <div
            class="my-1"
            v-for="(error, eIndex) of error.message"
            :key="`error_${eIndex}`"
          >
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </div>
      <template #modal-header="{ close }" class="container">
        <h3>Üye Ol</h3>
        <button type="button" aria-label="Close" class="close" @click="close()">
          <i class="ri-close-line color-third"></i>
        </button>
      </template>
      <template>
        <!-- loader spinner Start -->
        <!-- loader spinner End -->
        <form @submit.stop.prevent="onSubmit" autocomplete="on">
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group class="input-icon" label="Ad">
                <i class="ri-user-3-line"></i>
                <b-form-input
                  :state="validateState($v.RegisterName)"
                  v-model="$v.RegisterName.$model"
                  id="input-RegisterName"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group class="input-icon" label="Soyad">
                <i class="ri-user-3-line"></i>
                <b-form-input
                  :state="validateState($v.RegisterSurname)"
                  v-model="$v.RegisterSurname.$model"
                  id="input-RegisterSurname"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group class="input-icon" label="E-Posta">
                <i class="ri-user-3-line"></i>
                <b-form-input
                  :state="validateState($v.email)"
                  v-model="$v.email.$model"
                  id="input-email"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group class="input-icon" label="Şifre">
                <i class="ri-lock-line"></i>
                <b-form-input
                  :state="validateState($v.password)"
                  v-model="$v.password.$model"
                  id="input-password"
                  :type="visibility"
                ></b-form-input>
                <i
                  class="ri-eye-off-line"
                  @click="showPassword()"
                  v-if="visibility == 'password'"
                ></i>
                <i
                  class="ri-eye-line"
                  @click="hidePassword()"
                  v-if="visibility == 'text'"
                ></i>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group class="input-icon" label="Şifre Tekrar">
                <i class="ri-lock-line"></i>
                <b-form-input
                  :state="validateState($v.repeatPassword)"
                  v-model="$v.repeatPassword.$model"
                  id="input-repeatPassword"
                  :type="RepeatVisibility"
                ></b-form-input>
                <i
                  class="ri-eye-off-line"
                  @click="showRepeatPassword()"
                  v-if="RepeatVisibility == 'password'"
                ></i>
                <i
                  class="ri-eye-line"
                  @click="hideRepeatPassword()"
                  v-if="RepeatVisibility == 'text'"
                ></i>
              </b-form-group>
            </div>
          </div>
          <div class="checkbox-group">
            <input
              type="checkbox"
              class="check-group"
              name="check"
              v-model="announcement_permission"
              true-value="1"
              false-value="0"
            />
            <label class="form-check-label" for="check">
              <span class="check"></span>
              <span class="box"></span>
              Beni sınıf etkinlikleri ve yeni sürümler hakkında güncel tutun
            </label>
          </div>
          <button type="submit" class="btn btn-primary w-100 mb-4">
            <b-spinner
              label="Spinning"
              class="align-middle top-minus-1 mr-3"
              small
              v-if="loading"
            ></b-spinner>
            {{loading?"":"Üyeliğimi Oluştur"}}
          </button>
          <a
            href="#"
            class="fw-bold text-center mb-4 d-block"
            role="button"
            @click="$bvModal.hide('modal-login')"
            v-b-modal.modal-register
          >
            Bir hesaba mı ihtiyacınız var? Hemen kayıt olun.
          </a>
          <p class="fw-bold text-center mb-3 d-block font-weight-normal">
            Veya sosyal ağlarla kayıt olun:
          </p>
          <div class="d-flex login-social mb-4">
            <a @click="facebookClick">
              <div
                class="
                  iconBg-size
                  facebook-bgcolor
                  rounded-circle
                  d-flex
                  justify-content-center
                  align-items-center
                  mr-3
                "
              >
                <i class="ri-facebook-fill"></i>
              </div>
            </a>
            <a @click="googleClick">
              <div
                class="
                  iconBg-size
                  google-bgcolor
                  rounded-circle
                  d-flex
                  justify-content-center
                  align-items-center
                  mr-3
                "
              >
                <i class="ri-google-fill"></i>
              </div>
            </a>
            <GoogleLogin
              id="loginWithGoogle"
              :params="params"
              :onSuccess="loginWithGoogle"
              hidden
            ></GoogleLogin>
            <v-facebook-login
              id="loginWithFacebook"
              :app-id="facebookClientID"
              @login="loginWithFacebook"
              hidden
            ></v-facebook-login>
          </div>
          <p class="small color-second text-center d-block">
            Giriş yaparak,
            <router-link to="../#/gizlilik-sozlesmesi/" class="color-second">
              <u>Gizlilik ve Hizmet Şartlarımızı</u>
            </router-link>
            kabul etmiş olursunuz
          </p>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import { GoogleLogin } from "vue-google-login";
import VFacebookLogin from "vue-facebook-login-component";
import { defineValue } from "../../../core/userData";
import { flattenDeep } from "lodash";

export default {
  name: "RegisterModal",
  mixins: [validationMixin],
  components: { GoogleLogin, VFacebookLogin },
  data() {
    return {
      loading: false,
      password: null,
      repeatPassword: null,
      RegisterName: null,
      RegisterSurname: null,
      type: "password",
      email: null,
      visibility: "password",
      RepeatVisibility: "password",
      announcement_permission: 0,
      registered: false,
      errors: null,
      params: {
        client_id: this.$googleClientID,
      },
      facebookClientID: this.$facebookClientID,
    };
  },
  validations: {
    RegisterName: {
      required,
      minLength: minLength(2),
    },
    RegisterSurname: {
      required,
      minLength: minLength(2),
    },
    password: {
      required,
      minLength: minLength(8),
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
    email: {
      required,
      email,
    },
  },
  methods: {
    register() {
      this.loading = true;
      const reqData = {
        language_code: "tr",
        name: this.RegisterName,
        surname: this.RegisterSurname,
        email: this.email,
        password: this.password,
        password_confirmation: this.repeatPassword,
        announcement_permission: this.announcement_permission,
      };
      this.axios
        .post("/user", reqData)
        .then((response) => {
          response;
          this.registered = true;
          this.errors = null;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            error.response.data.message = flattenDeep(
              Object.keys(error.response.data.message).map((key) => {
                return error.response.data.message[key];
              })
            );
          this.registered = false;
          this.loading = false;
          this.errors = error.response.data;
          }
        });
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    showPassword() {
      this.visibility = "text";
    },
    hidePassword() {
      this.visibility = "password";
    },
    showRepeatPassword() {
      this.RepeatVisibility = "text";
    },
    hideRepeatPassword() {
      this.RepeatVisibility = "password";
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) return false;

      this.register();
    },
    change() {
      this.registered = false;
      this.errors = null;
    },
    googleClick() {
      document.getElementById("loginWithGoogle").click();
    },
    loginWithGoogle(googleUser) {
      if (googleUser) {
        this.axios
          .post("/user/social-login", {
            provider: "google",
            language_code: "tr",
            token: googleUser.Zb.access_token,
          })
          .then((response) => {
            console.log("Hello", googleUser);

            var picture_expire_in = new Date();
            picture_expire_in.setMinutes(picture_expire_in.getMinutes() + 4);
            let data = response.data.data;
            data.user.picture_expire_in = picture_expire_in.toLocaleString();
            defineValue(data);
            this.$store.dispatch("setUserData", data);

            this.makeAuth();
            this.$router.push("/");
          })
          .catch((error) => {
            this.socialLoginError = error.response.data.message;
          });
      }
    },
    facebookClick() {
      document.getElementById("loginWithFacebook").click();
    },
    loginWithFacebook(facebookUser) {
      if (facebookUser) {
        this.axios
          .post("/user/social-login", {
            provider: "facebook",
            language_code: "tr",
            token: facebookUser.authResponse.accessToken,
          })
          .then((response) => {
            var picture_expire_in = new Date();
            picture_expire_in.setMinutes(picture_expire_in.getMinutes() + 4);
            let data = response.data.data;
            data.user.picture_expire_in = picture_expire_in.toLocaleString();
            defineValue(data);
            this.$store.dispatch("setUserData", data);

            this.makeAuth();
            this.$router.push("/");
          })
          .catch((error) => {
            this.socialLoginError = error.response.data.message;
          });
      }
    },
  },
};
</script>
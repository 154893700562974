<template>
  <div class="main-content">
    <div class="container">
      <form @submit.stop.prevent="onSubmit">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-3">
            <div class="sidebar">
              <div class="text-center text-sm-left text-md-center">
                <div
                  class="
                    d-flex
                    justify-content-center
                    flex-column
                    place-items-center
                  "
                >
                  <div class="text-center">
                    <div class="profile-image-wrapper mb-4">
                      <div
                        class="
                          profile-image
                          m-auto
                          position-relative
                          rounded-circle
                          bg-image
                        "
                        :style="
                          data && data.picture_link
                            ? {
                                backgroundImage:
                                  'url(' + data.picture_link + ')',
                              }
                            : {
                                backgroundImage:
                                  'url(https://svgshare.com/i/bnS.svg)',
                              }
                        "
                      >
                        <!-- Gostermek icin d-flex eklenmeli -->
                        <div class="spinner over bg-transparent d-none">
                          <b-spinner label="Spinning" class="brand-color">
                          </b-spinner>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mw-100">
                    <div class="profile-info mb-4">
                      <div
                        class="
                          profile-name
                          text-capitalize text-truncate
                          h4
                          mb-1
                        "
                      >
                        {{ data && data.name ? data.name : "" }}
                      </div>
                      <div
                        class="
                          color-second
                          text-capitalize text-truncate
                          font-weight-normal
                          h4
                          mb-0
                        "
                      >
                        {{ data && data.title ? data.title : "" }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex d-md-inline-block text-left mw-100">
                    <div class="mt-2 pr-5 pr-md-0">
                      <div class="mb-2">
                        <i
                          class="mr-2 align-bottom top-minus-1 ri-star-line"
                        ></i>
                        <span>
                          {{ data && data.rating ? data.rating : 0 }}</span
                        >
                        <span> Eğitmen Puanı</span>
                      </div>
                      <div class="mb-2">
                        <i
                          class="mr-2 align-bottom top-minus-1 ri-star-line"
                        ></i>
                        <span>
                          {{
                            data && data.commentCount ? data.commentCount : ""
                          }}</span
                        >
                        <span> Yorum</span>
                      </div>
                      <div class="mb-2">
                        <i
                          class="
                            mr-2
                            align-bottom
                            top-minus-1
                            ri-star-line ri-user-2-line
                          "
                        ></i>
                        <span>
                          {{
                            data && data.studentCount ? data.studentCount : ""
                          }}</span
                        >
                        <span> Öğrenci</span>
                      </div>
                      <div class="mb-2">
                        <i
                          class="
                            mr-2
                            align-bottom
                            top-minus-1
                            ri-star-line ri-video-line
                          "
                        ></i>
                        <span>
                          {{
                            data && data.courseCount ? data.courseCount : ""
                          }}</span
                        >
                        <span> Kurs</span>
                      </div>
                    </div>
                    <div class="mt-2 mt-md-4 pt-0 pt-md-2 pl-5 pl-md-0">
                      <div class="mb-2" v-if="data && data.website">
                        <a :href="data.website" target="blank">
                          <i class="ri-link-m mr-2 align-bottom top-minus-1"></i
                          >Website
                        </a>
                      </div>
                      <div class="mb-2" v-if="data && data.facebook">
                        <a
                          :href="data.facebook"
                          target="blank"
                          class="social-media-link facebook-color"
                        >
                          <i
                            class="
                              ri-facebook-fill
                              mr-2
                              align-bottom
                              top-minus-1
                              facebook-color
                            "
                          ></i
                          ><span>Facebook</span>
                        </a>
                      </div>
                      <div class="mb-2" v-if="data && data.linkedin">
                        <a
                          :href="data.linkedin"
                          target="blank"
                          class="social-media-link linkedin-color"
                        >
                          <i
                            class="
                              ri-linkedin-fill
                              mr-2
                              align-bottom
                              top-minus-1
                              linkedin-color
                            "
                          ></i
                          ><span>Linkedin</span>
                        </a>
                      </div>
                      <div class="mb-2" v-if="data && data.twitter">
                        <a
                          :href="data.twitter"
                          target="blank"
                          class="social-media-link twitter-color"
                        >
                          <i
                            class="
                              ri-twitter-fill
                              mr-2
                              align-bottom
                              top-minus-1
                              twitter-color
                            "
                          ></i
                          ><span>Twitter</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-8 col-lg-9 mt-5 mt-md-0">
            <div class="content">
              <h2 class="gradient-color mb-4">Profil</h2>
              <div class="mb-4">
                <h3>Hakkımda</h3>
                <div v-html="data.about"></div>
              </div>
              <div class="pt-4">
                <h3 class="mb-4 pr-5">
                  Kurslarım
                  <span class="color-second"
                    >({{
                      data && data.courseCount ? data.courseCount : 0
                    }})</span
                  >
                </h3>
                <VueSlickCarousel
                  v-bind="CourseCoruselSettings"
                  v-if="data && data.courseCount > 0"
                >
                  <div v-for="(course, i) in data.courses" :key="i">
                    <CourseCard :course="course" :id="i" class="w-100 p-lg-0">
                    </CourseCard>
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <modal :content="checkFavorite.data" v-model="show"></modal>
  </div>
</template>

<script>
import axios from "axios";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { mapGetters } from "vuex";
export default {
  components: {
    VueSlickCarousel,
  },
  created() {
    this.fetchInstructor()
  },
  methods:{
    fetchInstructor(){
      axios
      .get("/instructor/" + this.$route.params.id)
      .then((response) => {
        this.data = response.data.data;
      })
      .catch((error) => {
        console.log(error.response.data);
      });
    }
  },
   watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.fetchInstructor()
      }
    },
  },
  computed: {
    ...mapGetters(["getUserData"]),
    instructor: {
      get() {
        return this.$store.state.instructor;
      },
    },
  },
  data() {
    return {
      show: false,
      checkFavorite: { data: { status: "", message: "hi" } },
      favorite: false,
      profile: {},
      CourseCoruselSettings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      courses: [],
      courseLink: null,
      course_id: "",
      instructors: {},
      count: 5,
      ratings: [],
      courseClone: {},
      data: {},
      courseInfo: {
        title: "AspNet Core Web/API + Çok Katmanlı Mimari | Best Practices",
        explanation:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        beforePrice: "59.99₺",
        price: "27.99₺",
        discount: 87,
        votes: 379,
        score: 4.6,
        userBought: 6.676,
        episode: 14,
        time: "13 saat 45 Dakika",
        purchaser: 6.675,
        ratings: {
          fiveStart: 67,
          fourStart: 4,
          threeStart: 6,
          twoStart: 1,
          oneStart: 2,
        },
        instructor: {
          fullName: "John Doe",
          title: "Bilgisayar Mühendisi",
          detailInfo: [
            {
              value: 4.7,
              text: "Eğitmen Puanı",
              context: "score",
              ico: "ri-star-line",
            },
            {
              value: 1.997,
              text: "Yorum",
              context: "comment",
              ico: "ri-award-line",
            },
            {
              value: 26.574,
              text: "Öğrenci",
              context: "trainee",
              ico: "ri-user-2-line",
            },
            {
              value: 23,
              text: "Kurs",
              context: "course",
              ico: "ri-video-line",
            },
          ],
        },
        educationalAttainment: [
          "Sıfırdan  çok katmanlı mimari ile aspnet core mvc/api uygulması geliştirmeyi öğreneceksiniz.",
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        ],
      },
      courseContent: [
        { title: "Bölüm 1:Başlangıç", section: "1 / 10", time: "45 dakika" },
        {
          title: "Bölüm 2:Vue.js ile DOM Etkileşimleri",
          section: "2 / 10",
          time: "45 dakika",
        },
        {
          title: "Bölüm 3:Koşul Kullanımı ve Listeleri",
          section: "3 / 10",
          time: "45 dakika",
        },
        {
          title: "Bölüm 4:İlk Proje | Canavar Avı Oyunu",
          section: "4 / 10",
          time: "45 dakika",
        },
        {
          title: "Bölüm 5:Vue.JS instance Nedir?",
          section: "5 / 10",
          time: "45 dakika",
        },
        {
          title: "Bölüm 6:Webpack ve Vue CLI ile Gerçek",
          section: "6 / 10",
          time: "45 dakika",
        },
        {
          title: "Bölüm 7:Component Yapısına Giriş",
          section: "7 / 10",
          time: "45 dakika",
        },
        {
          title: "Bölüm 8:Componentler Arası İletişim",
          section: "8 / 10",
          time: "45 dakika",
        },
        {
          title: "Bölüm 9:Gelişmiş Component Kullanımı",
          section: "9 / 10",
          time: "45 dakika",
        },
      ],
      promiseThings: [],
    };
  },
};
</script>
<style>
.fa-facebook {
  color: #3b5998 !important;
}

.fa-twitter {
  color: #55acee !important;
}
.fa-linkedin {
  color: #007bb5 !important;
}
.dropdown-toggle::after {
  display: none !important;
}
</style>
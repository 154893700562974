<template>
  <div v-if="announcements != null && announcements.length > 0">
    <div v-for="item in announcements" :key="item.id" class="mb-5 pb-1">
      <div class="mb-4">
        <a>
          <div class="d-flex align-items-center">
            <div
              class="wh-48 rounded-circle bg-image mr-3"
              :style="
                item.user
                  ? {
                      backgroundImage: 'url(' + item.user.picture_link + ')',
                    }
                  : {
                      backgroundImage:
                        'url(http://ui.ubit.com.tr/profile-picture.jpg)',
                    }
              "
            ></div>
            <div class="educator-info">
              <div class="text-capitalize text-truncate">
                {{ item.user.full_name }}
              </div>
              <div
                class="
                  color-second
                  text-capitalize text-truncate
                  font-weight-normal
                  mb-0
                "
              >
                <time-ago
                  :locale="language"
                  :datetime="item.created_at"
                  :long="true"
                ></time-ago>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="announcement">
        <h3 class="mb-4">
          {{ item.title }}
        </h3>
        <p v-html="item.announcement || ''"></p>
      </div>
    </div>
  </div>
</template>

<script>
import TimeAgo from "vue2-timeago";
export default {
  components: {
    TimeAgo,
  },
  props: {
    announcements: {
      default: [],
    },
    language: {
      default: "",
    },
  },
};
</script>
<template>
  <div>
    <vue-autosuggest
      class="hello"
      ref="autosuggest"
      v-model="query"
      :suggestions="query == '/' ? [] : suggestions"
      :inputProps="inputProps"
      :sectionConfigs="sectionConfigs"
      :getSuggestionValue="getSuggestionValueOpr"
      @input="fetch"
    >
      <template v-slot="{ suggestion }">
        <div v-if="suggestion.name == 'hotels'" class="suggestion">
          <img
            :class="{ avatar: true }"
            :src="
              suggestion.item.picture_link == null
                ? `https://svgshare.com/i/bnS.svg`
                : suggestion.item.picture_link
            "
          />
          {{ suggestion.item.title }}
        </div>
        <div v-if="suggestion.name == 'destinations'" class="suggestion">
          <img
            :class="{ avatar: true }"
            :src="
              suggestion.item.picture_link == null
                ? `https://svgshare.com/i/bnS.svg`
                : suggestion.item.picture_link
            "
          />
          {{ suggestion.item.full_name }}
        </div>
      </template>
      <template slot="after-suggestions">
        <li
          class="text-center px-4 py-3 autosuggest__results-item"
          @mouseup.stop
          @click.stop="demo()"
        >
          <a class="color-second" style="position: relative; z-index: 9999"
            >Tüm Sonuçlar</a
          >
        </li>
      </template>
    </vue-autosuggest>
    <button class="btn" @click="demo()"><i class="ri-search-line"></i></button>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
export default {
  components: {
    VueAutosuggest,
  },
  props: {
    suggestionsArr: Array,
    getSuggestionValue: Function,
    fetchResults: Function,
  },
  data() {
    return {
      loading: false,
      inputProps: {
        placeholder: "Arama...",
        class: "form-control w-100",
      },
      sectionConfigs: {
        destinations: {
          limit: 5,
          label: "Eğitmenler",
          onSelected: (selected) => {
            this.query = "";
            this.fetch(this.query);
            this.$router.push("/profil/" + selected.item.id).catch(() => {});
            this.selected = selected.item;
          },
        },
        hotels: {
          limit: 5,
          label: "Kurslar",
          onSelected: (selected) => {
            this.query = "";
            this.fetch(this.query);
            this.selected = selected.item;
            this.$router
              .push({
                path: "/kurs-detay/" + selected.item.slug,
                params: selected.item.id,
              })
              .catch();
          },
        },
        default: {
          onSelected: () => {
            // this.doSearch(this);
          },
        },
      },
    };
  },
  created() {
    this.sectionConfigs.destinations.onSelected.bind(this);
    this.sectionConfigs.hotels.onSelected.bind(this);
  },
  methods: {
    demo() {
      this.suggestions = [];
        this.$router.push({
          path: "/tum-sonuclar",
          query: { arama: this.query },
        }).catch(()=>{});
    },
    getSuggestionValueOpr(suggestion) {
      this.getSuggestionValue(suggestion);
    },
    fetch() {
      if (this.query.length > 2) {
        this.fetchResults(this.query);
      }
      if (this.query.length === 0) {
        this.fetchResults(this.query);
      }
    },
    checkSearchForLoader(val) {
      if (val !== "") {
        this.$store.commit("SET_LOADER", false);
      }
      return;
    },
  },
  computed: {
    query: {
      get() {
        return this.$store.state.searchQuery;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_INPUT", val);
      },
    },
    suggestions:{
      get(){
        return this.suggestionsArr
      },
      set(val){
        this.$emit("update:suggestionsArr",val)
      }
    }
  },
  watch: {
    query: function (val) {
      this.checkSearchForLoader(val);
    },
  },
};
</script>
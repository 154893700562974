import Vue from 'vue'
import VueRouter from 'vue-router'
import Anasayfa from '../views/Anasayfa.vue'
import Profil from '../views/Profil.vue'
import ProfilDuzenle from '../views/ProfilDuzenle.vue'
import Bildirimler from '../views/Bildirimler.vue'
import BildirimAyarlari from '../views/BildirimAyarlari.vue'
import Hosgeldin from '../views/Hosgeldin.vue'
import GizlilikSozlesmesi from '../views/GizlilikSozlesmesi.vue'
import KullanimSartlari from '../views/KullanimSartlari.vue'
import Kurslarim from '../views/Kurslarim.vue'
import EgitimVideo from '../views/Egitim-Video.vue'
import EgitimQuiz from '../views/EgitimQuiz.vue'
import EgitimAnket from '../views/EgitimAnket.vue'
import errorPage from '../views/404.vue'
import error500 from '../views/500.vue'
import Aktivasyon from '../views/Aktivasyon.vue'
import Progress from '../views/Progress.vue'
import SatinAlmaGecmisi from '../views/SatinAlmaGecmisi.vue'
import Makbuz from '../views/Makbuz.vue'
import Link from '../views/Link.vue'
import Sepet from '../views/Sepet.vue'
import SepetMisafir from '../views/SepetMisafir.vue'
import Odeme from '../views/Odeme.vue'
import Ayarlar from '../views/Ayarlar.vue'
import Iletisim from '../views/Iletisim.vue'
import SifreSifirlama from '../views/SifreSifirlama.vue'
import { getValue } from "../core/userData"
import KursDetay from "../views/KursDetay.vue"
import Insructor from "../views/Profil.vue"
import TumKategoriler from "../views/TumKategoriler"
import TumKurslar from "../views/TumKurslar"
import TumSonuclar from "../views/TumSonuclar"
import { store } from '../store'
import axios from 'axios'
import NProgress from 'vue-nprogress'
import {defineValue} from "../core/userData"


const options = {
  latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: false // Show progressbar when doing Vue.http, default: true
};
Vue.use(NProgress, options)

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: Anasayfa,
    meta: { requiresAuth: true }
  },
  {
    path: '/instructor/:id',
    name: 'instructor',
    component: Insructor,
  },
  {
    path: '/kurs-detay/:slug',
    name: 'KursDetay',
    component: KursDetay,
    meta: { requiresAuth: true }
  },
  {
    path: '/profil/:id',
    name: 'Profil',
    component: Profil,
    meta: { requiresAuth: true }
  },
  {
    path: '/profil-duzenle',
    name: 'ProfilDuzenle',
    component: ProfilDuzenle
  },
  {
    path: '/bildirimler',
    name: 'Bildirimler',
    component: Bildirimler
  },
  {
    path: '/bildirim-ayarlari',
    name: 'BildirimAyarlari',
    component: BildirimAyarlari
  },
  {
    path: '/hosgeldin',
    name: 'Hosgeldin',
    component: Hosgeldin
  },
  {
    path: '/gizlilik-sozlesmesi',
    name: 'gizlilik-sozlesmesi',
    component: GizlilikSozlesmesi,
    meta: { requiresAuth: true }
  },
  {
    path: '/kullanim-sartlari',
    name: 'KullanimSartlari',
    component: KullanimSartlari,
    meta: { requiresAuth: true }
  },
  {
    path: '/kurslarim',
    name: 'Kurslarim',
    component: Kurslarim
  },
  {
    path: '/tum-kategoriler',
    name: 'TumKategoriler',
    component: TumKategoriler,
    meta: { requiresAuth: true }
  },
  {
    path: '/tum-kurslar',
    name: 'TumKurslar',
    component: TumKurslar,
    meta: { requiresAuth: true }
  },
  {
    path: '/tum-sonuclar',
    name: 'TumSonuclar',
    component: TumSonuclar,
    meta: { requiresAuth: true, default: true }
  },
  {
    path: '/egitim-video/:slug',
    name: 'egitim-video',
    component: EgitimVideo
  },
  {
    path: '/egitim-anket/:id',
    name: 'egitimAnket',
    component: EgitimAnket
  },
  {
    path: '/egitim-quiz/:id',
    name: 'EgitimQuiz',
    component: EgitimQuiz
  },
  {
    path: '/404',
    name: '404',
    component: errorPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/500',
    name: '500',
    component: error500,
    meta: { requiresAuth: true }
  },
  {
    path: '/email/aktivasyon/:email/:code',
    name: 'Aktivasyon',
    component: Aktivasyon,
    meta: { requiresAuth: true }
  },
  {
    path: '/progress',
    name: 'Progress',
    component: Progress
  },
  {
    path: '/link',
    name: 'link',
    component: Link
  },
  {
    path: '/satin-alma-gecmisi',
    name: 'satin-alma-gecmisi',
    component: SatinAlmaGecmisi
  },
  {
    path: '/makbuz/:id',
    name: 'makbuz',
    component: Makbuz
  },
  {
    path: '/sepet',
    name: 'Sepet',
    component: Sepet,
    meta: { requiresAuth: true }
  },
  {
    path: '/sepet-misafir',
    name: 'SepetMisafir',
    component: SepetMisafir,
    meta: { requiresAuth: true }
  },
  {
    path: '/odeme',
    name: 'Odeme',
    component: Odeme
  },
  {
    path: '/ayarlar',
    name: 'Ayarlar',
    component: Ayarlar
  },
  {
    path: '/iletisim',
    name: 'Iletisim',
    component: Iletisim,
    meta: { requiresAuth: true }
  },
  {
    path: '/sifre-sifirlama/:email/:token',
    name: 'SifreSifirlama',
    component: SifreSifirlama,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/hosgeldin',
    component: Hosgeldin
  },
  {
    path: '*',
    component: errorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})

router.beforeEach((to, from, next) => {
  const userData = getValue()
  let fn = function () {
    store.commit("SET_SEARCH_INPUT", "")
    store.commit("SET_LOADER", true);
    return
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    next()
    let func = function () {
      if (userData && userData.user && userData.user.picture_expire_in < new Date().toLocaleString()) {
        axios.get('/user?language_code=tr')
          .then(response => {
            var picture_expire_in = new Date()
            picture_expire_in.setMinutes(picture_expire_in.getMinutes() + 4)
            let data = response.data.data
            data.picture_expire_in = picture_expire_in.toLocaleString()
            var user_data = userData
            user_data.user = data

            defineValue(data)
            store.dispatch('setUserData', user_data)
          })
      }
    }
    if (to.matched.some(record => record.meta.default)) {
      func()
      return;
    }
    fn();
  }

  if (userData && userData.access_token) {
    fn();
    next();
  }
})

export default router


<template>
  <div class="main-content">
      <div class="container pt-4">
           <div class="text-center"><i class="ri-settings-line ri-8x color-first animation-rotate d-inline-block"></i>
            <h1 class="mb-4">Lütfen Bekleyiniz...</h1>
           <h3 class="mb-5">İşleminiz tamamlanıyor.</h3>
           </div>
      </div>
  </div>
</template>
<script>
export default {
   name: "loader"
   }
</script>
<style lang="scss">
.theme-dark .loaderBck {
  background-color:#0f0e13 !important;
}
.loaderBck{
  background-color:#fff !important;
}
</style>

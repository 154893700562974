<template>
  <div class="main-content">
    <div class="container">
      <h2 class="gradient-color mb-4">Tüm Sonuçlar</h2>
      <div>
        <div>
            <div v-if="courses.length > 0" class="row">
              <course-card
                v-for="result in courses"
                :key="result.id"
                :course="result"
                class="col-12 col-sm-6 col-md-4 col-lg-3 mb-30 d-flex flex-column"
              ></course-card>
          </div>
          <div v-if="users.length > 0" class="row">
            <instructor
              v-for="result in users"
              class="col-12 col-sm-6 col-md-4 col-lg-3 mb-30 d-flex flex-column"
              :user="result"
              :key="'u' + result.id"
            ></instructor>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
import axios from "axios";
import CourseCard from "../components/ui/CourseCard.vue";
import { get } from "lodash";
import instructor from "../components/ui/Instructor.vue";
export default {
  name: "TümSonuclar",
  components: { CourseCard, instructor },
  data() {
    return {
      searchResult: [],
      CardImage7:
        "https://productimages.hepsiburada.net/s/40/550/10650895351858.jpg",
      courses: [],
      users: [],
    };
  },
  created() {
    this.fetchCourses();
  },
  methods: {
    fetchCourses() {
      axios
        .post("/search", {
          search: get(this.$route, "query.arama", null),
        })
        .then((res) => {
          const data = res.data.data;
          this.courses = this.toCollectCourses(data);
          this.users = this.toCollectUsers(data);
          console.log(this.users);
          this.searchResult = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toCollectCourses(data) {
      if (data == null || data == "") return;
      return data.filter((x) => x.type == null);
    },
    toCollectUsers(data) {
      if (data == null || data == "") return;
      return data.filter((x) => x.type == "user");
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == from.path) this.fetchCourses();
    },
    searchResult(val) {
      console.log(val);
    },
  },
  computed: {
    query: {
      get() {
        return this.$store.state.searchQuery;
      },
      set() {},
    },
  },
};
</script>
<template>
  <div class="mb-4">
    <article
      class="card position-relative"
    >
      <b-dropdown
        id="dropdown-right"
        text="Dropdown Button"
        right
        class="dropdown ml-auto after-none position-absolute right-0 top-0"
      >
        <template #button-content>
          <i class="ri-more-line"></i>
        </template>
        <b-dropdown-item v-b-modal.ShareModal @click="shareBtn()">
          <i class="ri-share-fill mr-3 align-bottom top-minus-1"></i>
          Paylaş
        </b-dropdown-item>
        <b-dropdown-item v-if="user && user.access_token">
          <favori-button class="p-lg-1 btn btn-link text-center" :course="course"></favori-button>
        </b-dropdown-item>
      </b-dropdown>

      <a @click="otherPageBtn()">
        <div
          class="card-img-top card-image"
          :style="{
            backgroundImage: 'url(' + courseData.picture_link + ')',
          }"
        ></div>
      </a>
      <div class="card-body">
        <div class="font-weight-bold ellipsis-2 mb-2">
          <a @click="otherPageBtn()">
            {{ courseData.title }}
          </a>
        </div>
        <div class="text-capitalize text-truncate mb-2">
          <a
            class="color-second"
            >{{ (courseData.instructor&& courseData.instructor.user && courseData.instructor.user.full_name) ?  courseData.instructor.user.full_name : ""}}
          </a>
        </div>
        <div
          class="mb-1 mt-auto pt-2"
          v-if="
            courseData.progress && courseData.progress.percentage ? true : false
          "
        >
          <b-progress
            :value="
              courseData.progress && courseData.progress.percentage
                ? courseData.progress.percentage
                : false
            "
            class="bg-third"
            variant="success"
          ></b-progress>
        </div>
        <div
          class="
            card-star-rating
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <b
            class="mb-0 pt-2"
            v-if="
              courseData.progress && courseData.progress.percentage
                ? true
                : false
            "
          >
            %{{
              courseData.progress && courseData.progress.percentage
                ? courseData.progress.percentage
                : 0
            }}
          </b>

          <div class="card-star-rating d-flex align-items-center">
            <b
              class="pt-2 mr-2 h4 mb-0 color-yellow"
              :class="{
                'd-none': !(
                  courseData.rating
                ),
              }"
            >
              {{ courseData.rating || "" }}
            </b>
            <star-rating
              :rating="
                courseData.rating || 0
              "
              :round-start-rating="false"
              :read-only="true"
              :star-points="[
                23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46,
                19, 31, 17,
              ]"
            ></star-rating>
          </div>
        </div>
        <div class="d-flex align-items-center mt-3">
          <div class="h3 mb-0 mr-2">
            {{ courseData.price ? courseData.price : "27,99" }}₺
          </div>
          <!--<div class="h3 color-second mb-0"><s>59,99₺</s></div>-->
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import userOrAnonymousFavMixin from "../../mixin/userOrAnonymousFav";
import favoriButton from "../../components/ui/FavoriButton.vue"
export default {
  name: "CourseCard",
  mixins: [userOrAnonymousFavMixin],
  components: {
    StarRating,
    favoriButton
  },
  props: {
    value: {
      default: false,
    },
  },
  data() {
    return{
      key:0
    }
  },
  async mounted() {
    this.$store.commit("SET_LOADER", false);
  },
  async updated(){
    await this.start();
  },
  computed: {
    user(){
      return this.$store.state.user
    }
  },
 
};
</script>
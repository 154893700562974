<template>
  <div class="main-content">
    <div class="container">
      <h1 class="text-center font-weight-normal text-capitalize">
        Hoşgeldin {{ user.name }} {{ user.surname }},
      </h1>
      <h1 class="text-center font-weight-normal gradient-color m-auto">
        Başlamak için kurslarımıza bir göz atın
      </h1>
      <div class="mt-5 pt-5">
        <h3 class="mb-4 pr-5">Beğenebileceğin Kurslar</h3>
        <VueSlickCarousel
          v-if="mayBeFavoriteCourses && mayBeFavoriteCourses.length > 0"
          v-bind="CoruselSettings"
        >
          <CourseCard
            v-for="course in mayBeFavoriteCourses"
            :key="course.id"
            :course="course"
          ></CourseCard>
        </VueSlickCarousel>
      </div>

      <div class="mt-5 pt-5">
        <h3 class="mb-4 pr-5">Popüler Kurslar</h3>
        <VueSlickCarousel
          v-if="popularCourses && popularCourses.length > 0"
          v-bind="CoruselSettings"
        >
          <CourseCard
            v-for="course in popularCourses"
            :key="course.id"
            :course="course"
          >
          </CourseCard>
        </VueSlickCarousel>
      </div>
    </div>
    <SuccessModal></SuccessModal>
    <DangerModal></DangerModal>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { getValue } from "../core/userData";

export default {
  name: "Profil",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      user: {},
      value: 65,
      max: null,
      courses: [],
      popularCourses: [],
      mayBeFavoriteCourses: [],
      favoritedCourses: {},
      filter: {
        order: {
          options: [
            {
              name: "Yakın Zamanda Erişilen",
              value: "last_watched",
            },
            {
              name: "Yakın Zamanda Kayıt Yapılan",
              value: "last_record",
            },
            {
              name: "Başlık: A-Z",
              value: "a_z",
            },
            {
              name: "Başlık: Z-A",
              value: "z_a",
            },
          ],
          selected: "",
        },
        category: {
          options: [],
          selected: "",
        },
        progress: {
          options: [
            {
              name: "Henüz Başlanmayan",
              value: 0,
            },
            {
              name: "Devam Eden",
              value: 50,
            },
            {
              name: "Tamamlanan",
              value: 100,
            },
          ],
          selected: "",
        },
        instructor: {
          options: [],
          selected: "",
        },
        status: {
          options: [
            {
              name: "Aktif",
              value: "A",
            },
            {
              name: "Pasif",
              value: "P",
            },
          ],
          selected: "",
        },
        search: "",
      },
      CardImage1: "https://picsum.photos/600/300/?image=27",
      CardImage2: "https://picsum.photos/600/300/?image=22",
      CardImage3: "https://picsum.photos/600/300/?image=23",
      CardImage4: "https://picsum.photos/600/300/?image=24",
      CardImage5: "https://picsum.photos/600/300/?image=25",
      CardImage6: "https://picsum.photos/600/300/?image=26",
      CoruselSettings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
        ],
      },
      success: null,
      error: null,
      courseTeachers: [],
    };
  },
  methods: {
    successModal() {
      this.$bvModal.show("SuccessModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("SuccessModal");
      }, modalTimeoutSeconds * 1000);
    },
    dangerModal() {
      this.$bvModal.show("DangerModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("DangerModal");
      }, modalTimeoutSeconds * 1000);
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    /* detailPage(slug) {
      this.$router.push({
        path: "/kurs-detay/" + slug,
        params: [slug,id],
      });
    }, */

    fetchMayBeFavoriteCourses() {
      this.axios
        .get("/course/may-be-my-favorite?perPage=8")
        .then((response) => {
          let courses = response.data.data.data;
          this.mayBeFavoriteCourses = courses.filter((element) => {
            if (element.status == 1) {
              return element;
            }
          });
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    fetchPopularCourses() {
      this.axios
        .get("/course/popular-courses?perPage=8")
        .then((response) => {
          this.popularCourses = response.data.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    fetchCategories() {
      this.axios
        .get("/course/categories")
        .then((response) => {
          this.filter.category.options = response.data.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    /*fetchFavoritedCourses() {
      this.axios
        .get("/course/my-courses", {
          params: {
            favorite: 1,
          },
        })
        .then((response) => {
          this.favoritedCourses = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },*/
    fetchFilteredCourses() {
      this.axios
        .get("/course/my-courses", {
          params: {
            order: this.filter.order.selected.value,
            category: this.filter.category.selected.id,
            progress: this.filter.progress.selected.value,
            instructor: this.filter.instructor.selected.id,
            status: this.filter.status.selected.value,
            search: this.filter.search,
          },
        })
        .then((response) => {
          this.courses = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    nameWithLang({ json_category }) {
      return `${json_category.tr}`;
    },
    fetchRating() {
      this.axios
        .get("/course/" + this.course.id + "/rating")
        .then((response) => {
          this.rating = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    setRating() {
      this.axios
        .post("/course/rating", {
          course_id: this.course.id,
          rating: this.rating,
        })
        .then((response) => {
          this.rating = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    removeRating() {
      this.axios
        .delete("/course/rating", {
          course_id: this.course.id,
        })
        .then((response) => {
          this.rating = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    start() {
      this.fetchPopularCourses();
      this.fetchCategories();
      this.fetchMayBeFavoriteCourses();
      const userData = getValue();
      if (userData && userData.user) {
        this.user = userData.user;
      }
    },
  },
  computed: {
    ...mapGetters(["getUserData"]),
  },
  created() {
    this.start();
  },
};
</script>
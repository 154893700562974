
let key = "favorite";
export function getOrDefineFav() {
    let items = localStorage.getItem(key);
    if (items == null) {
        items = [];
        localUpdate(items);
        return
    }
    return JSON.parse(items);
}
export function addFav(item) {
    let items = getOrDefineFav();
    if (!existData(item.id)) {
        items.push(item);
        localUpdate(items);
        return;
    }

}
export function removeFav(id) {
    let items = getOrDefineFav()
    let indexOf = items.findIndex(course => course.id === id);
    if (indexOf < 0) {
        throw new Error("Favori Yapabileceğin Kurs Bulunamadı");
    }
    items.splice(indexOf, 1);
    localUpdate(items);
}

export function existData(id) {
    let items = getOrDefineFav();
    let check = items.findIndex(x => x.id === id);
    if (check >= 0) {
        return true
    }
    else {
        return false
    }
}
export function cleaningBasketFav(){
    localStorage.removeItem(key)
}
function localUpdate(items) {
    localStorage.setItem(key, JSON.stringify(items))
}

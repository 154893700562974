<template>
  <header class="header">
    <b-container>
      <div>
        <b-navbar toggleable="lg" type="dark">
          <div
            class="d-flex mobile-nav navbar-toggler m-0 p-0 align-items-center"
          >
            <b-navbar-brand
              v-if="!nightMode"
              to="/"
              class="navbar-toggler mobile-nav p-0 m-0 align-items-center"
              ><img src="../../assets/img/baugo-logo-light.png"
            /></b-navbar-brand>
            <b-navbar-brand
              v-if="nightMode"
              to="/"
              class="navbar-toggler mobile-nav p-0 m-0 align-items-center"
              ><img src="../../assets/img/logo.png"
            /></b-navbar-brand>
          </div>
          <div
            class="d-flex mobile-nav navbar-toggler m-0 p-0 align-items-center"
          >
            <b-navbar-toggle target="nav-mobil-search dropdown-toggle">
              <template>
                <b><i class="ri-search-line"></i></b>
              </template>
            </b-navbar-toggle>
            <div class="dropdown-notification cart mobile-nav navbar-toggler">
              <b-dropdown
                size="lg"
                variant="link"
                toggle-class="text-decoration-none border-none"
                no-caret
                id="dropdown-right"
                right
              >
                <template #button-content>
                  <div class="notification">{{ courseSize }}</div>
                  <i class="ri-shopping-cart-line"></i>
                </template>
                <li class="dropdown-content">
                  <ul>
                    <b-dropdown-item
                      to="/"
                      v-for="item in allBasketData"
                      :key="item.id"
                      @click="otherPager(item.is_paid, item.slug)"
                    >
                      <div
                        class="dropdown-profile-img rounded bg-image mr-3 wh-48"
                        :style="{
                          backgroundImage: 'url(' + item.picture_link + ')',
                        }"
                      ></div>
                      <div class="notification-dropdown">
                        <div class="notification-message ellipsis-2">
                          {{ item.title }}
                        </div>
                        <div
                          class="
                            text-capitalize text-truncate
                            ellipsis-2
                            mb-2
                            color-second
                            white-space
                            text-small
                          "
                        >
                          <router-link
                            to="#"
                            v-for="itemTeacher in item.courseTeacher"
                            :key="itemTeacher.id"
                            class="color-second"
                            >{{ itemTeacher.user.name }}
                          </router-link>
                        </div>
                        <div>
                          <b>{{ item.price }}₺</b>
                        </div>
                      </div>
                    </b-dropdown-item>
                    <li v-if="courseSize > 0" class="divider-line mb-0"></li>
                    <div class="h4 text-center color-first">
                      Toplam: {{ allCoursePrice }}₺
                    </div>
                    <b-dropdown-item>
                      <router-link
                        to="/sepet"
                        tag="button"
                        class="btn btn-primary btn-block"
                      >
                        Sepete Git
                      </router-link>
                    </b-dropdown-item>
                  </ul>
                </li>
              </b-dropdown>
            </div>
            <div class="dropdown-notification mobile-nav navbar-toggler">
              <b-dropdown
                size="lg"
                variant="link"
                toggle-class="text-decoration-none border-none"
                no-caret
                id="dropdown-right"
                right
              >
                <template #button-content class="test">
                  <div v-if="unread > 0" class="notification"></div>
                  <i class="ri-notification-line"></i>
                </template>
                <li class="dropdown-header px-4 py-3">
                  <div class="d-flex justify-content-between">
                    <div class="cursor-default color-first">Bildirimler</div>
                    <div>
                      <div>
                        <router-link
                          to="/bildirim-ayarlari"
                          class="color-second"
                          >Ayarlar</router-link
                        >
                      </div>
                    </div>
                  </div>
                </li>
                <li class="divider-line mt-0"></li>
                <li v-if="notifications.length > 0" class="dropdown-content">
                  <ul>
                    <b-dropdown-item
                      to="../bildirimler"
                      v-for="notification in notifications"
                      :key="notification.id"
                    >
                      <div
                        class="rounded-circle bg-image mr-3 wh-48"
                        :style="
                          getUserData.user.picture_link
                            ? {
                                backgroundImage:
                                  'url(' + getUserData.user.picture_link + ')',
                              }
                            : {
                                backgroundImage:
                                  'url(https://svgshare.com/i/bnS.svg)',
                              }
                        "
                      ></div>
                      <div class="notification-dropdown">
                        <div class="notification-message ellipsis-2">
                          {{ notification.message }}
                        </div>
                        <div class="notification-time text-right">
                          {{ notification.time_diff }}
                        </div>
                      </div>
                    </b-dropdown-item>
                  </ul>
                </li>
                <li v-else class="dropdown-content">
                  <ul>
                    <li>
                      <div class="notification-dropdown">
                        <div
                          class="
                            notification-message
                            ellipsis-2
                            color-second
                            text-small text-center
                            py-2
                          "
                        >
                          Hiç bildiriminiz yok.
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="divider-line mb-0"></li>
                <li class="text-center px-4 py-3">
                  <router-link to="/bildirimler" class="color-second"
                    >Tümünü Gör</router-link
                  >
                </li>
              </b-dropdown>
            </div>
            <div class="headProfileButton mobile-nav navbar-toggler pr-0">
              <b-dropdown
                size="lg"
                variant="link"
                toggle-class="text-decoration-none border-none"
                no-caret
                id="dropdown-right"
                right
              >
                <template #button-content>
                  <div
                    v-if="!nightMode"
                    class="profileImage rounded-circle bg-image"
                    :style="
                      getUserData.user.picture_link
                        ? {
                            backgroundImage:
                              'url(' + getUserData.user.picture_link + ')',
                          }
                        : {
                            backgroundImage:
                              'url(https://svgshare.com/i/bnS.svg)',
                          }
                    "
                  ></div>
                  <div
                    v-if="nightMode"
                    class="profileImage rounded-circle bg-image"
                    :style="
                      getUserData.user.picture_link
                        ? {
                            backgroundImage:
                              'url(' + getUserData.user.picture_link + ')',
                          }
                        : {
                            backgroundImage:
                              'url(https://svgshare.com/i/bnS.svg)',
                          }
                    "
                  ></div>
                </template>
                <li class="dropdown-content pt-0">
                  <ul>
                    <b-dropdown-item
                      to="../profil-duzenle"
                      class="dropdown-profile px-4 py-3 hover-bg-none"
                    >
                      <div
                        v-if="!nightMode"
                        class="rounded-circle bg-image mr-3 wh-48"
                        :style="
                          getUserData.user.picture_link
                            ? {
                                backgroundImage:
                                  'url(' + getUserData.user.picture_link + ')',
                              }
                            : {
                                backgroundImage:
                                  'url(https://svgshare.com/i/bnS.svg)',
                              }
                        "
                      ></div>
                      <div
                        v-if="nightMode"
                        class="rounded-circle bg-image mr-3 wh-48"
                        :style="
                          getUserData.user.picture_link
                            ? {
                                backgroundImage:
                                  'url(' + getUserData.user.picture_link + ')',
                              }
                            : {
                                backgroundImage:
                                  'url(https://svgshare.com/i/bnS.svg)',
                              }
                        "
                      ></div>
                      <div class="white-space">
                        <div class="text-truncate dropdown-name">
                          {{ getUserData.user.name }}
                          {{ getUserData.user.surname }}
                        </div>
                        <div class="text-truncate dropdown-mail color-second">
                          {{ getUserData.user.email }}
                        </div>
                      </div>
                    </b-dropdown-item>
                    <li class="divider-line"></li>
                    <b-dropdown-item to="/kurslarim" class="pb-0">
                      <i class="ri-video-line mr-3"></i>
                      Kurslarım
                    </b-dropdown-item>
                    <b-dropdown-item to="/ayarlar" class="pb-0">
                      <i class="ri-settings-2-line mr-3"></i>Ayarlar
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="!nightMode"
                      @click="nightMode = !nightMode"
                      class="pb-0"
                    >
                      <i class="ri-moon-line mr-3"></i>Koyu Tema
                    </b-dropdown-item>
                    <b-dropdown-item to="/satin-alma-gecmisi" class="pb-0">
                      <i class="ri-pages-line mr-3"></i>Satın Alma Geçmişi
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="nightMode"
                      @click="nightMode = !nightMode"
                      class="pb-0"
                    >
                      <i class="ri-sun-line mr-3"></i>Açık Tema
                    </b-dropdown-item>
                    <li class="divider-line mt-2"></li>
                    <b-dropdown-item
                      @click="handleLogout"
                      to="/"
                      class="pb-0 reverse-hover"
                    >
                      <i class="ri-logout-box-line mr-3 color-second"></i
                      ><span class="color-second">Çıkış Yap</span>
                    </b-dropdown-item>
                  </ul>
                </li>
              </b-dropdown>
            </div>
          </div>

          <!-- mobile search -->
          <b-collapse id="nav-mobil-search" class="w-100">
            <b-navbar-nav class="my-0 mx-0">
              <b-nav-item>
                <b-nav-form class="w-100">
                  <div class="search-input position-relative w-100">
                    <Search
                      ref="autosuggest"
                      :suggestionsArr.sync="suggestions"
                      id="autosuggest__input"
                      :getSuggestionValue="getSuggestionValue"
                      :fetchResults="fetchResults"
                    >
                    </Search>
                  </div>
                </b-nav-form>
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
          <!-- -->

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mr-auto e">
              <b-navbar-brand v-if="!nightMode" to="/"
                ><img src="../../assets/img/baugo-logo-light.png"
              /></b-navbar-brand>
              <b-navbar-brand v-if="nightMode" to="/"
                ><img src="../../assets/img/logo.png"
              /></b-navbar-brand>
            </b-navbar-nav>
            <b-navbar-nav class="m-auto header-search">
              <b-nav-item class="w-100">
                <b-nav-form class="w-100">
                  <div class="search-input position-relative w-100">
                    <Search
                      ref="autosuggest"
                      :suggestionsArr.sync="suggestions"
                      id="autosuggest__input2"
                      :getSuggestionValue="getSuggestionValue"
                      :fetchResults="fetchResults"
                    ></Search>
                  </div>
                </b-nav-form>
              </b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto d-flex align-items-center">
              <!-- <b-nav-item>
                <router-link to="#" class="color-second"
                  >English</router-link>
              </b-nav-item> -->
              <div class="dropdown-notification cart mr-2">
                <b-dropdown
                  size="lg"
                  variant="link"
                  toggle-class="text-decoration-none border-none"
                  no-caret
                  id="dropdown-right"
                  right
                >
                  <template #button-content>
                    <div class="notification">{{ courseSize }}</div>
                    <i class="ri-shopping-cart-line"></i>
                  </template>
                  <li class="dropdown-content">
                    <ul>
                      <b-dropdown-item
                        to="/"
                        v-for="item in allBasketData"
                        :key="item.id"
                        @click="otherPage(item.is_paid, item.slug)"
                      >
                        <div
                          class="
                            dropdown-profile-img
                            rounded
                            bg-image
                            mr-3
                            wh-48
                          "
                          :style="{
                            backgroundImage: 'url(' + item.picture_link + ')',
                          }"
                        ></div>
                        <div class="notification-dropdown">
                          <div class="notification-message ellipsis-2">
                            {{ item.title }}
                          </div>
                          <div
                            class="color-second"
                            to="#"
                            v-for="itemTeacher in item.courseTeacher"
                            :key="itemTeacher.id"
                          >
                            {{ itemTeacher.user.name }}
                          </div>
                          <div>
                            <b>{{ item.price }}₺</b>
                          </div>
                        </div>
                      </b-dropdown-item>
                      <li v-if="courseSize > 0" class="divider-line mb-0"></li>
                      <div class="h4 text-center color-first">
                        Toplam: {{ allCoursePrice }}₺
                      </div>
                      <b-dropdown-item>
                        <router-link
                          to="/sepet"
                          tag="button"
                          class="btn btn-primary btn-block"
                        >
                          Sepete Git
                        </router-link>
                      </b-dropdown-item>
                    </ul>
                  </li>
                </b-dropdown>
              </div>
              <div class="dropdown-notification mx-4">
                <b-dropdown
                  size="lg"
                  variant="link"
                  toggle-class="text-decoration-none border-none"
                  no-caret
                  id="dropdown-right"
                  right
                >
                  <template #button-content class="test">
                    <div v-if="unread > 0" class="notification"></div>
                    <i class="ri-notification-line"></i>
                  </template>
                  <li class="dropdown-header px-4 py-3">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="cursor-default color-first">Bildirimler</div>
                      <b-dropdown-item>
                        <router-link
                          to="/bildirim-ayarlari"
                          class="color-second"
                          >Ayarlar</router-link
                        >
                      </b-dropdown-item>
                    </div>
                  </li>
                  <li class="divider-line mt-0"></li>
                  <li v-if="notifications.length > 0" class="dropdown-content">
                    <ul>
                      <b-dropdown-item
                        to="../bildirimler"
                        v-for="notification in notifications"
                        :key="notification.id"
                      >
                        <div
                          class="rounded-circle bg-image mr-3 wh-48"
                          :style="
                            getUserData.user.picture_link
                              ? {
                                  backgroundImage:
                                    'url(' +
                                    getUserData.user.picture_link +
                                    ')',
                                }
                              : {
                                  backgroundImage:
                                    'url(https://svgshare.com/i/bnS.svg)',
                                }
                          "
                        ></div>
                        <div class="notification-dropdown">
                          <div class="notification-message ellipsis-2">
                            {{ notification.message }}
                          </div>
                          <div class="notification-time text-right">
                            {{ notification.time_diff }}
                          </div>
                        </div>
                      </b-dropdown-item>
                    </ul>
                  </li>
                  <li v-else class="dropdown-content">
                    <ul>
                      <li>
                        <div class="notification-dropdown">
                          <div
                            class="
                              notification-message
                              ellipsis-2
                              color-second
                              text-small text-center
                              py-2
                            "
                          >
                            Hiç bildiriminiz yok.
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="divider-line mb-0"></li>
                  <li class="text-center px-4 py-3">
                    <router-link to="/bildirimler" class="color-second"
                      >Tümünü Gör</router-link
                    >
                  </li>
                </b-dropdown>
              </div>
              <div class="headProfileButton">
                <b-dropdown
                  size="lg"
                  variant="link"
                  toggle-class="text-decoration-none border-none"
                  no-caret
                  id="dropdown-right"
                  right
                >
                  <template #button-content>
                    <div
                      v-if="!nightMode"
                      class="profileImage rounded-circle bg-image"
                      :style="
                        getUserData.user.picture_link
                          ? {
                              backgroundImage:
                                'url(' + getUserData.user.picture_link + ')',
                            }
                          : {
                              backgroundImage:
                                'url(https://svgshare.com/i/bnS.svg)',
                            }
                      "
                    ></div>
                    <div
                      v-if="nightMode"
                      class="profileImage rounded-circle bg-image"
                      :style="
                        getUserData.user.picture_link
                          ? {
                              backgroundImage:
                                'url(' + getUserData.user.picture_link + ')',
                            }
                          : {
                              backgroundImage:
                                'url(https://svgshare.com/i/bnS.svg)',
                            }
                      "
                    ></div>
                  </template>
                  <li class="dropdown-content pt-0">
                    <ul>
                      <b-dropdown-item
                        to="../profil-duzenle"
                        class="dropdown-profile px-4 py-3 hover-bg-none"
                      >
                        <div
                          v-if="!nightMode"
                          class="rounded-circle bg-image mr-3 wh-48"
                          :style="
                            getUserData.user.picture_link
                              ? {
                                  backgroundImage:
                                    'url(' +
                                    getUserData.user.picture_link +
                                    ')',
                                }
                              : {
                                  backgroundImage:
                                    'url(https://svgshare.com/i/bnS.svg)',
                                }
                          "
                        ></div>
                        <div
                          v-if="nightMode"
                          class="rounded-circle bg-image mr-3 wh-48"
                          :style="
                            getUserData.user.picture_link
                              ? {
                                  backgroundImage:
                                    'url(' +
                                    getUserData.user.picture_link +
                                    ')',
                                }
                              : {
                                  backgroundImage:
                                    'url(https://svgshare.com/i/bnS.svg)',
                                }
                          "
                        ></div>
                        <div class="white-space">
                          <div class="text-truncate dropdown-name">
                            {{ getUserData.user.name }}
                            {{ getUserData.user.surname }}
                          </div>
                          <div class="text-truncate dropdown-mail color-second">
                            {{ getUserData.user.email }}
                          </div>
                        </div>
                      </b-dropdown-item>
                      <li class="divider-line"></li>
                      <b-dropdown-item to="/kurslarim" class="pb-0">
                        <i class="ri-video-line mr-3"></i>
                        Kurslarım
                      </b-dropdown-item>
                      <b-dropdown-item to="/ayarlar" class="pb-0">
                        <i class="ri-settings-2-line mr-3"></i>Ayarlar
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="!nightMode"
                        @click="nightMode = !nightMode"
                        class="pb-0"
                      >
                        <i class="ri-moon-line mr-3"></i>Koyu Tema
                      </b-dropdown-item>
                      <b-dropdown-item to="/satin-alma-gecmisi" class="pb-0">
                        <i class="ri-pages-line mr-3"></i>Satın Alma Geçmişi
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="nightMode"
                        @click="nightMode = !nightMode"
                        class="pb-0"
                      >
                        <i class="ri-sun-line mr-3"></i>Açık Tema
                      </b-dropdown-item>
                      <li class="divider-line mt-2"></li>
                      <b-dropdown-item
                        @click="handleLogout"
                        to="/"
                        class="pb-0 reverse-hover"
                      >
                        <i class="ri-logout-box-line mr-3 color-second"></i
                        ><span class="color-second">Çıkış Yap</span>
                      </b-dropdown-item>
                    </ul>
                  </li>
                </b-dropdown>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </b-container>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { getOrDefine, trackBasket, unTrackBasket } from "../../core/basket";
import { add, get } from "../../core/nightMode";
import { cleaningUser } from "../../core/userData";
import { cleaningBasket } from "../../core/basket";
import axios from "axios";

export default {
  name: "LoginHeader",

  data() {
    return {
      show: false,
      courseSize: 0,
      nightMode: false,
      allBasket: [],
      notifications: [],
      unread: 0,
      allCoursePrice: 0,
      results: [],
      timeout: null,
      selected: null,
      searchText: "",
      debounceMilliseconds: 50,
      usersUrl: "https://jsonplaceholder.typicode.com/users",
      photosUrl: "https://jsonplaceholder.typicode.com/photos",
      inputProps: {
        placeholder: "Arama...",
        class: "form-control w-100",
      },
      photosPromise: [],
      usersPromise: [],
      suggestions: [],
      sectionConfigs: {
        destinations: {
          limit: 6,
          label: "Eğitmenler",
          onSelected: (selected) => {
            this.$router.push("/profil/" + selected.item.id).catch(() => {});
            this.selected = selected.item;
          },
        },
        allBasketData: [],
        hotels: {
          limit: 6,
          label: "Kurslar",
          onSelected: (selected) => {
            this.$router
              .push({
                path: "/kurs-detay/" + selected.item.slug,
                params: selected.item.id,
              })
              .catch(() => {});
            this.selected = selected.item;
          },
        },
        default: {
          onSelected: () => {
            this.doSearch(this);
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getUserData"]),
  },

  watch: {
    nightMode: function () {
      add(this.nightMode);
      this.$emit("nightChange", { nightMode: this.nightMode });
    },
  },
  created() {
    this.nightMode = get();
    this.fetchFirst3Notifications();
    this.fetchUnreadNotificationsCount();
    this.anyWhereClick();
    this.basketData();
  },
  mounted() {
    let notRunCreated = true;
    trackBasket(this.updateBasket.bind(this, notRunCreated));
    trackBasket(this.basketData);
  },
  beforeDestroy() {
    unTrackBasket(this.updateBasket);
    unTrackBasket(this.basketData);
  },

  methods: {
    handleLogout() {
      delete axios.defaults.headers.common["Authorization"];
      this.$store.dispatch("setUserData", null);
      cleaningUser();
      cleaningBasket();

      this.$router.push("/").catch(() => {});
    },
    basketData() {
      this.allBasketData = getOrDefine();
      this.courseSize = this.allBasketData.length;
      if (this.courseSize != null && this.courseSize > 0) {
        this.allCoursePrice = this.allBasketData
          .map((x) => x.price)
          .reduce((total, price) => total + price);
      } else {
        this.allCoursePrice = 0;
      }
    },
    goBasket() {
      this.$router.push({ path: "/sepet" }).catch((err) => console.log(err));
    },
    updateBasket() {
      let courses = getOrDefine();
      if (courses == null || courses.length == 0) {
        this.courseSize = 0;
        return;
      }
      this.courseSize = courses.length;
      this.show = true;
    },
    anyWhereClick() {
      document.body.addEventListener("click", () => {
        this.show = false;
      });
    },
    otherPage(is_paid, slug) {
      if (is_paid == true) {
        this.$router.push("/egitim-video/" + slug);
      } else {
        this.$router.push("/kurs-detay/" + slug);
      }
    },
    fetchFirst3Notifications() {
      this.axios
        .get("/notification/1?language_code=tr")
        .then((response) => {
          Object.keys(response.data.data).forEach((date) => {
            response.data.data[date].forEach((noti) => {
              if (this.notifications.length < 3)
                this.notifications.push(JSON.parse(JSON.stringify(noti)));
              else return;
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchUnreadNotificationsCount() {
      this.axios.get("/notification/unread").then((response) => {
        this.unread = response.data.data;
      });
    },
    doSearch() {
      console.log("Searching...");
    },
    fetchResults(queryInput) {
      const query = queryInput ? queryInput : "/";
      clearTimeout(this.timeout);
      if (query != "/") {
        this.timeout = setTimeout(() => {
          axios
            .post("/search", "search=" + query)
            .then((response) => {
              this.photosPromise = this.toCollectCourse(response.data.data);
              this.usersPromise = this.toCollectUser(response.data.data);
              if(this.photosPromise == null && this.usersPromise==null) return
              Promise.all([this.photosPromise, this.usersPromise]).then(
                (values) => {
                  console.log(values)
                  this.suggestions = [];
                  this.selected = null;
                  const photos = this.filterResults(values[0], query, "title");
                  const users = this.filterResults(
                    values[1],
                    query,
                    "full_name"
                  );

                  users.length &&
                    this.suggestions.push({
                      name: "destinations",
                      data: users,
                    });
                  photos.length &&
                    this.suggestions.push({ name: "hotels", data: photos });
                  console.log(photos);
                }
              );
            })
            .catch((error) => console.log(error.response));
        }, this.debounceMilliseconds);
      } else {
        this.suggestions = [];
      }
    },
    filterResults(data, text, field) {
      return data
        .filter((item) => {
          return item[field];
        })
        .sort();
    },
    getSuggestionValue(suggestion) {
      let { name, item } = suggestion;
      return name === "hotels" ? item.title : item.name;
    },
    toCollectUser(data) {
      if (data == null || data == "") return;
      return data.filter((x) => x.type == "user");
    },
    toCollectCourse(data) {
      if (data == null || data == "") return;
      return data.filter((x) => x.type != "user");
    },
  },
};
</script>



var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('article',{staticClass:"card position-relative"},[_c('b-dropdown',{staticClass:"dropdown ml-auto after-none position-absolute right-0 top-0",attrs:{"id":"dropdown-right","text":"Dropdown Button","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"ri-more-line"})]},proxy:true}])},[_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.ShareModal",modifiers:{"ShareModal":true}}],on:{"click":function($event){return _vm.shareBtn()}}},[_c('i',{staticClass:"ri-share-fill mr-3 align-bottom top-minus-1"}),_vm._v(" Paylaş ")]),(_vm.user && _vm.user.access_token)?_c('b-dropdown-item',[_c('favori-button',{staticClass:"p-lg-1 btn btn-link text-center",attrs:{"course":_vm.course}})],1):_vm._e()],1),_c('a',{on:{"click":function($event){return _vm.otherPageBtn()}}},[_c('div',{staticClass:"card-img-top card-image",style:({
          backgroundImage: 'url(' + _vm.courseData.picture_link + ')',
        })})]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"font-weight-bold ellipsis-2 mb-2"},[_c('a',{on:{"click":function($event){return _vm.otherPageBtn()}}},[_vm._v(" "+_vm._s(_vm.courseData.title)+" ")])]),_c('div',{staticClass:"text-capitalize text-truncate mb-2"},[_c('a',{staticClass:"color-second"},[_vm._v(_vm._s((_vm.courseData.instructor&& _vm.courseData.instructor.user && _vm.courseData.instructor.user.full_name) ? _vm.courseData.instructor.user.full_name : "")+" ")])]),(
          _vm.courseData.progress && _vm.courseData.progress.percentage ? true : false
        )?_c('div',{staticClass:"mb-1 mt-auto pt-2"},[_c('b-progress',{staticClass:"bg-third",attrs:{"value":_vm.courseData.progress && _vm.courseData.progress.percentage
              ? _vm.courseData.progress.percentage
              : false,"variant":"success"}})],1):_vm._e(),_c('div',{staticClass:"\n          card-star-rating\n          d-flex\n          justify-content-between\n          align-items-center\n        "},[(
            _vm.courseData.progress && _vm.courseData.progress.percentage
              ? true
              : false
          )?_c('b',{staticClass:"mb-0 pt-2"},[_vm._v(" %"+_vm._s(_vm.courseData.progress && _vm.courseData.progress.percentage ? _vm.courseData.progress.percentage : 0)+" ")]):_vm._e(),_c('div',{staticClass:"card-star-rating d-flex align-items-center"},[_c('b',{staticClass:"pt-2 mr-2 h4 mb-0 color-yellow",class:{
              'd-none': !(
                _vm.courseData.rating
              ),
            }},[_vm._v(" "+_vm._s(_vm.courseData.rating || "")+" ")]),_c('star-rating',{attrs:{"rating":_vm.courseData.rating || 0,"round-start-rating":false,"read-only":true,"star-points":[
              23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46,
              19, 31, 17 ]}})],1)]),_c('div',{staticClass:"d-flex align-items-center mt-3"},[_c('div',{staticClass:"h3 mb-0 mr-2"},[_vm._v(" "+_vm._s(_vm.courseData.price ? _vm.courseData.price : "27,99")+"₺ ")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
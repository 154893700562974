<template>
  <div class="main-content">
    <div class="container">
        <h2 class="gradient-color mb-4">İletişim</h2>
        <div class="maps-wrapper mb-5">
           <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12036.907949892378!2d29.0092591!3d41.042165!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7671d1b9817b8519!2sBah%C3%A7e%C5%9Fehir%20University!5e0!3m2!1sen!2str!4v1638304497522!5m2!1sen!2str" width="100%" height="400" style="border:0;" class="5" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
              <h4 class="mb-3">Bahçeşehir Üniversitesi</h4> 
              <div class="mb-3">
                Çırağan Caddesi Osmanpaşa Mektebi Sokak No: 4 - 6 34353 Beşiktaş, İSTANBUL / TÜRKİYE
              </div> 
              <div class="mb-4"><a href="tel:4442864">444 2864</a></div>
          </div>
           <div class="col-12 col-md-6">
                      <div class="mb-3" v-if="facebook && facebook.value">
                        <a target="blank" :href="facebook.value" class="social-media-link facebook-color">
                          <i class="ri-facebook-fill mr-3 facebook-color align-middle top-minus-1 ri-lg"></i><span>facebook/baugo</span>
                        </a>
                      </div>
                      <div class="mb-3" v-if="twitter && twitter.value">
                        <a target="blank" :href="twitter.value" class="social-media-link twitter-color">
                          <i class="ri-twitter-fill mr-3 twitter-color align-middle top-minus-1 ri-lg"></i><span>twitter/baugo</span>
                        </a>
                      </div>
                      <div class="mb-3" v-if="instagram && instagram.value">
                        <a :href="instagram.value" target="blank" class="social-media-link instagram-color">
                          <i class="ri-instagram-fill mr-3 instagram-color align-middle top-minus-1 ri-lg"></i><span>instagram/baugo</span>
                        </a>
                      </div>
                    </div>
        </div>
                   
    </div>
  </div>
</template>

<script>
export default {
  name: 'Iletisim',
   computed:{
    facebook: {
      get() {
        return this.$store.getters.getSettings("facebook");
      },
    },
    twitter: {
      get() {
        return this.$store.getters.getSettings("twitter");
      },
    },
    instagram: {
      get() {
        return this.$store.getters.getSettings("instagram");
      },
    },
  }
}

</script>


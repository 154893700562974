<template>
  <div class="main-content">
    <div class="container">
          <a v-b-modal.AlertModal>Launch demo modal</a>
          <h2 class="gradient-color mb-4">Öğrenim İçeriğim</h2>
          <h3><a href="../Hosgeldin">Hoşgeldin</a></h3>
          <h3><a href="../profil">Profil</a></h3>
          <h3><a href="../profil-duzenle">Profil Düzenle</a></h3>
          <h3><a href="../bildirimler">Bildirimler</a></h3>
          <h3><a href="../bildirim-ayarlari">Bildirim Ayarları</a></h3>
          <h3><a href="../egitim-video/tbtk-101-temel-bilgi-teknolojileri-kullanimi-egitimi">Eğitim Video</a></h3>
          <h3><a href="../egitim-anket">Eğitim Anket</a></h3>
          <h3><a href="../egitim-quiz">Eğitim quiz</a></h3>
          <h3><a href="../ogrenim-icerigi">Öğrenim İçeriği</a></h3>
          <h3><a href="../anasayfa">Anasayfa</a></h3>
          <h3><a href="../ayarlar">Ayarlar</a></h3>
          <h3><a href="../gizlilik-sozlesmesi">Gizlilik Sözleşmesi</a></h3>
          <h3><a href="../kurs-detay">Kurs Detay</a></h3>
          <h3><a href="../kurslarim">Kurslarım</a></h3>
          <h3><a href="../makbuz">Makbuz</a></h3>
          <h3><a href="../odeme">Ödeme</a></h3>
          <h3><a href="../satin-alma-gecmisi">Satın Alma Geçmişi</a></h3>
          <h3><a href="../sepet">Sepet</a></h3>
          <h3><a href="../404">404</a></h3>
          <h3><a href="../500">500</a></h3>
          <h3><a href="../ui">Ui</a></h3>

        <div>
</div>
  
    </div>
     <AlertModal></AlertModal> 
  </div>
</template>

<script>

export default {
  name: 'Link',

  }
</script>
<template>
  <div class="accordion custom-accordion" role="tablist">
    <b-card
      v-for="(section, index) in sections"
      :key="index"
      class="shadow-none"
      :class="{ 'bg-transparent': transparent }"
      no-body
    >
      <div
        class="card-header border-0"
        :class="{
          'py-1 px-0 bg-transparent': transparent,
          'p-2': !transparent,
        }"
        role="tab"
      >
        <button
          class="btn pb-1"
          :class="{
            'pl-0': transparent,
            'background-transparent': !transparent,
            collapsed: false,
            'not-collapsed': lastSection == index,
          }"
          v-b-toggle="'accordion-' + index"
          :aria-expanded="lastSection == index ? 'true' : 'false'"
          :aria-controls="'accordion-' + index"
        >
          {{ section.title }}
        </button>
        <div
          class="color-second text-small"
          :class="{ 'pl-0': transparent, 'pl-3': !transparent }"
          v-if="section.resources && section.resources.length"
        >
          {{ section.resources.length || 0 }}
          <span class="slash">/</span>
          {{ section.resources.length || 0 }}
          |
          <template
            v-if="
              section.total_duration_object &&
              section.total_duration_object.hour != '00' &&
              section.total_duration_object.hour != null
            "
          >
            {{ parseInt(section.total_duration_object.hour) }}s
          </template>
          <template
            v-if="
              section.total_duration_object &&
              section.total_duration_object.minute != '00' &&
              section.total_duration_object.minute != null
            "
          >
            {{ parseInt(section.total_duration_object.minute) }}dk
          </template>
          <template
            v-if="
              section.total_duration_object &&
              section.total_duration_object.second != '00' &&
              section.total_duration_object.second != null
            "
          >
            {{ parseInt(section.total_duration_object.second) }}sn
          </template>
        </div>
      </div>
      <b-collapse
        :id="'accordion-' + index"
        :visible="lastSection == index"
        accordion="accordion"
        role="tabpanel"
        :class="{ 'bg-transparent': transparent }"
      >
        <b-card-body
          :class="{ 'pl-0': transparent, 'pl-3 ml-2': !transparent }"
        >
          <div
            v-for="(item, i) in resultQuery(section.resources)"
            :key="'s' + i"
          >
            <div class="curriculum-wrapper d-flex mb-3">
              <div
                class="checkbox-group"
                v-if="item.video.progress && !transparent"
              >
                <input
                  type="checkbox"
                  class="check-group"
                  name="check"
                  :id="item.id"
                  v-model="item.video.progress.is_completed"
                  @change="checkState(item)"
                />
                <!--
                              <input
                                type="checkbox" class="check-group" name="check"
                                v-model="checkedList" :id="item.id" :value="item.id" @change="checkState(item.id)"
                              />-->
                <!--
                                :checked="item.video.progress && 
                                [new Date('2020-02-13T' + item.video.duration) ===
                                 new Date('2020-02-13T' + item.video.progress.last_time)]"-->
                <label class="form-check-label" for="check">
                  <span class="check"></span>
                  <span class="box"></span>
                </label>
              </div>
              <div class="curriculum-content" v-if="item.video.progress">
                <div>
                  <a @click="fetchVideo(item, index, i)">
                    {{ item.title }} - {{ item.video.title }}
                  </a>
                </div>
                <div class="color-second text-small">
                  {{ item.video.duration_text }}
                </div>
              </div>
              <span v-else>{{ item.title }}</span>
            </div>
          </div>
          <template v-if="!searchQuery">
            <div class="curriculum-wrapper d-flex mb-3" v-if="showQuizAndPoll">
              <div class="checkbox-group">
                <input
                  type="checkbox"
                  name="check"
                  value="1"
                  class="check-group"
                />
                <label for="check" class="form-check-label">
                  <span class="check"></span>
                  <span class="box"></span>
                </label>
              </div>
              <div class="curriculum-content">
                <div class="pt-0-15">
                  <a @click="goPoll">
                    <i class="ri-pencil-fill mr-2"></i>
                    <span class="top-minus-1"> Anket </span>
                  </a>
                </div>
                <div class="color-second">10 Soru | 10 Dakika</div>
              </div>
            </div>
            <div class="curriculum-wrapper d-flex mb-3" v-if="showQuizAndPoll">
              <div class="checkbox-group">
                <input
                  type="checkbox"
                  name="check"
                  value="1"
                  class="check-group"
                />
                <label for="check" class="form-check-label">
                  <span class="check"></span>
                  <span class="box"></span>
                </label>
              </div>
              <div class="curriculum-content">
                <div class="pt-0-15">
                  <a @click="goExam">
                    <i class="ri-pencil-fill mr-2"></i>
                    <span class="top-minus-1"> Quiz </span>
                  </a>
                </div>
                <div class="color-second">20 Soru | 20 Dakika</div>
              </div>
            </div>
            <div v-if="moots != null">
              <div
                v-for="sectionMoot in sectionMoots"
                :key="'mooot' + sectionMoot.id"
                class="curriculum-wrapper d-flex mb-3 pl-4 ml-2"
              >
                <div class="curriculum-content">
                  <div class="pt-0-15">
                    <a v-b-modal.ModalXl @click="openMoots(sectionMoot)">
                      <i class="ri-chat-4-fill mr-2 scaleX"></i>
                      <span class="top-minus-1">
                        {{ sectionMoot.subject }}
                      </span>
                    </a>
                  </div>
                  <div
                    class="color-second"
                    v-if="sectionMoot && sectionMoot.posts.length"
                  >
                    {{ sectionMoot.posts.length }} Yorum
                  </div>
                </div>
              </div>
            </div>
          </template>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import { getLastSection, updateSection } from "../../core/lastSection";
export default {
  props: {
    sections: {
      type: Array,
      defaultValue: [],
    },
    src: {
      defaultValue: {},
    },
    player: Object,
    sectionMoot: Object,
    mootsToShow: Number,
    mootRemaincharactersText: String,
    searchQuery: null,
    moots: null,
    saveVideoLastTime: Function,
    convertSeconds: Function,
    courseId: null,
    showQuizAndPoll: {
      defaultValue: false,
    },
    transparent: {
      defaultValue: false,
    },
  },
  data() {
    return {
      lastSection: 0,
      saveSection: 0,
    };
  },
  created() {
    this.getSection()
  },
  computed: {
    sectionMoots() {
      return Object.values(this.moots).filter((item) => {
        return item.course_section_id === this.sectionId;
      });
    },
    resource: {
      get() {
        return this.src;
      },
      set(val) {
        this.$emit("update:src", val);
      },
    },
    currentTime: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    searchQuery(val) {
      console.log("selam");
      if (val != "") this.lastSection = 0;
      else this.lastSection = this.saveSection;
    },
  },
  methods: {
    resultQuery(resources) {
      if (this.searchQuery) {
        return resources.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.title.toLowerCase().includes(v) ||
                item.video.title.toLowerCase().includes(v)
            );
        });
      } else {
        return resources;
      }
    },
    checkState(item) {
      console.log(item.id);
      if (this.resource.id == item.id) {
        if (item.video.progress.is_completed) {
          this.player.currentTime(
            this.convertSeconds(this.resource.video.duration)
          );
        } else {
          this.player.currentTime(0);
        }
      } else {
        if (item.video.progress.is_completed) {
          this.saveVideoLastTime(
            item.id,
            this.convertSeconds(item.video.duration)
          );
        } else {
          this.saveVideoLastTime(item.id, 0);
        }
      }
    },
    fetchVideo(item, sectionIndex, childIndex) {
      this.updateIndex(sectionIndex, childIndex);
      this.saveVideoLastTime();
      this.resource = item;
      this.currentTime = 0;
    },
    openMoots(moot) {
      this.sectionMoot = moot;
      this.moot = "";
      this.mootRemaincharactersText = "500";
      this.mootsToShow = 2;
    },
    goPoll() {
      this.$router.push({ name: "egitimAnket", params: { id: this.courseId } });
    },
    goExam() {
      this.$router.push({ name: "EgitimQuiz", params: { id: this.courseId } });
    },
    updateIndex(sectionIndex, childIndex) {
      this.lastSection = sectionIndex;
      updateSection({ section: sectionIndex, index: childIndex });
      this.getSection();
    },
    getSection() {
      let section = getLastSection();
      if (section == null || section == "") return;
      this.lastSection = section.section;
      this.saveSection = section.section;
    },
  },
};
</script>

<template>
  <div id="app" :class="{ 'theme-dark': nightMode }">
    <nprogress-container v-if="show" />
    <div class="position-relative">
      <notifications position="bottom right" />
        <LoginHeader v-if="getUserData" @nightChange="nightCatcher" />
        <Header v-else @nightChange="nightCatcher" />
      <router-view></router-view>
      <shareModal :courseLink="courseLink"></shareModal>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "./components/layout/Header";
import LoginHeader from "./components/layout/LoginHeader";
import Footer from "./components/layout/Footer";
import { mapGetters } from "vuex";
import { getOrDefine } from "../src/core/basket";
import { getValue,cleaningUser } from "../src/core/userData";
import { get, add } from "./core/nightMode";
import { getOrDefineLB } from "./core/laterBuy";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import { getOrDefineFav } from "./core/anonymousFavorite";
import nprogress from "nprogress";
import {getLastTime} from "../src/core/getLastTime"

export default {
  name: "app",
  components: {
    Header,
    LoginHeader,
    Footer,
    NprogressContainer,
  },

  data() {
    return {
      key: 0,
      show: false,
      nightMode: false,
      errorMessages: {},
      indexValue: 0,
    };
  },
  methods: {
    nightCatcher(data) {
      this.nightMode = data.nightMode;
      this.nightMode
        ? document.body.classList.add("theme-dark")
        : document.body.classList.remove("theme-dark");
    },
    setLoader() {
      this.axios.interceptors.request.use(
        (config) => {
          this.show = this.loaderShow;
          if(this.show){
            nprogress.start();
          }
          return config;
        },
        (error) => {
          nprogress.done();
          this.show = false;
          throw error;
        },
  
      );

      this.axios.interceptors.response.use(
        (response) => {
          nprogress.done();
          this.show = false;
          return response;
        },
        (error) => {
          nprogress.done();
          console.log(error.response);
          this.show = false;
          if (error.response.status >= 500) {
            this.$router.push("/500");
          }
          if(error.response.status == 401){
            cleaningUser()
            this.$router.push("/");
          }
          throw error;
        }
      );
    },
    getSettingsInfo() {
      this.axios
        .get(
          "/settings/terms_of_use,privacy_policy,cancellation_policy,distance_selling_contract,preliminary_information,facebook,twitter,instagram"
        )
        .then((res) => {
          let data = res.data.data;
          this.$store.commit("ADD_SETTİNGS", data);
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    courseLink() {
      return this.$store.state.courseLink;
    },
    ...mapGetters(["getUserData"]),
    loaderShow: {
      get() {
        return this.$store.state.loaderShow;
      },
    },
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    nightMode() {
      add(this.nightMode);
    },
  },
  async created() {
    const nightMode = get();
    if (nightMode) {
      this.nightMode = nightMode;
    }
    getLastTime()
    getOrDefine();
    getOrDefineLB();
    getOrDefineFav();
    this.setLoader();
    this.getSettingsInfo();  
  },

  mounted() {
    const userData = getValue();
    if (userData) {
      this.$store.dispatch("setUserData", userData);
    }
     if (this.user && this.user.access_token){
      this.$store.dispatch("fetchFavoritedCourses");
    }
  },
};
</script>

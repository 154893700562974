
<template>
  <header class="header">
    <b-container>
      <div>
        <b-navbar toggleable="lg" type="dark">
          <b-navbar-toggle
            target="nav-collapse"
            class="w-33 text-left"
            @click="menuCollapse()"
          >
            <template>
              <i class="ri-menu-2-line"></i>
            </template>
          </b-navbar-toggle>
          <b-navbar-brand
            v-if="!nightMode"
            class="navbar-toggler mobile-nav p-0 m-0 align-items-center"
          >
            <img src="../../assets/img/baugo-logo-light.png" />
          </b-navbar-brand>
          <b-navbar-brand
            v-if="nightMode"
            class="navbar-toggler mobile-nav p-0 m-0 align-items-center"
          >
            <img src="../../assets/img/logo.png" />
          </b-navbar-brand>

          <div
            class="
              d-flex
              align-items-center
              p-0
              w-33
              justify-content-end
              top-plus-1
            "
          >
            <b-navbar-toggle target="nav-collapse" @click="searchCollapse()">
              <template>
                <i class="ri-search-line"></i>
              </template>
            </b-navbar-toggle>
            <b-navbar-toggle target="dropdown-toggle">
              <div class="dropdown-notification cart mr-2">
                <b-dropdown
                  size="lg"
                  variant="link"
                  toggle-class="text-decoration-none border-none"
                  no-caret
                  id="dropdown-right"
                  right
                >
                  <template #button-content>
                    <div class="notification">{{ courseSize }}</div>
                    <i class="ri-shopping-cart-line"></i>
                  </template>
                  <li class="dropdown-content">
                    <ul>
                      <b-dropdown-item
                        to="/"
                        v-for="item in allBasketData"
                        :key="item.id"
                        @click="otherPager(item.is_paid, item.slug)"
                      >
                        <div
                          class="
                            dropdown-profile-img
                            rounded
                            bg-image
                            mr-3
                            wh-48
                          "
                          :style="{
                            backgroundImage: 'url(' + item.picture_link + ')',
                          }"
                        ></div>
                        <div class="notification-dropdown">
                          <div class="notification-message ellipsis-2">
                            {{ item.title }}
                          </div>
                          <div
                            class="
                              text-capitalize text-truncate
                              ellipsis-2
                              mb-2
                              color-second
                              white-space
                              text-small
                            "
                          >
                            <router-link
                              to="#"
                              v-for="itemTeacher in item.courseTeacher"
                              :key="itemTeacher.id"
                              class="color-second"
                              >{{ itemTeacher.user.name }}
                            </router-link>
                          </div>
                          <div>
                            <b>{{ item.price }}₺</b>
                          </div>
                        </div>
                      </b-dropdown-item>
                      <li v-if="courseSize > 0" class="divider-line mb-0"></li>
                      <div class="h4 text-center color-first">
                        Toplam: {{ allCoursePrice }}₺
                      </div>
                      <b-dropdown-item>
                        <router-link
                          to="/sepet"
                          tag="button"
                          class="btn btn-primary btn-block"
                        >
                          Sepete Git
                        </router-link>
                      </b-dropdown-item>
                    </ul>
                  </li>
                </b-dropdown>
              </div>
            </b-navbar-toggle>
          </div>

          <!-- mobile search -->
          <b-collapse
            v-show="searchCollapsed"
            class="w-100 searchCollapse"
            v-bind:style="{ display: searchCollapsed ? 'none' : 'block' }"
          >
            <b-navbar-nav class="mt-1 mx-0">
              <b-nav-item>
                <b-nav-form class="w-100">
                  <div class="search-input position-relative w-100">
                    <b-form-input
                      class="mr-sm-2 w-100"
                      placeholder="Arama..."
                    ></b-form-input>
                    <button class="btn bg-transparent">
                      <i class="ri-search-line"></i>
                    </button>
                  </div> </b-nav-form
              ></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
          <!-- -->
          <b-collapse
            v-show="menuCollapsed"
            class="w-100 menuCollapse"
            v-bind:style="{ display: menuCollapsed ? 'none' : 'block' }"
          >
            <b-navbar-nav>
              <li class="nav-item py-2">
                <a v-b-modal.modal-login class="py-2">Giriş Yap</a>
              </li>
              <li class="nav-item py-2">
                <a v-b-modal.modal-register class="py-2">Üye Ol</a>
              </li>
            </b-navbar-nav>
          </b-collapse>

          <b-collapse id="nav-collapse" is-nav class="d-none">
            <b-navbar-nav class="mr-auto">
              <b-navbar-brand v-if="nightMode" to="/"
                ><img src="../../assets/img/logo.png"
              /></b-navbar-brand>
              <b-navbar-brand v-if="!nightMode" to="/"
                ><img src="../../assets/img/baugo-logo-light.png"
              /></b-navbar-brand>
            </b-navbar-nav>
            <b-navbar-nav class="m-auto header-search">
              <b-nav-item class="w-100">
                <b-nav-form class="w-100">
                  <div class="search-input position-relative w-100">
                    <Search
                      ref="autosuggest"
                      :suggestionsArr.sync="suggestions"
                      id="autosuggest__input2"
                      :getSuggestionValue="getSuggestionValue"
                      :fetchResults="fetchResults"
                      class="mr-sm-2 w-100"
                    ></Search>
                  </div>
                </b-nav-form>
              </b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto d-flex align-items-center">
              <li class="nav-item mr-3">
                <div class="dropdown-notification cart mr-2">
                  <b-dropdown
                    size="lg"
                    variant="link"
                    toggle-class="text-decoration-none border-none"
                    no-caret
                    id="dropdown-right"
                    right
                  >
                    <template #button-content>
                      <div class="notification">{{ courseSize }}</div>
                      <i class="ri-shopping-cart-line"></i>
                    </template>
                    <li class="dropdown-content">
                      <ul>
                        <b-dropdown-item
                          to="/"
                          v-for="item in allBasketData"
                          :key="item.id"
                          @click="otherPager(item.is_paid, item.slug)"
                        >
                          <div
                            class="
                              dropdown-profile-img
                              rounded
                              bg-image
                              mr-3
                              wh-48
                            "
                            :style="{
                              backgroundImage: 'url(' + item.picture_link + ')',
                            }"
                          ></div>
                          <div class="notification-dropdown">
                            <div class="notification-message ellipsis-2">
                              {{ item.title }}
                            </div>
                            <div
                              class="
                                text-capitalize text-truncate
                                ellipsis-2
                                mb-2
                                color-second
                                white-space
                                text-small
                              "
                            >
                              <router-link
                                to="#"
                                v-for="itemTeacher in item.courseTeacher"
                                :key="itemTeacher.id"
                                class="color-second"
                                >{{ itemTeacher.user.name }}
                              </router-link>
                            </div>
                            <div>
                              <b>{{ item.price }}₺</b>
                            </div>
                          </div>
                        </b-dropdown-item>
                        <li
                          v-if="courseSize > 0"
                          class="divider-line mb-0"
                        ></li>
                        <div class="h4 text-center color-first">
                          Toplam: {{ allCoursePrice }}₺
                        </div>
                        <b-dropdown-item>
                          <router-link
                            to="/sepet"
                            tag="button"
                            class="btn btn-primary btn-block"
                          >
                            Sepete Git
                          </router-link>
                        </b-dropdown-item>
                      </ul>
                    </li>
                  </b-dropdown>
                </div>
              </li>
              <li class="nav-item mr-3">
                <button
                  type="button"
                  class="btn outline-primary"
                  v-b-modal.modal-login
                >
                  Giriş Yap
                </button>
              </li>
              <li class="nav-item">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-b-modal.modal-register
                >
                  Üye Ol
                </button>
              </li>
            </b-navbar-nav>
          </b-collapse>
          <!-- dark light mode switch -->
          <!-- <i class="fas fa-sun icon-sun" v-if="nightMode" @click="nightMode = !nightMode"></i>
        <i class="fas fa-moon icon-moon" v-else @click="nightMode = !nightMode"></i> -->
        </b-navbar>
        <!-- dark light mode switch -->
        <!-- <i
          class="fas fa-sun icon-sun"
          v-if="nightMode"
          @click="nightMode = !nightMode"
        ></i>
        <i
          class="fas fa-moon icon-moon"
          v-else
          @click="nightMode = !nightMode"
        ></i> -->
        <!-- Modals -->
        <LoginModal> </LoginModal>
        <ForgotPassword> </ForgotPassword>
        <RegisterModal> </RegisterModal>
      </div>
    </b-container>
  </header>
</template>

<script>
import { get, add } from "../../core/nightMode";
import axios from "axios";
import { getOrDefine, trackBasket, unTrackBasket } from "../../core/basket";
export default {
  name: "Header",

  data: () => {
    return {
      nightMode: false,
      menuCollapsed: false,
      searchCollapsed: false,
      suggestions: [],
      selected: null,
      photosPromise: [],
      usersPromise: [],
      debounceMilliseconds: 50,
      allBasketData: [],
      allCoursePrice: 0,
      courseSize: 0,
    };
  },
  methods: {
    menuCollapse: function () {
      // var myNav = document.querySelector('.menuCollapsed');
      this.menuCollapsed = !this.menuCollapsed;
      // !this.menuCollapsed ? myNav.classList.add('show') : myNav.classList.remove('show');
      this.searchCollapsed = false;
    },
    searchCollapse: function () {
      // var mySearchCollapsed  = document.querySelector('.searchCollapsed');
      this.searchCollapsed = !this.searchCollapsed;
      // this.searchCollapsed ? mySearchCollapsed .classList.add('show') : mySearchCollapsed .classList.remove('show');
      this.menuCollapsed = false;
    },
    fetchResults(queryInput) {
      const query = queryInput ? queryInput : "/";
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        axios
          .post("/search", "search=" + query)
          .then((response) => {
            this.photosPromise = response.data.data.courses;
            this.usersPromise = response.data.data.users;
            Promise.all([this.photosPromise, this.usersPromise]).then(
              (values) => {
                this.suggestions = [];
                this.selected = null;

                const photos = this.filterResults(values[0], query, "title");
                const users = this.filterResults(values[1], query, "full_name");

                users.length &&
                  this.suggestions.push({ name: "destinations", data: users });
                photos.length &&
                  this.suggestions.push({ name: "hotels", data: photos });
              }
            );
          })
          .catch((error) => console.log(error.response));
      }, this.debounceMilliseconds);
    },
    filterResults(data, text, field) {
      return data
        .filter((item) => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field];
          }
        })
        .sort();
    },
    getSuggestionValue(suggestion) {
      let { name, item } = suggestion;
      return name === "hotels" ? item.title : item.name;
    },
    basketData() {
      this.allBasketData = getOrDefine();

      this.courseSize = this.allBasketData.length;
      if (this.courseSize != null && this.courseSize > 0) {
        this.allCoursePrice = this.allBasketData
          .map((x) => x.price)
          .reduce((total, price) => total + price);
      } else {
        this.allCoursePrice = 0;
      }
    },
    goBasket() {
      this.$router.push({ path: "/sepet" }).catch((err) => console.log(err));
    },
    updateBasket() {
      let courses = getOrDefine();
      if (courses == null || courses.length == 0) {
        this.courseSize = 0;
        return;
      }
      this.courseSize = courses.length;
      this.show = true;
    },
  },
  created() {
    this.basketData();
    this.nightMode = get();
  },
  mounted() {
    let notRunCreated = true;
    trackBasket(this.updateBasket.bind(this, notRunCreated));
    trackBasket(this.basketData);
  },
  beforeDestroy() {
    unTrackBasket(this.updateBasket);
    unTrackBasket(this.basketData);
  },
  watch: {
    nightMode: function () {
      add(this.nightMode);
      this.$emit("nightChange", { nightMode: this.nightMode });
    },
  },
};
</script>


<template>
  <div>
    <b-modal id="Questions" centered :hide-footer="true">
      <template #modal-header="{ close }" class="container">
        <h3>Soru {{ showQuestion.id }}</h3>
        <button type="button" aria-label="Close" class="close" @click="close()">
          <i class="ri-close-line color-third"></i>
        </button>
      </template>
      <div v-if="showQuestion">
        <div
          v-if="
            showQuestion.question_type == 'multiple_choice' ||
            showQuestion.question_type == 'either_or'
          "
          class="curriculum-question-wrapper"
        >
          <div class="mb-4">
            <p>
              {{ showQuestion.question }}
            </p>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div
                class="py-2 px-3 mb-2 rounded q-option"
                role="button"
                :class="selectClass(option.id)"
                @click="selectAnswer(option.id)"
                :key="'qq' + option.id"
                v-for="(option, index) in showQuestion.items"
              >
                {{ optionsKey[index] }} {{ option.item }}
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="saveAnswer('multiple-choice')"
            >
              Gönder
            </button>
          </div>
        </div>
        <div v-else class="curriculum-question-wrapper">
          <template v-if="answer != null">
            <div v-if="answer" class="alert alert-success mb-4" role="alert">
              <div class="alert-content w-100 d-inline-block">
                <div class="my-1">
                  <strong> Doğru Cevap</strong>
                </div>
              </div>
            </div>
            <div v-if="!answer" class="alert alert-error mb-4" role="alert">
              <div class="alert-content w-100 d-inline-block">
                <div class="my-1">
                  <strong> Yanlış Cevap</strong>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="mb-4 question">{{ showQuestion.question }}</div>
            <b-form-group class="mb-3">
              <b-form-textarea
                v-model="openAnswer"
                id="textarea"
                rows="5"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="saveAnswer('open-ended')"
            >
              Gönder
            </button>
          </template>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "Questions",
  props:{
    showQuestion:{default:{}},
    selectClass:{type:Function},
    selectAnswer:{type:Function},
    saveAnswer:{type:Function},
    answer:{default:""},
    reply:{default:""}
  },
  computed:{
    openAnswer:{
      get(){
        return this.reply
      },
      set(val){
        this.$emit('update:reply',val)
      }
    }
  }
};
</script>
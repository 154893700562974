  <template>
  <div class="main-content">
    <div class="container">
      <h2 class="gradient-color mb-4">Sepet</h2>
      <!-- dolu sepet baslangic -->

      <div class="row flex-column-reverse flex-lg-row mb-5 pb-4">
        <div class="col-12 col-lg-9 mt-5 mt-lg-0">
          <div class="cart-items mb-4 pb-1" v-if="allBasketData">
            <basket-card
              v-for="(item, i) in allBasketData"
              :key="i"
              :addLaterCourse="addLaterCourse"
              :deleteCourse="deleteCourse"
              :course="item"
            />
          </div>
          <div
            class="mb-4 pb-1"
            v-if="allBasketData.length === 0 && laterBasket.length === 0"
          >
            <div class="mt-3 mb-5 text-center color-third">
              <div class="mb-1">
                <i class="ri-shopping-bag-line ri-8x color-third"></i>
              </div>
              <div class="h4">Sepetinizde kurs bulunmuyor.</div>
            </div>
          </div>
          <div class="cart-items mb-5" v-if="laterBasket.length != 0 ">
            <h3 class="mb-4">Daha Sonrası İçin Kaydedilenler</h3>
            <basket-card
              v-for="(item, i) in laterBasket"
              :key="i"
              :otherPage="otherPage"
              :moveBasket="moveBasket"
              :course="item"
              :later="true"
            />
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="mb-3 pb-1">
            <div class="mb-2 pb-1 font-weight-bold">
             <!-- Tutar: {{ allCoursePrice }}₺-->
            </div>
            <!--   <div class="mb-2 pb-1 font-weight-bold">
                Kurs İndirimi
                <span class="color-second text-small">(%20)</span>:
                <span class="color-green ml-2">20₺</span>
              </div> -->
            <!-- Kupon indirimi olduğu zaman bu alanda gözükmelidir -->
            <!-- <div class="mb-2 pb-1 font-weight-bold">Kupon İndirimi: <span class="color-green ml-2">10₺</span></div> -->
            <div class="h3 mb-0 d-inline-block">
              Toplam Tutar: {{ allCoursePrice }}₺
            </div>
          </div>
          <!-- <b-input-group class="mb-3 pb-1">
             <b-form-input ref="text" placeholder="Kupon Girin"></b-form-input>
            <b-input-group-append>
              <b-button>
                <span>Uygula</span>
            <span class="color-green">Uygulandı!</span>
            Uygula butonuna tıkladındığı zaman yorum satırındaki gözükmelidir
            </b-button>
            </b-input-group-append>
          </b-input-group> -->
          <div class="mb-3">
            <a
              @click="goPay()"
              type="button"
              class="btn btn-primary btn-block btn-lg"
            >
              Ödemeye Geç
            </a>
          </div>
          <!-- gecersiz kupon durumunda gosterilmelidir -->
          <!-- <div class="alert alert-error mt-3 py-2 px-3" role="alert">
                    <div class="alert-content w-100 d-inline-block">
                            <div class="text-small my-1"><strong>Kupon geçersiz</strong></div>
                    </div>
            </div> -->
        </div>
      </div>

      <div class="pt-2 welcome-page" v-if="courses && courses.length > 0">
        <h3 class="mb-4 pr-5">Popüler Kurslar</h3>
        <VueSlickCarousel
          v-if="courses && courses.length > 0"
          v-bind="settings"
        >
          <CourseCard
            v-for="course in courses"
            :key="course.id"
            v-bind:course="course"
          >
            <!-- <div slot="CourseTime">
                <div class="mb-2 color-second text-small">
                  Toplam 16,5 saat
                  <div class="clearfix d-none d-md-block d-xl-none"></div>
                  <span
                    class="h4 m-0 color-second mx-2 d-md-none d-xl-inline-block"
                    >&middot;</span
                  >14 bölüm
                </div>
              </div> -->
          </CourseCard>
        </VueSlickCarousel>
      </div>
    </div>
    <SuccessModal></SuccessModal>
    <DangerModal></DangerModal>
    <ForgotPassword> </ForgotPassword>
  </div>
</template>

  <script>
import VueSlickCarousel from "vue-slick-carousel";
import { getOrDefine, remove, add } from "../core/basket";
import {getValue} from "../core/userData"
import {
  getOrDefineLB,
  addLB,
  trackBasketLB,
  removeLB,
} from "../core/laterBuy";
export default {
  name: "Sepet",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      key: 0,
      laterBasket: [],
      loadingFavorite: false,
      favorite: false,
      user: {},
      value: 65,
      courses: [],
      settings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      allBasketData: [],
      archivedCourses: [],
      allCoursePrice: 0,
      modalItem: "",
      courseSize: 0,
    };
  },

  mounted() {
    this.fetchCourses();
    //this.fetchArchivedCourses();
    this.basketData();
    this.$store.commit("SET_LOADER", false);
  },
  methods: {
    addLaterCourse(course) {
      addLB(course);
      remove(course.id);
      this.basketData();
      this.laterBasketFn();
    },
    moveBasket(item) {
      add(item);
      removeLB(item.id);
      this.basketData();
      this.laterBasketFn();
    },
    goPay() {
      let user = getValue();
      if(user == null){
        this.$bvModal.show("modal-login")
      }
      this.$store.commit("SET_LOADER", true);
      this.$router.push("/odeme");
    },
    setRating: function (rating) {
      this.rating = "You have Selected: " + rating + " stars";
    },
    showCurrentRating: function (rating) {
      this.currentRating =
        rating === 0
          ? this.currentSelectedRating
          : "Click to select " + rating + " stars";
    },
    setCurrentSelectedRating: function (rating) {
      this.currentSelectedRating = "You have Selected: " + rating + " stars";
    },
    basketData() {
      this.allBasketData = getOrDefine();
      this.allCoursePrice = this.allBasketData
        .map((order) => order.price)
        .reduce((total, val) => total + val, 0);
    },
    laterBasketFn() {
      this.laterBasket = getOrDefineLB();
    },
    findWithAttr(array, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i].course.id === value.course.id) {
          return i;
        }
      }
      return -1;
    },
    successModal() {
      this.$bvModal.show("SuccessModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("SuccessModal");
      }, modalTimeoutSeconds * 1000);
    },
    dangerModal() {
      this.$bvModal.show("DangerModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("DangerModal");
      }, modalTimeoutSeconds * 1000);
    },
    deleteCourse(id) {
      remove(id);
      this.successModal();
      this.basketData();
    },

    fetchCourses() {
      this.axios
        .get("/course/popular-courses")
        .then((response) => {
          this.courses = response.data.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },

    fetchArchivedCourses() {
      this.axios
        .get("/course/my-courses", {
          params: {
            archive: 1,
          },
        })
        .then((response) => {
          this.archivedCourses = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },

    addedArchive(id) {
      this.axios
        .post("/course/archive?course_id=" + id + "&archive=" + true)
        .then((response) => {
          console.log(response);
          this.fetchArchivedCourses();
          this.successModal();
        })
        .catch((error) => {
          console.log(error);
          this.dangerModal();
        });
    },
    otherPage(is_paid, slug) {
      if (is_paid == true) {
        this.$router.push("/egitim-video/" + slug);
      } else {
        this.$router.push("/kurs-detay/" + slug);
      }
    },
  },
  created() {
    this.fetchCourses();
    this.laterBasketFn();
    trackBasketLB(this.laterBasketFn);
    getOrDefine();
    let chechAuth = this.$route.params.auth;
    if(chechAuth){
      this.$bvModal.show("modal-login");
    }
  },
};
</script>


<template>
  <div class="main-content full-content-bg">
    <div class="container">
      <h2 class="gradient-color mb-4">
        {{ getValue(courseClone.title) }}
      </h2>
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-12 col-md-8 col-lg-9 mt-5 mt-md-0">
          <div class="mb-5 pb-5">
            <div class="mb-4" v-html="getValue(courseClone.description)"></div>
            <div class="row">
              <div class="col-md-6 col-lg-4 mb-2">
                <div class="card-star-rating d-flex align-items-center">
                  <b class="pt-2 mr-2 h4 mb-0 color-yellow">{{
                    courseClone.rating ? courseClone.rating.toFixed(1) : 0
                  }}</b>
                  <star-rating
                    :rating="courseRating ? courseRating : 0"
                    :round-start-rating="false"
                    :read-only="true"
                    :star-points="[
                      23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                      34, 46, 19, 31, 17,
                    ]"
                  ></star-rating>
                  <div class="pt-2 ml-2">
                    {{ getValue(courseClone.ratingCount, 0) }} Kişi
                  </div>
                </div>
                <div class="mt-2">
                  {{ getValue(courseClone.buyingCount, 0) }} kişi satın aldı.
                </div>
              </div>

              <div class="col-md-6 col-lg-4 mb-2 pt-2">
                <div class="mb-2">
                  Bölüm Sayısı:
                  {{
                    courseClone.sections
                      ? courseClone.sections.length
                      : getValue(courseClone.sectionCount, 0)
                  }}
                </div>
                <div>
                  Süre:
                  {{
                    courseClone.total_duration_object
                      ? Object.keys(courseClone.total_duration_object).length >
                        0
                        ? parseInt(courseClone.total_duration_object.hour) +
                          " saat " +
                          parseInt(courseClone.total_duration_object.minute) +
                          "dakika"
                        : "0 saat 0 dakika"
                      : ""
                  }}
                </div>
              </div>

              <div class="col-md-6 col-lg-4 mb-2 pt-2">
                <div class="mb-2"><a href="#" class="brand-color">AKTS</a></div>
                <div>
                  Eğitmen:
                  <a @click="Instructor()" class="brand-color">{{
                    instructor.user ? getValue(instructor.user.full_name) : ""
                  }}</a>
                </div>
              </div>
            </div>
            <div class="d-flex mt-4 pt-2">
              <div class="d-flex align-items-center">
                <!-- <button
              type="button"
              class="btn outline-primary d-flex align-items-center text-left mr-3">
              <i class="ri-star-fill mr-3 mr-sm-2 color-second align-bottom"></i>Favorilere Ekle</button> -->
                <favorite-button
                  v-if="user && user.access_token"
                  class="
                    btn
                    outline-primary
                    d-flex
                    align-items-center
                    text-left
                    mr-3
                  "
                  :course="courseClone"
                ></favorite-button>
              </div>

              <div class="d-flex align-items-center">
                <button
                  v-b-modal.ShareModal
                  @click="share()"
                  type="button"
                  class="btn btn-link"
                >
                  <i
                    class="ri-share-fill align-bottom top-minus-1 fa-lg mr-lg-2"
                  ></i
                  ><span class="ml-1">Paylaş</span>
                </button>
              </div>
            </div>
          </div>
          <div class="mb-5">
            <h3 class="mb-4">Kazanımlar</h3>
            <template
              v-if="learningOutcomes != null && learningOutcomes.length > 0"
            >
              <div
                v-for="(educationalAttainment, index) in learningOutcomes"
                :key="index"
                class="mb-1 d-flex"
              >
                <div class="pt-1">
                  <i
                    class="ri-check-fill ri-lg color-green mr-2 top-minus-1"
                  ></i>
                </div>
                <div>
                  {{ educationalAttainment.item || "" }}
                </div>
              </div>
            </template>
          </div>
          <div class="mb-5 pt-2">
            <h3 class="mb-3">Kurs İçeriği</h3>
            <sections
              class="col-lg-12 p-0"
              :transparent="true"
              :sections="courseClone.sections ? courseClone.sections : []"
            ></sections>
          </div>

          <div class="mb-5">
            <h3 class="mb-4">Eğitmen</h3>
            <div class="row">
              <div class="col-12 col-xl-4 mb-3 mb-xl-0">
                <a>
                  <div class="d-flex align-items-center">
                    <div
                      class="rounded-circle bg-image mr-4 wh-64"
                      @click="Instructor()"
                      :style="
                        instructor &&
                        instructor.user &&
                        instructor.user.picture_link
                          ? {
                              backgroundImage:
                                'url(' + instructor.user.picture_link + ')',
                            }
                          : {
                              backgroundImage:
                                'url(https://svgshare.com/i/bnS.svg)',
                            }
                      "
                    ></div>
                    <div class="educator-info" @click="Instructor()">
                      <div class="text-capitalize text-truncate h4 mb-1">
                        {{
                          instructor.user
                            ? getValue(instructor.user.full_name)
                            : ""
                        }}
                      </div>
                      <!-- <div
                        class="
                          color-second
                          text-capitalize text-truncate
                          font-weight-normal
                          h4
                          mb-0
                        "
                      >
                        {{
                          Object.keys(instructor).length > 0 &&
                          instructor.user &&
                          instructor.user.title
                            ? instructor.user.title
                            : "Bilgisayar Mühendisi"
                        }}
                      </div> -->
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-6 col-xl-4">
                <div class="mt-2 pt-1 top-minus-2">
                  <div class="mb-1">
                    <i class="ri-star-line mr-2 align-bottom top-minus-1"></i
                    >{{ instructor.rating ? instructor.rating.toFixed(1) : 0 }}
                    <span class="d-none d-sm-inline-block">Eğitmen Puanı</span>
                  </div>
                  <div>
                    <i class="ri-award-line mr-2 align-bottom top-minus-1"></i
                    >{{ getValue(instructor.commentCount, 0) }} Yorum
                  </div>
                </div>
              </div>
              <div class="col-6 col-xl-4">
                <div class="mt-2 pt-1 top-minus-2">
                  <div class="mb-1">
                    <i class="ri-user-2-line mr-2 align-bottom top-minus-1"></i
                    >{{ getValue(instructor.studentCount, 0) }} Öğrenci
                  </div>
                  <div>
                    <i class="ri-video-line mr-2 align-bottom top-minus-1"></i
                    >{{ getValue(instructor.courseCount, 0) }} Kurs
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-5 pb-1">
            <h3 class="mb-4">Geri Bildirim</h3>
            <div class="row">
              <div
                class="
                  col-12 col-sm-3 col-xl-2
                  text-left text-sm-center
                  d-flex
                  align-items-center
                  mb-3 mb-sm-0
                "
              >
                <div class="mx-0 mx-sm-auto">
                  <h1 class="grade-title mb-0 display-4 font-weight-bold">
                    {{ courseRating ? courseRating.toFixed(1) : 0 }}
                  </h1>
                  <star-rating
                    class="
                      star-small star-rating-text-none
                      d-flex
                      justify-content-center
                      mb-2
                    "
                    :round-start-rating="false"
                    :rating="courseRating ? courseRating : 0"
                    :show-rating="false"
                    :read-only="true"
                    :star-points="[
                      23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                      34, 46, 19, 31, 17,
                    ]"
                  ></star-rating>
                  <div>Kurs Puanı</div>
                </div>
              </div>
              <div class="col-12 col-sm-9 col-xl-10">
                <div
                  class="d-flex align-items-center mb-2"
                  v-for="({ value, rating }, i) in ratings"
                  :class="{ 'opacity-45': i > 0 }"
                  :key="i"
                  role="button"
                >
                  <div class="w-100 mr-4 top-minus-1">
                    <b-progress
                      :value="value ? value : 0"
                      class="bg-third"
                      variant="success"
                    ></b-progress>
                  </div>
                  <div class="w-100 d-flex align-items-center">
                    <star-rating
                      class="star-small star-rating-text-none mr-2 top-minus-2"
                      :rating="rating ? rating : 0"
                      :show-rating="false"
                      :read-only="true"
                      :star-points="[
                        23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                        34, 46, 19, 31, 17,
                      ]"
                    ></star-rating>
                    <span>%{{ value ? value.substring(0, 2) : 0 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
          <div class="sidebar">
            <div
              class="mb-4"
              v-b-modal.show
              @click="previewCourse()"
            >
              <template>
                <div class="embed-responsive embed-responsive-16by9 rounded">
                  <div
                    :style="{ backgroundImage: 'url(' + pictureLink + ')' }"
                    class="video small-preview video-js rounded embed-responsive-item"
                  ></div>
                </div>
              </template>
            </div>
            <!-- <div class="mb-2 pb-1">
              <span class="badge badge-brand">Efsane Cuma İndirimi</span>
            </div> -->
            <div class="mb-4">
              <!-- <div class="color-second text-small">
                %{{ courseInfo.discount }} İndirim
              </div>
              <div class="h2 color-second mb-0 mr-3 d-inline-block">
                <s>{{ courseInfo.beforePrice }}</s>
              </div> -->
              <div class="h2 mb-0 d-inline-block">{{ courseClone.price }}₺</div>
            </div>
            <div class="mb-3">
              <div class="mb-2 pb-1">
                <button
                  type="button"
                  class="btn btn-primary btn-block"
                  @click.stop="
                    checkPaid
                      ? goEgitimVideo()
                      : checkBasket
                      ? goBasket()
                      : addBasketItem()
                  "
                >
                  <b-spinner
                    label="Spinning"
                    class="top-minus-1 align-middle mr-3"
                    small
                    v-if="showSpinner"
                  ></b-spinner
                  >{{
                    showSpinner
                      ? ""
                      : checkPaid
                      ? "Kursa Git"
                      : checkBasket
                      ? "Sepete Git"
                      : "Sepete Ekle"
                  }}
                </button>
              </div>
              <button
                type="button"
                class="btn btn-secondary btn-block"
                @click="goPayment()"
              >
                Hemen Satın Al
              </button>
            </div>

            <!-- <h5 class="gray text-center my-2">30 Günde Para İade Garantisi</h5>
            <div class="text-left">
              <div class="p-1">
                <i></i>
                <span class="ps-1 ps-lg-1 ml-1" v-if="promise.check">{{
                  promise.text
                }}</span>
              </div>
            </div>
            <div class="text-smaller color-second text-center">
              30 Gün İçinde Para İade Garantisi
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <modal :content="content" v-model="show">
      <template v-if="this.errorMassage">
        {{ this.errorMassage }}
      </template>
    </modal>
    <GeneralModal :id="'show'" :size="size">
      <template v-slot:GeneralModalTitle>
        <div v-if="generalModalMode == 'report'">
          <h3>Kötüye Kullanım Bildir</h3>
        </div>
        <div v-else>
          <h3>Kurs Önizleme</h3>
        </div>
      </template>
      <template v-slot:GeneralModalContent>
        <div v-if="generalModalMode == 'report'">
          <div class="alert alert-success mb-4" role="alert">
            <div class="alert-content w-100 d-inline-block">
              <div class="my-1">
                <strong>Talebiniz başarıyla gönderildi</strong>
              </div>
            </div>
          </div>
          <b-form-group label="Sorun Türü">
            <template>
              <div>
                <multiselect
                  placeholder="Seçiniz"
                  v-model="selected"
                  :options="options1"
                  :select-label="''"
                  :selected-label="''"
                  deselect-label=""
                  :allow-empty="false"
                >
                  <span slot="noResult">Sonuç bulunamadı</span>
                </multiselect>
              </div>
            </template>
          </b-form-group>
          <b-form-group>
            <b-form-textarea
              class="overflow-auto"
              id="textarea"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
          <button type="button" class="btn d-flex btn-primary ml-auto">
            Gönder
          </button>
        </div>
        <div v-else>
          <div>
            <template>
              <div>
                <div
                  id="videoPlayer"
                  class="
                    video video-js
                    rounded
                    embed-responsive embed-responsive-16by9
                  "
                ></div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </GeneralModal>
  </div>
</template>

<script>
import Artplayer from "artplayer";
import StarRating from "vue-star-rating";
import { add, getOrDefine } from "../core/basket";
import { get, trackNightMode, unTrackNightMode } from "../core/nightMode";

import { getValue } from "../core/userData";
import { getDefaultValue } from "../helper/assignment";
import favoriteButton from "../components/ui/FavoriButton.vue";
import { removeLB } from "../core/laterBuy";
export default {
  components: {
    StarRating,
    favoriteButton,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
          controls: true,
          autoplay: true,
        };
      },
    },
  },
  async created() {
    this.$store.commit("SET_LOADER", false);
    this.start();
  },
  destroyed() {
    unTrackNightMode(this.getNightMode.bind(this));
  },
  mounted() {
    this.$root.$on("bv::modal::shown", (bvEvent, modalId) => {
      console.log("Modal is about to be shown", bvEvent, modalId);
      if (modalId != "show") return;
      this.player = new Artplayer({
        container: "#videoPlayer",
        autoplay: true,
        fullscreen: true,
        url: this.videoLink,
      });
      this.player.on("destroy", () => {
        this.player;
      });
      /*this.player.on("error", async () => {
        if (this.player.error().code == 4) {
          await this.fetchData();
          this.player.src(this.videoLink);
        }
        return;
      });
      this.player.src(this.videoLink);
      this.player.play();*/
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async start() {
      this.userData = getValue();
      this.getNightMode();
      this.nightModeFn();
      await this.fetchData();
      this.orderRating();
      this.goDefaultPage();
      this.filterCourseByBasket();
    },
    orderRating() {
      if (this.courseClone.ratingList == null) return;
      let stars = [1, 2, 3, 4, 5];
      let collect = this.courseClone.ratingList;
      let newArr = stars
        .filter((star) => !collect.some((x) => x.rating == star))
        .map((x) => {
          return { value: x.percentage || 0, rating: x };
        });

      this.ratings = this.courseClone.ratingList
        .map((rating) => {
          return { value: rating.percentage, rating: rating.rating };
        })
        .concat(newArr)
        .sort(function (a, b) {
          return b.rating - a.rating;
        });
    },
    nightModeFn() {
      trackNightMode(this.getNightMode.bind(this));
    },
    getNightMode() {
      this.nightMode = get();
    },
    async fetchData() {
      try {
        this.loadingPage = true;
        let res = await this.axios.get(
          "/course/preview/" + this.$route.params.slug
        );
        if (res.data.message) {
          this.errorMassage = res.data.message;
          this.content = res.data;
          this.statusPage = res.data.status;
          this.show = true;
          return;
        }
        let data = res.data.data;
        this.course_id = data.id;
        this.courseRating = data.rating;
        console.log(this.courseRating);
        this.favorite = data.favorite;
        this.courseClone = data;
        this.loadingPage = false;
        this.instructor = data.instructor;
        this.pictureLink = data.picture_link;
        this.videoLink = data.video_link;
        this.learningOutcomes = data.learningOutcomes;
        this.checkPaid = data.is_paid || false;
        this.courseSlug = data.slug;
      } catch (err) {
        console.log(err);
      }
    },
    Instructor() {
      this.$router.push({
        name: "Profil",
        params: {
          id: this.instructor.user_id,
        },
      });
    },
    addBasketItem() {
      this.showSpinner = true;
      if (Object.keys(this.courseClone).length !== 0) {
        add(this.courseClone);
        this.checkBasket = true;
        this.showSpinner = false;
      }
      if (!this.courseClone.id) return;
      removeLB(this.courseClone.id);
      this.showSpinner = false;
    },
    goBasket() {
      if (Object.keys(this.courseClone).length !== 0) {
        this.$router.push({ name: "Sepet" });
      }
    },
    filterCourseByBasket() {
      this.checkBasket = getOrDefine().some(
        (course) => course.id === this.course_id
      );
    },
    share() {
      this.courseLink =
        "https://cdnbaugo.ubit.com.tr/kurs-detay/" + this.$route.params.slug;
      this.$store.commit("SET_SHARE", this.courseLink);
    },
    goPayment() {
      if (Object.keys(this.courseClone).length !== 0) {
        if (this.userData && this.userData.access_token) {
          add(this.courseClone);
          this.$router.push({ name: "Odeme" });
          return;
        }
        add(this.courseClone);
        this.$router.push({ name: "Sepet", params: { auth: false } });
      }
    },
    goDefaultPage() {
      if (this.statusPage === "error" ? true : false) {
        this.$router.push({ name: "Anasayfa" });
      }
    },
    previewCourse() {
      this.showGeneral = true;
      this.generalModalMode = "previewCourse";
      this.size = "lg";
    },
    goEgitimVideo() {
      this.$router.push({
        name: "egitim-video",
        params: { slug: this.courseSlug || "" },
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.slug !== from.params.slug) {
        (async () => await this.start())();
      } else {
        unTrackNightMode(this.getNightMode.bind(this));
      }
    },
  },
  data() {
    return {
      getValue: getDefaultValue,
      generalModalMode: null,
      size: "md",
      k: 0,
      videoRefresh: 0,
      showSpinner: false,
      userData: {},
      nightMode: false,
      statusPage: "",
      errorMassage: "",
      content: {},
      checkBasket: false,
      show: false,
      courseLink: null,
      loadingFavorite: false,
      loadingPage: true,
      course_id: "",
      instructor: {},
      count: 5,
      favorite: false,
      player: null,
      player2: null,
      courseRating: 0,
      ratings: [],
      courseClone: {},
      pictureLink: "",
      videoLink: "",
      learningOutcomes: [],
      checkPaid: false,
      courseSlug: "",
      showGeneral: false,
    };
  },
};
</script>

<template>
  <div>
    <div
      v-if="!loader"
      :key="key"
      @click="operationFavoriteBtn()"
      type="button"
    >
      <i
        class="ri-star-fill mr-3 align-bottom"
        :class="{
          'color-yellow': favorite ? true : false,
          'color-second': favorite ? false : true,
        }"
      ></i>
      {{ favorite ? "Favoriden Çıkar" : "Favorilere Ekle" }}
    </div>
    <b-spinner
      label="Spinning"
      class="align-middle top-minus-1 mr-3"
      small
      v-if="loader"
    ></b-spinner>
  </div>
</template>

<script>
import userOrAnonymousFav from "../../mixin/userOrAnonymousFav";
export default {
  mixins: [userOrAnonymousFav],
  props: {
    course: { default: {} },
  },
  computed:{
      favorite:{
          get(){return this.$store.getters.checkId(this.course.id)},
          set(val){
              return val
          }
      } 
  },
};
</script>
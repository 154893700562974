<template>
  <div class="home">
    <div class="home-slider">
      <template>
        <VueSlickCarousel v-bind="settings" ref="carousel" :arrows="false">
          <div class="slider-wrapper">
            <div
              v-for="banner in banners"
              :key="banner.id"
              class="slide bg-image"
              v-bind:style="{
                backgroundImage: 'url(' + banner.image_link + ')',
              }"
            >
              <div class="container">
                <div class="slide-content">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <h1 class="mb-2 pb-1">{{ banner.title }}</h1>
                      <p>
                        {{ banner.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="slider-wrapper">
            <div
              class="slide bg-image"
             v-bind:style="{'background-image': 'url(' + require('../assets/img/slide-2.jpg') + ')'}">
              <div class="container">
                <div class="slide-content">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <h1 class="mb-2 pb-1">
                        Kurslar şimdi yalnızca ₺27,99'den başlayan fiyatlarla!
                      </h1>
                      <p>
                        Sezonun en büyük indirimi 27 Mayıs'ta sona eriyor. Sizi
                        bir adım öne geçirecek yetkinlikler edinin.
                      </p>
                      <div class="mt-4">
                        <button class="btn btn-lg btn-primary">Satın Al</button>
                        <button class="btn btn-lg btn-secondary ml-3">
                          İncele
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="slider-wrapper">
            <div
              class="slide bg-image"
            v-bind:style="{'background-image': 'url(' + require('../assets/img/slide-1.jpg') + ')'}">
              <div class="container">
                <div class="slide-content">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <h1 class="mb-2 pb-1">
                        Kurslar şimdi yalnızca ₺27,99'den başlayan fiyatlarla!
                      </h1>
                      <p>
                        Sezonun en büyük indirimi 27 Mayıs'ta sona eriyor. Sizi
                        bir adım öne geçirecek yetkinlikler edinin.
                      </p>
                      <div class="mt-4">
                        <button class="btn btn-lg btn-primary">Satın Al</button>
                        <button class="btn btn-lg btn-secondary ml-3">
                          İncele
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </VueSlickCarousel>
      </template>
    </div>
    <section class="py-5">
      <div class="container py-4">
        <h2 class="mb-4 pr-5">Kategoriler</h2>
        <VueSlickCarousel
          :class="slideClass"
          v-bind="CourseCoruselSettings"
          v-if="categories && categories.length > 0"
        >
          <div class="mb-4" v-for="category in categories" :key="category.id">
            <article class="card position-relative pic-card">
              <router-link
                :to="{
                  name: 'TumKurslar',
                  params: { query: category.category, id: category.id },
                }"
                class="h-100"
              >
                <div
                  class="card-img-top h-100 bg-image"
                  :style="{
                    backgroundImage: 'url(' + category.image_link + ')',
                  }"
                >
                  <div
                    class="
                      font-weight-bold
                      position-absolute
                      left-0
                      bottom-0
                      p-4
                      pt-5
                      pic-card-title
                    "
                  >
                    <div class="pt-5">{{ category.category }}</div>
                  </div>
                </div>
              </router-link>
            </article>
          </div>
        </VueSlickCarousel>
        <div class="see-more position-relative d-flex justify-content-end">
          <router-link class="color-second" to="/tum-kategoriler"
            >Tümünü Gör</router-link
          >
        </div>
      </div>
    </section>
    <section class="py-5 section-striped">
      <div class="container py-4">
        <h2 class="mb-4 pr-5">Popüler Kurslar</h2>
        <VueSlickCarousel
          v-if="popularCourses && popularCourses.length > 0"
          v-bind="CoruselSettings"
          :class="slideClass"
        >
          <CourseCard
            v-for="course in popularCourses"
            :key="course.id"
            v-bind:course="course"
          >
          </CourseCard>
        </VueSlickCarousel>
        <div class="see-more position-relative d-flex justify-content-end">
          <a class="color-second" @click="goAllCourses('last_record')"
            >Tümünü Gör</a
          >
        </div>
      </div>
    </section>
    <section class="py-5">
      <div class="container py-4">
        <h2 class="mb-4 pr-5">Son Eklenen Kurslar</h2>
        <VueSlickCarousel
          v-if="lastCourses && lastCourses.length > 0"
          v-bind="CoruselSettings"
          :class="slideClass"
        >
          <CourseCard
            v-for="course in lastCourses"
            :key="course.id"
            v-bind:course="course"
          >
          </CourseCard>
        </VueSlickCarousel>
        <div class="see-more position-relative d-flex justify-content-end">
          <a class="color-second" @click="goAllCourses('last_record')"
            >Tümünü Gör</a
          >
        </div>
      </div>
    </section>
    <section class="py-5 section-striped">
      <div class="container py-4">
        <h2 class="mb-4 pr-5">Kullanıcılar Ne Diyor?</h2>
        <VueSlickCarousel
          v-bind="CoruselSettings2"
          v-if="feedbacks && feedbacks.length > 0"
        >
          <div class="mb-4" v-for="feedback in feedbacks" :key="feedback.id">
            <div class="card side-card">
              <div class="row m-0 h-100 h-xs-inherit">
                <div class="col-12 col-sm-4 col-lg-5 px-0 h-100 h-xs-inherit">
                  <div
                    class="bg-image w-100 h-100 h-xs-inherit"
                    v-bind:style="{
                      backgroundImage: 'url(' + feedback.image_link + ')',
                    }"
                  ></div>
                </div>
                <div class="col-12 col-sm-8 col-lg-7 px-1 h-100 h-xs-inherit">
                  <div class="card-body d-flex flex-column h-100 h-xs-inherit">
                    <div class="card-text mb-4">
                      {{ feedback.comment }}
                    </div>
                    <div class="text-small color-second mb-0 mt-auto">
                      {{ feedback.first_name }} {{ feedback.last_name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </section>
    <section class="py-5">
      <div class="container py-4">
        <div class="row">
          <div class="col-12 col-lg-4 mb-2 mb-lg-0">
            <div class="p-4 bg-second w-100 h-100 rounded">
              <div class="mb-2 color-second">Kurs Sayısı</div>
              <div class="h3 mb-0">{{ courseSize }}</div>
            </div>
          </div>
          <div class="col-12 col-lg-4 mb-2 mb-lg-0">
            <div class="p-4 bg-second w-100 h-100 rounded">
              <div class="mb-2 color-second">Ders Sayısı</div>
              <div class="h3 mb-0">{{ lessonSize }}</div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="p-4 bg-second w-100 h-100 rounded">
              <div class="mb-2 color-second">Toplam Ders Saati</div>
              <div class="h3 mb-0">{{ videoSize }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5 section-striped">
      <div class="container py-4">
        <h2 class="mb-4 pr-5">Sıkça Sorulan Sorular</h2>

        <template>
          <div class="accordion custom-accordion" role="tablist">
            <div
              v-for="frequentlyAskedQuestion in frequentlyAskedQuestions"
              :key="frequentlyAskedQuestion.id"
            >
              <h4 class="mb-3 mt-4 pt-2 color-second accordion-title">
                {{ frequentlyAskedQuestion.title }}
              </h4>
              <b-card
                class="shadow-none mb-2 rounded"
                no-body
                v-for="item in frequentlyAskedQuestion.items"
                :key="item.id"
              >
                <div class="card-header border-0 p-2" role="tab">
                  <button
                    class="btn background-transparent btn-block"
                    v-b-toggle="'accordion-' + item.id"
                  >
                    {{ item.question }}
                  </button>
                </div>
                <b-collapse
                  :id="'accordion-' + item.id"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body class="pt-0 pr-4 pb-4 pl-4">
                    <div>
                      {{ item.answer }}
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </template>
      </div>
    </section>
    <!-- <section class="py-5">
      <div class="container py-4">
        <h2 class="mb-4 pr-5">Sıkça Sorulan Sorular</h2>
        <template>
          <div class="accordion custom-accordion" role="tablist">
            <b-card
              class="shadow-none mb-2 rounded"
              no-body
              v-for="frequentlyAskedQuestion in frequentlyAskedQuestions"
              :key="frequentlyAskedQuestion.id"
            >
              <div class="card-header border-0 p-2" role="tab">
                <button
                  class="btn background-transparent btn-block"
                  v-b-toggle="'accordion-' + frequentlyAskedQuestion.id"
                >
                  {{ frequentlyAskedQuestion.title }}
                </button>
              </div>
              <b-collapse
                :id="'accordion-' + frequentlyAskedQuestion.id"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body class="pt-0 pr-4 pb-4 pl-4">
                  <div
                    v-for="item in frequentlyAskedQuestion.items"
                    :key="item.id"
                  >
                    Soru: {{ item.question }}- Cevap: {{ item.answer }}
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </template>
      </div>
    </section> -->
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "Anasayfa",
  template: {},
  components: {
    VueSlickCarousel,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
          controls: true,
          responsive: true,
        };
      },
    },
  },
  data() {
    return {
      lastCourses: [],
      favorite: false,
      popularCourses: [],
      value: 65,
      banners: [],
      courses: [],
      feedbacks: [],
      courseSize: 0,
      lessonSize: 0,
      videoSize: 0,
      frequentlyAskedQuestions: [],
      slideIndex: 0,
      categories: [],
      slideClass: "pagination-left",
      CardImage1: "https://source.unsplash.com/260x330/?technology",
      CardImage2: "https://source.unsplash.com/260x330/?desktop",
      CardImage3: "https://source.unsplash.com/260x330/?business",
      CardImage4: "https://source.unsplash.com/260x330/?book",
      CardImage5: "https://source.unsplash.com/260x330/?education",
      CardImage6: "https://source.unsplash.com/260x330/?design",
      CardImage7: "https://source.unsplash.com/260x330/?building",
      CardImage8: "https://source.unsplash.com/260x330/?keyboard",
      settings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        accessibility: false,
        fade: true,
        autoplay: false,
      },
      CoruselSettings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
      CoruselSettings2: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
        ],
      },
      CourseCoruselSettings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    fetchMainPage() {
      this.axios
        .get("/site/mainpage")
        .then((response) => {
          const { data } = response;
          this.banners = data.data.banners;
          this.courses = data.data.courseFeatured;
          this.feedbacks = data.data.feedbacks;
          this.courseSize = data.data.courseCount;
          this.lessonSize = data.data.courseResourcesCount;
          this.videoSize = data.data.videoSumDuration;
          this.frequentlyAskedQuestions = data.data.frequentlyAskedQuestion;
          this.categories = data.data.categoryList;
          this.popularCourses = data.data.courseFeatured;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchLastCourses() {
      this.axios
        .get("/course/all-courses?perPage=8", {
          params: {
            params: {
              order: "last_record",
            },
          },
        })
        .then((response) => {
          this.lastCourses = response.data.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    goAllCourses(param) {
      this.$router.push({ name: "TumKurslar", params: { last_record: param } });
    },
    goCourseDetail(slug) {
      this.$router.push({ name: "KursDetay", params: { slug: slug } });
    },
  },

  mounted() {
    this.fetchMainPage();
    this.fetchLastCourses();
  },
};
</script>

<template>
  <div>
    <b-modal
      id="Modal"
      centered
      v-model="show"
      :hide-footer="true"
      :hide-header="true"
    >
      <div class="d-flex align-items-center">
        <div
          type="button"
          aria-label="Close"
          class="close top-plus-2"
          @click="goBackBtn()"
        >
          <i class="ri-close-line color-third fa-2x"></i>
        </div>
        <div
          class="text-success ms-3"
          :class="{
            'text-succes': content.status === 'success',
            'text-danger': content.status !== 'success',
          }"
        >
        <slot>
          {{ content.message != null ? content.message : "İşleminiz başarılı"}}
        </slot>  
        </div>
      </div></b-modal
    >
  </div>
</template>


<script>
export default {
  name: "modal",
  props: {
    value: {
      type: Boolean,
      defaultValue: false,
    },
    content: {
      status: false,
      message: {password:""},
    },
    goBack:{
      type: Function,
      default:()=>{return}
    }
  },
  methods:{
    goBackBtn(){
        this.show = false;
        this.goBack();
    }
  },
  computed: {
      show: {
        get: function () {
          return this.value;
        },
        set:function(val){
          this.$emit("input", val);
        }
      },
    },
};
</script>
import {EventEmitter} from "events"

let emitter = new EventEmitter();
let key = "laterBasket";
export function getOrDefineLB() {
    let items = localStorage.getItem(key);
    if (items == null) {
        items = [];
        localUpdate(items);
        return
    }
    return JSON.parse(items);
}
export function addLB(item) {
    let items = getOrDefineLB();
    if (!existData(item.id)) {
        items.push(item);
        localUpdate(items);
        emitter.emit("laterBasket");
        
        return;
    }

}
export function removeLB(id) {
    let items = getOrDefineLB()
    let indexOf = items.findIndex(course => course.id === id);
    if (indexOf < 0) {
        return
    }
    items.splice(indexOf, 1);
    localUpdate(items);
    emitter.emit("laterBasket");
}

export function existData(id) {
    let items = getOrDefineLB();
    let check = items.findIndex(x => x.id === id);
    if (check >= 0) {
        return true
    }
    else {
        return false
    }
}
export function cleaningBasketLB(){
    localStorage.removeItem(key)
}
function localUpdate(items) {
    localStorage.setItem(key, JSON.stringify(items))
}

export function trackBasketLB(fn){
    emitter.addListener("laterBasket",fn)
}
export function unTrackBasketLB(fn){
    emitter.removeListener("laterasket",fn)
}
<template>
  <div v-if="discussions!=null&&discussions.length>0">
    <div
      v-for="(discussion, index) in discussions"
      :key="discussion.id"
      class="comment-wrapper"
    >
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <a class="d-flex align-items-center">
            <div
              class="wh-48 rounded-circle bg-image mr-3"
              :style="
                discussion && discussion.user
                  ? {
                      backgroundImage:
                        'url(' + discussion.user.picture_link + ')',
                    }
                  : {
                      backgroundImage:
                        'url(http://ui.ubit.com.tr/profile-picture.jpg)',
                    }
              "
            ></div>
            <div class="educator-info">
              <div class="text-capitalize text-truncate">
                <template v-if="discussion && discussion.user">
                  {{ discussion.user.full_name }}
                  <span class="color-second mx-2">&middot;</span>
                  <span class="brand-color text-smaller text-uppercase">
                    Eğitmen
                  </span>
                </template>
                <template v-else> BAUGO User </template>
              </div>
              <div
                class="
                  color-second
                  text-capitalize text-truncate
                  font-weight-normal
                  mb-0
                "
              >
                {{ discussion.time_diff || "" }}
              </div>
            </div>
          </a>
          <b-dropdown
            id="dropdown-right"
            right
            text="Right align"
            variant="link"
            class="dropdown ml-auto after-none"
          >
            <template #button-content class="py-0">
              <i class="ri-more-line ml-auto color-third"></i>
            </template>
            <template
              v-if="
                discussion && discussion.user
                  ? user.id == discussion.user.id
                  : false
              "
            >
              <b-dropdown-item
                v-b-modal.DeleteModal
                @click="deleteComment(discussion.id)"
              >
                <i
                  class="ri-delete-bin-7-fill mr-3 align-bottom top-minus-1"
                ></i>
                Sil
              </b-dropdown-item>
            </template>
            <b-dropdown-item
              v-b-modal.GeneralModal
              @click="reportModal(discussion.id)"
            >
              <i class="ri-flag-2-fill mr-3 align-bottom top-minus-1"></i>
              Kötüye kullanım bildir
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="announcement">
        <div class="mb-3">
          {{ discussion.content }}
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex color-third">
            <div class="d-flex align-items-center mr-3">
              <a role="button" @click="like(discussion.id)">
                <i
                  :class="
                    likedList.includes(discussion.id)
                      ? 'color-red'
                      : 'color-third'
                  "
                  class="ri-heart-fill mr-2"
                ></i>
              </a>
              3
            </div>
            <div class="d-flex align-items-center">
              <i class="ri-reply-fill color-third mr-2"></i>
              {{ discussion.sub_discussion.length || "" }}
            </div>
          </div>
          <div class="d-flex">
            <a
              class="d-flex align-items-center togglebutton"
              @click="selectItem(index)"
              :class="[index === activeItem ? 'color-first' : 'color-third']"
            >
              <i
                class="ri-pencil-fill mr-2"
                :class="[index === activeItem ? 'color-first' : 'color-third']"
              ></i>
              Yanıtla
            </a>
          </div>
        </div>
        <vue-slide-up-down :active="index === activeItem">
          <div class="element">
            <fieldset class="pt-1">
              <div>
                <b-form-textarea
                  class="overflow-auto mt-2 mb-3"
                  id="textarea"
                  rows="3"
                  max-rows="6"
                  v-model="message"
                  @keyup="remainCharCount(message, 1)"
                  maxlength="500"
                  style="resize: none; overflow-y: scroll; height: 74px"
                ></b-form-textarea>
              </div>
            </fieldset>
          </div>
          <div>
            <div class="d-flex">
              <p class="d-flex align-items-top mb-0 color-third text-small">
                {{ remaincharactersText || "" }}
              </p>
              <button
                type="button"
                class="btn btn-sm btn-primary d-flex align-items-center ml-auto"
                @click="saveComment(discussion.id, message)"
              >
                Gönder
              </button>
            </div>
          </div>
        </vue-slide-up-down>

        <div class="row mt-4">
          <div class="col-12 col-md-11 offset-md-1">
            <!-- comment -->
            <div
              v-for="(comment, commentIndex) in discussion.sub_discussion"
              :key="comment.id"
              :class="
                commentIndex < commentsToShow[index]
                  ? 'comment-wrapper pb-5'
                  : ''
              "
            >
              <template v-if="commentIndex < commentsToShow[index]">
                <div class="mb-3">
                  <div class="d-flex align-items-center">
                    <a class="d-flex align-items-center">
                      <div
                        class="wh-48 rounded-circle bg-image mr-3"
                        :style="
                          comment && comment.user
                            ? {
                                backgroundImage:
                                  'url(' + comment.user.picture_link + ')',
                              }
                            : {
                                backgroundImage:
                                  'url(http://ui.ubit.com.tr/profile-picture.jpg)',
                              }
                        "
                      ></div>
                      <div class="educator-info">
                        <div class="text-capitalize text-truncate">
                          <template v-if="comment && comment.user">
                            {{ comment.user.full_name || "" }}
                          </template>
                          <template v-else> BAUGO User </template>
                        </div>
                        <div
                          class="
                            color-second
                            text-capitalize text-truncate
                            font-weight-normal
                            mb-0
                          "
                        >
                          {{ comment.time_diff || "" }}
                        </div>
                      </div>
                    </a>
                    <b-dropdown
                      id="dropdown-right"
                      right
                      text="Right align"
                      variant="link"
                      class="dropdown ml-auto after-none"
                    >
                      <template #button-content class="py-0">
                        <i class="ri-more-line ml-auto color-third"></i>
                      </template>
                      <template
                        v-if="comment.user ? user.id == comment.user.id : false"
                      >
                        <b-dropdown-item
                          v-b-modal.DeleteModal
                          @click="deleteComment(comment.id)"
                        >
                          <i
                            class="
                              ri-delete-bin-7-fill
                              mr-3
                              align-bottom
                              top-minus-1
                            "
                          ></i>
                          Sil
                        </b-dropdown-item>
                      </template>
                      <b-dropdown-item
                        v-b-modal.GeneralModal
                        @click="reportModal(comment.id)"
                      >
                        <i
                          class="ri-flag-2-fill mr-3 align-bottom top-minus-1"
                        ></i>
                        Kötüye kullanım bildir
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div class="announcement">
                  <div class="mb-3">
                    {{ comment.content || "" }}
                  </div>

                  <div class="d-flex justify-content-between">
                    <div class="d-flex color-third">
                      <div class="d-flex align-items-center mr-3">
                        <a role="button" @click="like(comment.id)">
                          <i
                            :class="
                              likedList.includes(comment.id)
                                ? 'color-red'
                                : 'color-third'
                            "
                            class="ri-heart-fill mr-2"
                          ></i>
                        </a>
                        3
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div
              v-if="discussion.sub_discussion.length > commentsToShow[index]"
              class="show-replys text-center color-third pb-5"
            >
              <router-link
                to=""
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  text-smaller
                "
                @click="showMoreComment(index)"
              >
                Daha fazla yanıt göster
                <i class="ri-arrow-down-line ml-2"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUpDown from "vue-slide-up-down";
import { getValue } from "../../core/userData";
export default {
  components: { "vue-slide-up-down": SlideUpDown },
  data() {
    return {
      likedList: [],
      user: {},
      maxcharacter: 500,
    };
  },
  props: {
    discussions: { default: [] },
    modalMode: { default: "" },
    id: { default: "" },
    commentaryId: { default: "" },
    selectedValue: { default: "" },
    active: { default: "" },
    messageValue: { default: "" },
    remainCharCount:{type:Function},
    remaincharacters: { default: "" },
    commentsToShow: {
      default: function () {
        return [];
      },
      type: Array,
    },
    saveComment:{
      type:Function
    }
  },
  created() {
    const userData = getValue();
    if (userData && userData.user) {
      this.user = userData.user;
    }
  },
  methods: {
    deleteComment(id) {
      console.log("Sileceğiiiööö", id);
      this.commentId = id;
    },
    reportModal(id) {
      this.discussionId = id;
      this.selected = null;
      this.generalModalMode = "progressModal";
    },
    like(id) {
      if (this.likedList.includes(id)) {
        this.likedList.splice(this.likedList.indexOf(id), 1);
      } else {
        this.likedList.push(id);
      }
    },
    showMoreComment(index) {
      this.commentsToShow[index] = this.commentsToShow[index] + 2;
      this.commentsToShow.push();
    },
    selectItem(index) {
      if (this.activeItem == index) {
        this.activeItem = null;
      } else {
        this.activeItem = index;
      }
      this.message = "";
      this.remaincharactersText = "500";
    },
  },
  computed: {
    generalModalMode: {
      get() {
        return this.modalMode;
      },
      set(val) {
        this.$emit("update:modalMode", val);
      },
    },
    discussionId: {
      get() {
        return this.id;
      },
      set(val) {
        this.$emit("update:discussionId", val);
      },
    },
    commentId: {
      get() {
        return this.commentaryId;
      },
      set(val) {
        this.$emit("update:commentaryId", val);
      },
    },
    selected: {
      get() {
        return this.selectedValue;
      },
      set(val) {
        this.$emit("update:selectedValue", val);
      },
    },
    message: {
      get() {
        return this.messageValue;
      },
      set(val) {
        this.$emit("update:messageValue", val);
      },
    },
    activeItem: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit("update:active", val);
      },
    },
    remaincharactersText: {
      get() {
        return this.remaincharacters;
      },
      set(val) {
        this.$emit("update:remaincharacters", val);
      },
    },
  },
};
</script>

let key = "lastTime";
export function getLastTime() {
    let lastTime = localStorage.getItem(key);
    if (lastTime == null) {
        let arr = []
        updateTime(arr)
        return
    }
    return JSON.parse(lastTime)
}
export function getterLastTime(id) {
    return configLastTime(id);
}
export function addLastTime(obj) {
    let times = getLastTime();
    let check = times.find(x => x.id == obj.id);
    if (check != null) {
        console.log("Zaten Var")
        updateObj(times,obj.id,obj)
        return;
    }
    let newArr = [...times, obj];
    updateTime(newArr);
}
function updateObj(times, id,obj) {
    let arr = [...times];
    let index = times.findIndex(x => x.id == id)
    if (index < 0) return
    arr.splice(index,1);
    let newArr = [...arr,obj]
    updateTime(newArr)

}
export function updateTime(times) {
    localStorage.setItem(key, JSON.stringify(times))
}
function configLastTime(id) {
    let times = getLastTime();
    let result = times.find(x => x.id == id)
    if (result == null) return
    let valueArr = result.time.split(":");
    return (parseInt(valueArr[2]) + parseInt(valueArr[1] * 60) + parseInt(valueArr[0] * 1200))
}

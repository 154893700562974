export const Mutations = {
  SET_USER_DATA: (state, user) => {
    state.user = user;
  },
  SET_LOADER: (state, value) => {
    state.loaderShow = value
  },
  SET_SEARCH_INPUT: (state, newValue) => {
    state.searchQuery = newValue;
  },
  SET_SHARE: (state, newValue) => {
    state.courseLink = newValue;
  },
  SET_KEY: (state, newValue) => {
    state.keyValue = newValue;
  },
  ADD_SETTİNGS: (state, newValue) => {
    state.settings = newValue;
  },
  ADD_FAVORİTE: (state, id) => {
    let data = state.coursesId
    let check = data.findIndex(x => x == id);
    if(check < 0)
    state.coursesId = [...state.coursesId,id]
  },
  DELETE_FAVORİTE: (state, id) => {
    let data = state.coursesId;
    let index = data.findIndex((x)=> x == id);
    if(index < 0) return;
    data.splice(index,1);
    state.coursesId = [...data]
  },
};

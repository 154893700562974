export const DefaultState = () => {
  return {
    user: null,
    loaderShow:true,
    searchQuery:"",
    contentStatus:"",
    courseLink:null,
    keyValue:0,
    settings:[],
    coursesId:[]
  };
};

import axios from "axios";

export const Actions = {
  setUserData: ({ commit }, data) => commit('SET_USER_DATA', data),
  addFavorite({ dispatch }, courseID) {
 
    axios.post(
      "/course/favorite?course_id=" + courseID + "&favorite=" + true
    ).then(() => dispatch("statusFavorite", courseID));
  },
  deleteFavorite({ dispatch }, courseID) {
    axios.delete("/course/favorite", {
      params: { course_id: courseID },
    }).then(() => dispatch("statusFavorite", courseID));
  },
  statusFavorite({ state }, courseID) {
    axios.get("/course/" + courseID + "/favorite").then(res => state.contentStatus = res.data.data);
  },
  fetchFavoritedCourses({ state }) {
    axios
      .get("/course/my-favorites")
      .then((response) => {
        this.favoritedCourses = response.data.data;
        let values = Object.values(this.favoritedCourses);
        state.coursesId = values.map(x => x.id);
      })
      .catch((error) => {
        console.log(error.response);
      });
  },
};

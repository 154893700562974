<template>
  <div class="row">
    <div class="col-12 col-sm-6 col-lg-12 col-xl-7">
      <div class="bg-second rounded mb-3 pt-3 pb-4 px-3">
        <div class="px-1">
          <div
            class="d-flex justify-content-between align-items-center mb-3 mt-2"
          >
            <b class="mb-0"> %{{ progress ? progress.percentage : 0 }} </b>
            <a
              class="color-second"
              @click="detailBtn()"
            >
              Detaylar
            </a>
          </div>
          <b-progress
            :value="progress ? progress.percentage : 0"
            class="bg-third"
            variant="success"
          ></b-progress>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-12 col-xl-5 pl-sm-0 pl-lg-3 pl-xl-0">
      <div
        class="
          grade-wrapper
          bg-second
          rounded
          position-relative
          mb-3
          pt-2
          pb-3
          px-3
        "
      >
        <div class="px-1 pt-1">
          <h3 class="grade-title text-right mt-2 mb-0">
            {{rating}}<span class="slash">/</span>5
          </h3>
          <!-- :increment="0.5" -->
          <star-rating
            v-model="rating"
            @rating-selected="setRating"
            :star-points="[
              23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46,
              19, 31, 17,
            ]"
          ></star-rating>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
export default {
    components: {
    StarRating,
  },
    props:{
        point:{default:""},
        setRating:{type:Function},
        detailBtn:{type:Function},
        progress:{default:""}
    },
    computed:{
        rating:{
            get(){
                return this.point
            },
            set(val){
              console.log(val)
                this.$emit("update:point", val);
            }
        }
    }
}
</script>
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap";
import './assets/css/style.scss'
import './assets/css/darkmode.scss'
import '../node_modules/remixicon/fonts/remixicon.css'
//import jquery from 'jquery'
import VCalendar from 'v-calendar'
import Multiselect from 'vue-multiselect'
import VueTheMask from 'vue-the-mask'
import VueSplide from '@splidejs/vue-splide'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import Components from "../src/components/ui/install"
import {getValue} from "../src/core/userData"
import NProgress from 'vue-nprogress'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const nprogress = new NProgress()
const options = {
  latencyThreshold: 200,
  router: true,
};

Vue.use(NProgress, options)

Vue.use(VueSplide)
Vue.use(VueTheMask)
Vue.use(VueAxios, axios)
Vue.use(VCalendar, { componentPrefix: 'v',})
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(router)
Vue.use(Notifications)
Vue.use(Components)
Vue.use(VueReCaptcha, { siteKey: '6Lf22-MZAAAAAHZy0-2nAVLUANg7ZE_3_qRLOq23' })

Vue.component('multiselect', Multiselect)
//Vue.use(jquery)
window.$ = window.jQuery = require('jquery')
Vue.config.productionTip = false

Vue.prototype.$googleClientID = '477330156566-j4h4opoq2jj5qjmljossl76n4ku24gin.apps.googleusercontent.com'
Vue.prototype.$facebookClientID = '1738172203036445'


axios.defaults.baseURL = 'https://baugo.ubit.com.tr/api'

const userData = getValue()
if (userData && userData.access_token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + userData.access_token
}


new Vue({
  router,
  store,
  nprogress,
  render: h => h(App)
}).$mount('#app');

import { addFav, removeFav, existData } from "../core/anonymousFavorite";
import { getValue } from "../core/userData";
export default {
  props: {
    course: {},
  },
  data() {
    return {
      loader: false,
      userToken: null,
      key:0,
    };
  },
  async created() {
    await this.start()
  },
  methods: {
    async start() {
      let user = getValue();
      if (user == null) {
        this.favorite = existData(this.courseData.id);
      }
      if (user != null) {
        let checkUser = Object.values(user).length;
        if (checkUser == 0) return
        this.userToken = user.access_token
        if (this.userToken != null) {
          this.favorite = this.$store.getters.checkId(this.course.id);
        }
      }

    },
    async operationFavoriteBtn() {
      this.loader = true;
      if (this.userToken == null) {
        setTimeout(() => {
          this.anonymous();
          this.key++;   
          return;
        }, 500);
      }
      if(this.userToken != null)
      setTimeout(async () => {
        await this.userFavorite(this.course);
        this.key++;
      }, 500);
      this.loader = false;
    },
    async userFavorite() {
      try {
        if (!this.favorite) {
          await this.add();
          this.$store.commit("ADD_FAVORİTE",this.course.id)
        } else if (this.favorite) {
          await this.remove();
          this.$store.commit("DELETE_FAVORİTE",this.course.id)
        }
        this.show = true;
      } catch (err) {
        console.log(err);
      }
    },
    anonymous() {
      this.favorite = existData(this.courseData.id);
      if (!this.favorite) {
        addFav(this.courseData);
        this.$store.commit("ADD_FAVORİTE",this.course.id)
        return;
      } else {
        removeFav(this.courseData.id);
        this.$store.commit("DELETE_FAVORİTE",this.course.id)
        return;
      }
    },
    async add() {
      await this.$store.dispatch("addFavorite", this.course.id);
    },
    async remove() {
      await this.$store.dispatch("deleteFavorite", this.course.id);
    },
    otherPageBtn() {
        this.$router.push("/kurs-detay/" + this.course.slug);
    },
    shareBtn() {
      let link =
        "https://cdnbaugo.ubit.com.tr/kurs-detay/" +
        (this.$route.params.slug || this.course.slug);
      this.$store.commit("SET_SHARE", link);
    },
    userOrAnonymous() {
      this.favorite = this.course ? (this.course.favorite || this.favorite) : this.favorite;
      let fn = async () => {
        try {
          this.course.favorite = this.$store.getters.checkId(this.course.id);
          console.log(this.course.favorite);
        } catch (err) {
          console.log(err);
        }
      };
      this.user = getValue();
      if (this.user && this.user.access_token) {
        this.userToken = getValue().access_token;
        (async () => await fn())
      }
      else {
        this.favorite = existData(this.courseData.id);
      }
    }
  },
  computed: {
    courseData: {
      get() {
        return this.course;
      },
    },
  },
  favorite:{
    get(){return this.$store.getters.checkId(this.course.id)},
    set(val){
        return val
    }
}

};
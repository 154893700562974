<template>
  <div>
    <b-modal
      id="DeleteModal"
      entrance-transition="animated fadeIn"
      leave-transition="animated bounceOut"
      centered
      :hide-footer="true"
    >
      <template #modal-header="{ close }" class="container">
        <!-- Emulate built in modal header close button action -->
        <h3>Uyarı</h3>
        <button type="button" aria-label="Close" class="close" @click="close()">
          <i class="ri-close-line color-third"></i>
        </button>
      </template>
      <template>
      
        <div>Silmek istediğinize emin misiniz?</div>
        <div class="d-flex justify-content-end mt-4">
          <button type="button" class="btn d-flex btn-link mr-3"  @click="$bvModal.hide('DeleteModal')">Kapat</button>
          <button type="button" class="btn d-flex btn-danger" @click="deleteComment">Sil</button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'DeleteModal',
    props: ['commentId'],
  methods: {
     successModal() {
      this.$bvModal.show("SuccessModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("SuccessModal");
      }, modalTimeoutSeconds * 1000);
    },
    dangerModal(){
      this.$bvModal.show("DangerModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("DangerModal");
      }, modalTimeoutSeconds * 1000);
    },
    async deleteComment() {
      await this.axios.delete('/course/discussion/delete/' + this.commentId)
        .then(response => {
          response;
          this.successModal()
        })
        .catch(error => {
          console.log(error.data)
           this.dangerModal()
        })

      this.$bvModal.hide('DeleteModal')

      this.$emit('deleteComment')
    }
  }
}
</script>

<template>
  <div class="main-content">
    <div class="container">
      <h2 class="gradient-color mb-4">Satın Alma Geçmişi</h2>
      <b-tabs content-class="py-4">
        <b-tab title="Satın Alma" active>
          <div>
            <!-- User Interface controls -->
            <b-row class="table-property">
              <b-col sm="3" md="2" class="mb-2 mr-auto">
                <b-form-group>
                  <template>
                    <div>
                      <multiselect placeholder :searchable="false" :select-label="''" :selected-label="''" deselect-label :allow-empty="false" id="per-page-select" v-model="perPage" :options="pageOptions" @input="fetchFilter">
                        <span slot="noResult">Sonuç bulunamadı</span>
                      </multiselect>
                    </div>
                  </template>
                </b-form-group>
              </b-col>
              <b-col sm="9" md="6" lg="3" class="mb-2">
                <b-form-group>
                  <b-input-group>
                    <b-form-input id="filter-input" v-model="title" type="search" placeholder="Arama..." @input="fetchFilter"></b-form-input>

                    <b-input-group-append>
                      <b-button @click="reset">Sıfırla</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Main table element -->
            <b-table empty-filtered-text="Sonuç bulunamadı." empty-text="Tabloda herhangi bir veri mevcut değil." borderless striped :items="data" :fields="fieldsTwo" :current-page="1" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty>
              <template #cell(item)="row">
                <router-link :to="{ name: 'makbuz', params: { id: row.value.document } }">
                  {{ row.value.items[0].course.title }}
                  <b>
                    {{
                    row.value.items.length - 1
                    ? "ve " + (row.value.items.length - 1 + " kurs daha")
                    : ""
                    }}
                  </b>
                </router-link>
              </template>
              <!--Onaylandi -->

              <!--Reddedildi-->
              <!-- <template #cell(iconsName)="row">
          <i class="ri-close-circle-fill color-red mr-2 ri-lg align-middle top-minus-2"></i><a href="#" target="blank">{{ row.value.courseName}}</a>
              </template>-->

              <!--Onay Asamasinda -->
              <!-- <template #cell(iconsName)="row">
          <i class="ri-indeterminate-circle-fill color-third mr-2 ri-lg align-middle top-minus-2"></i><a href="#" target="blank">{{ row.value.courseName}}</a>
              </template>-->
              <template #cell(price)="row">
                <router-link :to="{ name: 'makbuz', params: { id: row.value.document } }">{{ row.value.paid + "TL" }}</router-link>
              </template>
              <template #cell(date)="row">
                <router-link :to="{ name: 'makbuz', params: { id: row.value.document } }">{{ row.value.item }}</router-link>
              </template>
              <template #cell(PaymentType)="row">
                <router-link :to="{ name: 'makbuz', params: { id: row.value.document } }">Kredi Kartı</router-link>
              </template>
              <template #cell(document)="row">
                <router-link :to="{ name: 'makbuz', params: { id: row.value.document } }">Makbuz</router-link>
              </template>
            </b-table>
            <b-col sm="12" class="mt-5">
              <b-pagination-nav v-model="currentPage" :number-of-pages="page || 1" :per-page="allData.perPage || 25" align="center" size="sm" use-router :link-gen="linkGen" class="my-0" first-text prev-text next-text last-text></b-pagination-nav>
            </b-col>
          </div>
          <!-- <div  class="my-3 text-center color-third">
                  <div class="mb-1">
                    <i class="ri-shopping-cart-line ri-5x color-third"></i>
                  </div>
                  <div class="h4">Henüz kurs satın alınmadı.</div>
          </div>-->
        </b-tab>

        <!--<b-tab title="İade">
            <DataTable :items="items" :fields="fields">
              </DataTable>
               <div class="my-3 text-center color-third">
                  <div class="mb-1">
                    <i class="ri-hand-coin-line ri-5x color-third"></i>
                  </div>
                  <div class="h4">Henüz iade bulunmuyor.</div>
              </div>
        </b-tab>-->
      </b-tabs>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "SatinAlmaGecmisi",
  data() {
    return {
      fieldsTwo: [
        { key: "item", label: "Kurs Adı", sortable: true },
        { key: "date", label: "Tarih", sortable: true },
        { key: "price", label: "Tutar", sortable: true },
        { key: "PaymentType", label: "Ödeme Türü" },
        { key: "document", label: "Belgeler" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      data: [],
      allData: {},
      title: "",
      page: 1,
      debounce: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {},
  created() {
    this.fetchFilter();
  },
  methods: {
    fetchFilter() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.axios
          .get("/payment/purchase-history", {
            params: {
              perPage: this.perPage,
              page: this.currentPage,
              title: this.title,
            },
          })
          .then((res) => {
            this.allData = res.data;
            let data = res.data.data;
            this.page = Math.ceil(this.allData.total / this.allData.per_page);
            if (data == null || data == "") return;
            this.data = data.map((x) => {
              let items = x.items || "";
              let date = x.created_at || "";
              let paid = x.paid || "";
              let document = x.transaction_id || "";
              return {
                item: { items: items, document: document },
                date: { item: this.configDate(date), document: document },
                price: { paid: paid, document: document },
                PaymentType: { title: "Kredi Kartı", document: document },
                document: { document: document },
              };
            });
          });
      }, 200);
    },
    reset() {
      this.perPage = 25;
      this.currentPage = 1;
      this.title = null;
      this.fetchFilter();
    },
    configDate(created_at) {
      return moment(created_at).format("DD.MM.YYYY");
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
  },
  watch: {
    currentPage() {
      this.fetchFilter();
    },
  },
};
</script>
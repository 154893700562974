<template>
  <div class="main-content">
    <div class="container large">
      <h2 class="gradient-color mb-4">
        {{ course.title }}
      </h2>
      <div class="row">
        <div class="col-12 col-lg-9 pb-4 pb-lg-0 mb-5 mb-lg-0">
          <!-- Video start -->
          <div>
            <template>
              <div class="video" ref="videoPlayer"></div>
            </template>
          </div>
          <!-- Video End -->
          <!-- Kurs bitiminde gostermek icin d-none yerine d-flex gelmeli -->
          <div
            class="
              text-center
              embed-responsive embed-responsive-16by9
              align-items-center
              justify-content-center
              d-none
            "
          >
            <div>
              <div>
                <i class="ri-checkbox-circle-fill ri-5x color-green"></i>
              </div>
              <div class="h3 d-block">Bu kursu tamamladınız!</div>
              <router-link
                class="btn btn-secondary d-block d-sm-inline-block mt-5"
                to="/kurslarim"
                >Daha fazla kurs bul</router-link
              >
            </div>
          </div>
          <!-- -->
          <div class="curriculum-tabs mt-4 position-relative">
            <div class="buttons-for-tabs d-flex">
              <favorite-button
                v-if="user && user.access_token"
                class="btn btn-link d-flex align-items-center ml-xl-2"
                :course="course"
              ></favorite-button>
              <div class="d-flex align-items-center ml-xl-2">
                <button
                  v-b-modal.ShareModal
                  @click="share"
                  type="button"
                  class="btn btn-link"
                >
                  <i
                    class="ri-share-fill mr-0 mr-sm-2 align-bottom color-first"
                  ></i>
                  <span class="d-none d-sm-inline-block"> Paylaş </span>
                </button>
              </div>
            </div>
            <b-tabs content-class="py-5">
              <div class="row">
                <div class="col-12 col-md-8 offset-md-2">
                  <b-tab title="Soru & Cevap">
                    <div class="mb-5 pb-1">
                      <div class="mb-4 pb-3">
                        <b-form-group>
                          <b-form-textarea
                            class="overflow-auto"
                            id="textarea"
                            rows="5"
                            max-rows="6"
                            v-model="comment"
                            @keyup="remainCharCount(comment, 0)"
                            maxlength="500"
                          ></b-form-textarea>
                        </b-form-group>
                        <div>
                          <div class="d-flex">
                            <p class="d-flex align-items-top mb-0 color-third">
                              {{ commentRemaincharactersText }}
                            </p>
                            <button
                              type="button"
                              class="
                                btn btn-primary
                                d-flex
                                align-items-center
                                ml-auto
                              "
                              @click="saveComment(null, comment)"
                            >
                              Gönder
                            </button>
                          </div>
                        </div>
                        <discussion
                          :discussions="discussions"
                          :commentsToShow="commentsToShow"
                          :modalMode="generalModalMode"
                          :remaincharacters.sync="remaincharactersText"
                          :active.sync="activeItem"
                          :id.sync="discussionId"
                          :commentaryId.sync="commentId"
                          :selectedValue.sync="selected"
                          :messageValue.sync="message"
                          :remainCharCount="remainCharCount"
                          :saveComment="saveComment"
                        ></discussion>
                      </div>
                      <!--<div class="spinner text-center py-4">
                        <b-spinner
                          label="Spinning"
                          class="color-third"
                        ></b-spinner>
                      </div>-->
                    </div>
                  </b-tab>
                </div>
                <div class="col-12 col-md-8 offset-md-2">
                  <b-tab title="Duyurular">
                    <announcement
                      :language="course.language_code"
                      :announcements="announcements"
                    ></announcement>
                  </b-tab>
                </div>
                <div class="col-12 col-md-8 offset-md-2">
                  <b-tab title="Genel Bakış">
                    <overview
                      :language="course.language_code"
                      :earnings="info.information"
                      :certificates="course.certificates"
                      :description="course.description"
                    ></overview>
                  </b-tab>
                </div>
              </div>
            </b-tabs>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="sidebar">
            <detail
              :progress="progress"
              :detailBtn="progressModal"
              :setRating="setRating"
              :point.sync="rating"
            ></detail>
            <div class="panel panel-default">
              <div class="search-input position-relative w-100 mb-3">
                <b-form-input
                  class="mr-sm-2 w-100"
                  placeholder="Derslerde Ara..."
                  v-model.trim="searchQuery"
                ></b-form-input>
                <button class="btn">
                  <i class="ri-search-line"></i>
                </button>
              </div>
              <sections
                :sections="filteredSections"
                :src.sync="resource"
                :player="player"
                :moots="moots"
                :sectionMoot="sectionMoot"
                :mootsToShow="mootsToShow"
                :mootRemaincharactersText="mootRemaincharactersText"
                :searchQuery="searchQuery"
                :saveVideoLastTime="saveVideoLastTime"
                :convertSeconds="convertSeconds"
                :courseId="course_id"
                v-model="currentTime"
                :showQuizAndPoll="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <!-- Questions Modal -->
    <Questions
      ref="questionModal"
      :showQuestion="showQuestion"
      :selectClass="selectClass"
      :selectAnswer="selectAnswer"
      :saveAnswer="saveAnswer"
      :answer="answer"
      :reply.sync="openAnswer"
    >
    </Questions>
    <GeneralModal>
      <template v-slot:GeneralModalTitle>
        <div v-if="generalModalMode == 'report'">
          <h3>İlerleme Durumu</h3>
        </div>
        <div v-else>
          <h3>Kötüye Kullanım Bildir</h3>
        </div>
      </template>
      <template v-slot:GeneralModalContent>
        <div v-if="generalModalMode == 'report'">
          <div class="mb-4 pb-2">
            <button
              type="button"
              class="btn btn-block btn-secondary"
              @click="resetProgress"
            >
              İlerleme Durumunu Sıfırla
            </button>
          </div>
          <div
            v-if="success != null"
            class="alert alert-success mb-4 float-bottom"
            role="alert"
          >
            <div class="alert-content w-100 d-inline-block">
              <div class="my-1">
                <strong>
                  {{ success }}
                </strong>
              </div>
            </div>
          </div>
          <div
            v-if="error != null"
            class="alert alert-error mb-4 float-bottom"
            role="alert"
          >
            <div class="alert-content w-100 d-inline-block">
              <strong>
                {{ error }}
              </strong>
            </div>
          </div>
          <div
            v-for="(section, index) in course.sections"
            :key="section.id + 'section'"
            class="mb-3 d-flex align-items-center"
          >
            <router-link v-if="index < 5" to="" class="d-flex">
              <span
                class="wh-24 mr-2 ri-lg top-minus-2 d-flex align-items-center"
              >
                <i class="ri-checkbox-circle-fill color-green"></i>
              </span>
              {{ section.title }}
            </router-link>
            <router-link v-else to="" class="d-flex color-second">
              <span
                class="wh-24 mr-2 ri-lg top-minus-2 d-flex align-items-center"
              ></span>
              {{ section.title }}
            </router-link>
          </div>
        </div>
        <div v-else>
          <div
            v-if="complainted.includes(discussionId)"
            class="alert alert-success mb-4"
            role="alert"
          >
            <div class="alert-content w-100 d-inline-block">
              <div class="my-1">
                <strong> Talebiniz başarıyla gönderildi </strong>
              </div>
            </div>
          </div>
          <template v-else>
            <b-form-group label="Sorun Türü">
              <template>
                <div>
                  <multiselect
                    placeholder="Seçiniz"
                    v-model="selected"
                    :options="options1"
                    :select-label="''"
                    :selected-label="''"
                    deselect-label=""
                    :allow-empty="false"
                  >
                    <span slot="noResult"> Sonuç bulunamadı </span>
                  </multiselect>
                </div>
              </template>
            </b-form-group>
            <b-form-group>
              <b-form-textarea
                class="overflow-auto"
                id="textarea"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <button
              type="button"
              class="btn d-flex btn-primary ml-auto"
              @click="complaint"
            >
              Gönder
            </button>
          </template>
        </div>
      </template>
    </GeneralModal>
    <DeleteModal
      :commentId="commentId"
      v-on:deleteComment="fetchDiscussions"
    ></DeleteModal>
    <ModalXl
      :sectionMoot="sectionMoot"
      :language="course.language_code"
      :mootsToShow="mootsToShow"
      :mootRemaincharactersText="mootRemaincharactersText"
      :debate="moot"
      :remainCharCount="remainCharCount"
      :saveMoot="saveMoot"
    ></ModalXl>
    <SuccessModal></SuccessModal>
    <DangerModal></DangerModal>
  </div>
</template>

<script>
//import StarsRating from "stars-rating-component-vue"
import Artplayer from "artplayer";
import { getValue } from "../core/userData";
import favoriteButton from "../components/ui/FavoriButton.vue";
import discussion from "../components/ui/Discussion.vue";
import Announcement from "../components/ui/Announcement.vue";
import Overview from "../components/ui/Overview.vue";
import Detail from "../components/ui/Detail.vue";
import { getterLastTime, addLastTime } from "../core/getLastTime";
import { getLastSection } from "../core/lastSection";

export default {
  name: "EgitimVideo",
  components: {
    favoriteButton,
    discussion,
    Announcement,
    Overview,
    Detail,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
          controls: true,
          responsive: true,
        };
      },
    },
  },
  data() {
    return {
      course_id: 0,
      user: {},
      info: [],
      course: {},
      questions: {},
      discussions: {},
      moots: {},
      sectionId: null,
      announcements: {},
      activeItem: null,
      currentTime: null,
      rating: null,
      progress: null,
      discussionId: null,
      complainted: [],
      timer: "",
      selected: null,
      options1: ["Seçenek 1", "Seçenek 2", "Seçenek 3"],
      generalModalMode: null,
      player: null,
      showQuestion: null,
      showTimes: [],
      hideTimes: [],
      stopTimingsIndex: 0,
      optionsKey: ["A)", "B)", "C)", "D)", "E)"],
      selectedAnswer: null,
      answer: null,
      openAnswer: "",
      checkedList: [],
      videoTime: 78,
      resource: "",
      playbackRates: [0.5, 1, 1.5, 2],
      message: "",
      comment: "",
      moot: "",
      commentId: null,
      maxcharacter: 500,
      remaincharactersText: "500",
      commentRemaincharactersText: "500",
      mootRemaincharactersText: "500",
      searchQuery: null,
      commentsToShow: [],
      mootsToShow: 2,
      sectionMoot: {},
      lastMoment: { section: 0, index: 0 },
    };
  },
  created() {
    let result = getLastSection();
    if (result != null && result != "") {
      this.lastMoment = result;
    }
    this.fetchCourse();
    const userData = getValue();
    if (userData && userData.user) {
      this.user = userData;
    }
  },
  methods: {
    saveVideo() {
      this.saveVideoLastTime();
    },
    progressModal() {
      this.generalModalMode = "report";
      this.success = null;
      this.error = null;
    },
    remainCharCount(commentText, commentDepth, moot) {
      if (commentText.length > this.maxcharacter) {
        this.remaincharactersText = 0;
        this.commentRemaincharactersText = 0;
      } else {
        if (commentDepth == 0) {
          if (moot == "moot") {
            this.mootRemaincharactersText =
              this.maxcharacter - commentText.length;
          } else {
            this.commentRemaincharactersText =
              this.maxcharacter - commentText.length;
          }
        } else if (commentDepth == 1) {
          this.remaincharactersText = this.maxcharacter - commentText.length;
        }
      }
    },
    remaincharCount() {
      if (this.message.length > this.maxcharacter) {
        this.remaincharactersText = 0;
      } else {
        var remainCharacters = this.maxcharacter - this.message.length;
        this.remaincharactersText = remainCharacters;
      }

      if (this.comment.length > this.maxcharacter) {
        this.remaincharactersText = 0;
      } else {
        var commentRemainCharacters = this.maxcharacter - this.comment.length;
        this.commentRemaincharactersText = commentRemainCharacters;
      }
    },
    trackTime(e) {
      this.currentTime = Math.trunc(e.target.currentTime);
      console.log(Math.trunc(e.target.currentTime));

      if (
        Math.trunc(e.target.currentTime) ==
        this.showTimes[this.stopTimingsIndex]
      ) {
        this.player.pause();
        this.showQuestion = this.questions[this.stopTimingsIndex];
        this.answer = null;
        this.$bvModal.show("Questions");
        this.$nextTick(() => {
          document
            .querySelector(".art-video-player")
            .append(document.querySelector("#Questions___BV_modal_outer_"));
        });

        this.stopTimingsIndex++;
        // $('.curriculum-wrapper input.check-group').prop( "checked" )
      }

      if (
        this.currentTime + 15 <
        this.videoTime /*this.convertSeconds(this.resource.video.duration))*/
      ) {
        this.checkedList.splice(this.checkedList.indexOf(1), 1);
      } else {
        if (!this.checkedList.includes(1)) {
          this.checkedList.push(1);
        }
      }
    },
    fetchCourse() {
      this.axios
        .get("course/detail/" + this.$route.params.slug)
        .then((response) => {
          this.course = response.data.data.course;
          this.info = response.data.data.info;
          this.favorite = response.data.data.favorite;
          this.rating = response.data.data.rating;
          this.progress = response.data.data.progress;
          this.resource =
            response.data.data.course.sections[
              this.lastMoment.section
            ].resources[this.lastMoment.index];
          this.course_id = response.data.data.course.id;

          this.createPlayer(this.resource.videos)
          this.course.sections.forEach((section) => {
            section.resources.forEach((resource) => {
              if (!resource.video.progress) {
                resource.video.progress = {
                  is_completed: null,
                };
              }
            });
          });

          this.fetchVideoLastTime(
            response.data.data.course.sections[0].resources[0].id
          );
          this.fetchQuestions();
          this.fetchDiscussions();
          this.fetchMoots();
          this.fetchAnnouncements();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchDiscussions() {
      await this.axios
        .get("course/" + this.course.id + "/discussion")
        .then((response) => {
          console.log(response.data.data);
          this.discussions = response.data.data.reverse();
          console.log(this.discussions);
          for (var index = 0; index < response.data.data.length; index++) {
            if (this.commentsToShow.length < response.data.data.length) {
              this.commentsToShow.unshift(2);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchAnnouncements() {
      this.axios
        .get("course/" + this.course.id + "/announcements")
        .then((response) => {
          this.announcements = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveComment(discussId, comment) {
      this.axios
        .post("/course/discussion", {
          course_id: this.course.id,
          content: comment,
          discussion_id: discussId ? discussId : "",
        })
        .then((response) => {
          console.log(response.data);
          this.resetInputs();
          this.fetchDiscussions();
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    saveMoot(id) {
      this.axios
        .post("/course/moot", {
          course_moot_id: id,
          subject: "subject",
          message: this.moot,
        })
        .then((response) => {
          console.log(response.data);

          this.sectionMoot.posts.push({
            course_moot_id: id,
            subject: "subject",
            message: this.moot,
          });

          this.resetInputs();
          this.fetchMoots();
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    async fetchMoots() {
      await this.axios
        .get("/course/" + this.course.id + "/moot")
        .then((response) => {
          this.moots = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchRating() {
      this.axios
        .get("/course/" + this.course.id + "/rating")
        .then((response) => {
          this.rating = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    setRating() {
      this.axios
        .post("/course/rating", {
          course_id: this.course.id,
          rating: this.rating,
        })
        .then((response) => {
          this.rating = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    removeRating() {
      this.axios
        .delete("/course/rating", {
          course_id: this.course.id,
        })
        .then((response) => {
          this.rating = response.data.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    fetchVideoLastTime(id) {
      if (!this.currentTime) {
        this.axios
          .get("/course/resource/" + id)
          .then((response) => {
            if (response.data.data.video.progress != null)
              this.player.currentTime = this.convertSeconds(
                response.data.data.video.progress.last_time
              );
            else this.player.currentTime = null;
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      }
    },
    saveVideoLastTime(id, second) {
      let videoLastTime = new Date();
      videoLastTime.setHours(0);
      videoLastTime.setMinutes(0);

      if (second != null) videoLastTime.setSeconds(second);
      else videoLastTime.setSeconds(this.currentTime);

      let defaultId = id || this.resource.id;
      addLastTime({
        time: videoLastTime.toTimeString().split(" ")[0],
        id: defaultId,
      });

      this.axios
        .post("/course/resource/last-time", {
          resource_id: id ? id : this.resource.id,
          last_time: videoLastTime.toTimeString().split(" ")[0],
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    fetchQuestions() {
      this.axios
        .get("/course/video/question/1")
        .then((response) => {
          this.questions = response.data.data;

          this.questions.forEach((item) => {
            this.showTimes.push(this.convertSeconds(item.show_time));
            this.hideTimes.push(this.convertSeconds(item.hide_time));
          });
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    convertSeconds(time) {
      var a = time.split(":");
      var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

      return seconds;
    },
    share() {
      let link =
        "https://cdnbaugo.ubit.com.tr/kurs-detay/" + this.$route.params.slug;
      this.$store.commit("SET_SHARE", link);
    },
    complaint() {
      if (!this.complainted.includes(this.discussionId)) {
        this.axios
          .post("/course/discussion/complaint", {
            discussion_id: this.discussionId,
            reason: this.selected,
          })
          .then((response) => {
            console.log(response.data);
            this.complainted.push(this.discussionId);
          })
          .catch((error) => {
            console.log(error.data);
          });
      }
    },
    resetInputs() {
      this.comment = "";
      this.moot = "";
      this.message = "";
      this.remaincharactersText = "500";
      this.commentRemaincharactersText = "500";
      this.mootRemaincharactersText = "500";
      this.activeItem = null;
    },
    selectAnswer(optId) {
      if (this.answer == null) {
        this.selectedAnswer = optId;
      }
    },
    saveAnswer(questType) {
      if (this.answer == null) {
        this.axios
          .post("/course/video/question/answer", {
            question_id: this.showQuestion.id,
            answer:
              questType == "multiple-choice"
                ? this.selectedAnswer
                : this.openAnswer,
          })
          .then((response) => {
            console.log(response.data);
            this.answer = response.data.data;
          })
          .catch((error) => {
            console.log(error.data);
          });
      }
    },
    selectClass(optId) {
      if (this.answer == true && optId == this.selectedAnswer) {
        return "correct";
      }
      if (this.answer == false && optId == this.selectedAnswer) {
        return "wrong";
      }
      if (!this.answer && optId == this.selectedAnswer) {
        return "selected";
      }
    },
    successModal() {
      this.$bvModal.show("SuccessModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("SuccessModal");
      }, modalTimeoutSeconds * 1000);
    },
    dangerModal() {
      this.$bvModal.show("DangerModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("DangerModal");
      }, modalTimeoutSeconds * 1000);
    },
    resetProgress() {
      this.axios
        .post("/course/1/progress", {
          course_id: this.course.id,
        })
        .then((response) => {
          console.log(response);
          this.successModal();
        })
        .catch((error) => {
          console.log(error);
          this.dangerModal();
        });
    },
    when403Error() {
      this.axios
        .get("/course/resource/" + this.resource.id)
        .then((res) => {
          this.player.src(res.data.data.videos[0].src);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createPlayer(quality){
      this.player = new Artplayer({
      container: this.$refs.videoPlayer,
      autoplay: true,
      fullscreen: true,
      quality:quality
    });
    /* this.player.on("error", () => {
      let error = this.player.error();
      if (error.code == 2 || error.code == 4) {
        this.when403Error();
      }
      return;
    });*/
    this.player.on("play", () => {
      window.addEventListener("beforeunload", this.saveVideo);
    });
    this.player.on("ready", () => {
      this.player.on("video:timeupdate", this.trackTime);
      this.player.on("video:ended", () => {
        addLastTime({ time: "00:00:00", id: this.resource.id });
      });
    });
    this.player.on("destroy", () => {
      this.player;
    });
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
    this.saveVideoLastTime();
    clearInterval(this.timer);
    window.removeEventListener("beforeunload", this.saveVideo);
  },
  computed: {
    filteredSections() {
      if (!this.searchQuery) {
        return this.course.sections;
      }
      return this.course.sections.filter((section) => {
        let filteredResources = section.resources.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.title.toLowerCase().includes(v) ||
                item.video.title.toLowerCase().includes(v)
            );
        });
        return filteredResources.length > 0;
      });
    },
  },
  watch: {
    resource(val) {
      if (val && val.videos) {
        this.player.url = val.videos[0].url;
        this.player.on("ready", () => {
          let lastTime = getterLastTime(val.id);
          console.log(lastTime);
          this.player.currentTime = lastTime;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.video {
  height: 500px;
  width: 100%;
}
</style>

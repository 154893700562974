<template>
  <div class="main-content">
    <div class="container">
      <h2 class="gradient-color mb-4">Tüm Kategoriler</h2>
      <div>
        <div>
          <div class="row" v-if="categories.length>0">
            <div
              class="col-12 col-sm-6 col-md-4 col-lg-3 mb-30"
              v-for="(category, i) in categories"
              :key="i"
            >
              <article class="card position-relative pic-card">
                <span
                  class="
                    badge badge-success
                    position-absolute
                    left-0
                    top-0
                    mt-3
                    ml-3
                  "
                  >Yeni</span
                >

                <a href="#" class="h-100">
                  <div
                    class="card-img-top h-100 bg-image"
                    :style="{
                      backgroundImage: 'url(' + category.image_link || '' + ')',
                    }"
                  >
                    <div
                      class="
                        font-weight-bold
                        position-absolute
                        left-0
                        bottom-0
                        p-4
                        pt-5
                        pic-card-title
                      "
                    >
                      <div class="pt-5">{{category.category}}</div>
                      <div class="mt-1 white-60" v-pre>Bilgisayar Mühendisi</div>
                    </div>
                  </div>
                </a>
              </article>
            </div>
          </div>
          <!-- row end -->
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import axios from "axios";
// import StarRating from "vue-star-rating";

export default {
  name: "TümKategoriler",
  data() {
    return {
      categories: [],
    };
  },
  created() {
    axios
      .get("/course/categories")
      .then((res) => (this.categories = res.data.data))
      .catch((err) => console.log(err));
  },
};
</script>
<template>
  <div>
    <article class="card position-relative pic-card d-flex flex-column">
      <a href="#" class="h-100">
        <div
          class="card-img-top h-100 bg-image"
          :style="{ backgroundImage: 'url(' + user.picture_link + ')' }">
            <div
              class="
                font-weight-bold
                position-absolute
                left-0
                bottom-0
                p-4
                pt-5
                pic-card-title
              "
            >
              <div class="pt-5">{{ user.full_name || "" }}</div>
              <!--<div class="mt-1 white-60">Bilgisayar Mühendisi</div>-->
            </div>
        </div>
      </a>
    </article>
  </div>
</template>

<script>
export default {
  props: {
    user: { default: {} },
  },
  data() {
    return {
      CardImage7:
        "https://productimages.hepsiburada.net/s/40/550/10650895351858.jpg",
    };
  },
  watch: {
    user(val) {
      console.log(val);
    },
  },
};
</script>
<template>
  <div class="main-content">
    <div class="container">
      <h2 class="gradient-color mb-4">Makbuz</h2>
      <div class="d-flex justify-content-between flex-column flex-md-row mb-5">
        <div class="pr-0 pr-md-3 mb-4 mb-md-0">
          <div class="mb-1 h4">BauGo</div>
          <!--<div class="mb-1">
            600 Harrison Street, 3rd Floor San Francisco, CA 94107, US
          </div>
          <router-link to="/">baugo.com</router-link>-->
        </div>
        <div class="pl-0 pl-md-3">
          <div class="mb-1">
            <b>Alıcı:</b>
            {{this.data.user?this.data.user.full_name:""}}
          </div>
          <div class="mb-1">
            <b>Tarih:</b>
            {{this.configDate(this.data.created_at)||""}}
          </div>
          <div class="mb-1">
            <b>Sipariş No:</b>
            {{this.$route.params.id}}
          </div>
        </div>
      </div>
      <div class="standard-table hide-empty-content no-pagination">
        <b-table empty-filtered-text="Sonuç bulunamadı." empty-text="Tabloda herhangi bir veri mevcut değil." borderless striped :items="this.arr" :fields="fieldsReceipt" :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty>
          <template #cell(name)="row">
            <a href="#" target="blank">{{ row.value.courseName }}</a>
          </template>
        </b-table>
      </div>
      <div class="text-small mt-5 pt-5 text-center">
        <router-link to="/iletisim" class="color-second">
          Bu makbuz hakkında sorunuz varsa lütfen destek ekibimizle iletişim
          kurun
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Makbuz",
  data() {
    return {
      itemsReceipt: [{ title: "", date: "06 Kasım 2019", price: "54,99₺" }],
      fieldsReceipt: [
        { key: "title", label: "Kurs Adı", sortDirection: "desc" },
        { key: "date", label: " " },
        { key: "price", label: "Tutar", tdClass: "bold-cell" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      title: "",
      arr: [],
      data: {},
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.fetchFilter();
  },
  methods: {
    fetchFilter() {
      this.axios.get("/payment/detail/" + this.$route.params.id).then((response) => {
        this.data = response.data;
        const { data } = response;
        if (data == null || data == "") return;
        let courses = data.items.map((x) => {
          let title = x.course.title || "";
          let price = x.course.price || "";
          return {
            title: title,
            price: price + "TL",
            document: { document: document },
          };
        });
        this.arr = [
          ...courses,
          { title: "", date: "Toplam Tutar", price: this.data.amount + "TL" },
        ];
      });
    },
    configDate(created_at) {
      return moment(created_at).format("DD.MM.YYYY");
    },
  },
};
</script>
<template>
  <div>
    <b-modal
      id="ModalXl"
      centered
      :hide-footer="true"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header="{ close }" class="container">
        <h3>
          {{ sectionMoot.subject }}
        </h3>
        <button type="button" aria-label="Close" class="close" @click="close()">
          <i class="ri-close-line color-third"></i>
        </button>
      </template>
      <template>
        <div class="mb-5 pb-1">
          <div class="mb-4 pb-3">
            <div v-if="sectionMoot.user" class="mb-4">
              <a>
                <div class="d-flex align-items-center">
                  <div
                    class="wh-48 rounded-circle bg-image mr-3"
                    :style="
                      sectionMoot.user
                        ? {
                            backgroundImage:
                              'url(' + sectionMoot.user.picture_link + ')',
                          }
                        : {
                            backgroundImage:
                              'url(http://ui.ubit.com.tr/profile-picture.jpg)',
                          }
                    "
                  ></div>
                  <div class="educator-info">
                    <div class="text-capitalize text-truncate">
                      {{ sectionMoot.user.full_name }}
                    </div>
                    <div
                      class="
                        color-second
                        text-capitalize text-truncate
                        font-weight-normal
                        mb-0
                      "
                    >
                      <time-ago
                        :locale="language"
                        :datetime="sectionMoot.created_at"
                        :long="true"
                      ></time-ago>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="announcement mb-5">
              <div v-html="sectionMoot.message"></div>
            </div>
            <hr class="my-3" />
            <div
              v-for="(post, postIndex) in sectionMoot.posts"
              :key="'post' + post.id"
              class="comment-wrapper"
            >
              <template v-if="postIndex < mootsToShow">
                <div class="mb-3">
                  <div class="d-flex align-items-center">
                    <router-link to="#" class="d-flex align-items-center">
                      <div
                        class="wh-48 rounded-circle bg-image mr-3"
                        :style="
                          post.user
                            ? {
                                backgroundImage:
                                  'url(' + post.user.picture_link + ')',
                              }
                            : {
                                backgroundImage:
                                  'url(http://ui.ubit.com.tr/profile-picture.jpg)',
                              }
                        "
                      ></div>
                      <div class="educator-info">
                        <div class="text-capitalize text-truncate">
                          <template v-if="post.user">
                            {{ post.user.full_name }}
                            <span class="color-second mx-2">&middot;</span>
                            <span
                              class="brand-color text-smaller text-uppercase"
                            >
                              Eğitmen
                            </span>
                          </template>
                          <template v-else> BAUGO User </template>
                        </div>
                        <div
                          class="
                            color-second
                            text-capitalize text-truncate
                            font-weight-normal
                            mb-0
                          "
                        >
                          <time-ago
                            :locale="course.language_code"
                            :datetime="post.created_at"
                            :long="true"
                          ></time-ago>
                        </div>
                      </div>
                    </router-link>
                    <b-dropdown
                      id="dropdown-right"
                      right
                      text="Right align"
                      variant="link"
                      class="dropdown ml-auto after-none"
                    >
                      <template #button-content class="py-0">
                        <i class="ri-more-line ml-auto color-third"></i>
                      </template>
                      <template v-if="user.id == post.user_id">
                        <b-dropdown-item v-b-modal.DeleteModal>
                          <i
                            class="
                              ri-delete-bin-7-fill
                              mr-3
                              align-bottom
                              top-minus-1
                            "
                          ></i>
                          Sil
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </div>
                </div>
                <div class="announcement">
                  <div class="mb-3">
                    {{ post.message }}
                  </div>
                </div>
              </template>
            </div>
            <div
              v-if="sectionMoot.posts && sectionMoot.posts.length > mootsToShow"
              class="show-replys text-center color-third pb-5"
            >
              <router-link
                to=""
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  text-smaller
                "
                @click="mootsToShow += 2"
              >
                Daha fazla yanıt göster
                <i class="ri-arrow-down-line ml-2"></i>
              </router-link>
            </div>
            <div class="mb-4 pb-3">
              <b-form-group>
                <b-form-textarea
                  class="overflow-auto"
                  id="textarea"
                  rows="5"
                  max-rows="6"
                  v-model="moot"
                  @keyup="remainCharCount(moot, 0, 'moot')"
                  maxlength="500"
                ></b-form-textarea>
              </b-form-group>
              <div>
                <div class="d-flex">
                  <p class="d-flex align-items-top mb-0 color-third">
                    {{ mootRemaincharactersText }}
                  </p>
                  <button
                    type="button"
                    class="btn btn-primary d-flex align-items-center ml-auto"
                    @click="saveMoot(sectionMoot.id)"
                  >
                    Gönder
                  </button>
                </div>
              </div>
            </div>
            <!--
            <div class="spinner text-center py-4">
              <b-spinner label="Spinning" class="color-third"></b-spinner>
            </div>
            -->
          </div>
        </div>
        <!-- content -->
      </template>
    </b-modal>
  </div>
</template>
<script>
import TimeAgo from "vue2-timeago";
import {getValue} from "../../../core/userData"
export default {
  components: {
      TimeAgo
  },
  name: "ModalXl",
  props:{
      sectionMoot:{default:{}},
      language:{default:""},
      mootsToShow:{default:""},
      mootRemaincharactersText:{default:""},
      debate:{default:""},
      remainCharCount:{type:Function},
      saveMoot:{type:Function}
  },
  data(){
      return{   
          user:null
      }
  },
  computed:{
      moot:{
          get(){
              return this.debate
          },
          set(val){
              this.$emit('update:debate',val)
          }
      }
  },
  created() {
    const userData = getValue();
    if (userData && userData.user) {
      this.user = userData.user;
    }
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.announcements != null && _vm.announcements.length > 0)?_c('div',_vm._l((_vm.announcements),function(item){return _c('div',{key:item.id,staticClass:"mb-5 pb-1"},[_c('div',{staticClass:"mb-4"},[_c('a',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"wh-48 rounded-circle bg-image mr-3",style:(item.user
                ? {
                    backgroundImage: 'url(' + item.user.picture_link + ')',
                  }
                : {
                    backgroundImage:
                      'url(http://ui.ubit.com.tr/profile-picture.jpg)',
                  })}),_c('div',{staticClass:"educator-info"},[_c('div',{staticClass:"text-capitalize text-truncate"},[_vm._v(" "+_vm._s(item.user.full_name)+" ")]),_c('div',{staticClass:"\n                color-second\n                text-capitalize text-truncate\n                font-weight-normal\n                mb-0\n              "},[_c('time-ago',{attrs:{"locale":_vm.language,"datetime":item.created_at,"long":true}})],1)])])])]),_c('div',{staticClass:"announcement"},[_c('h3',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(item.announcement || '')}})])])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
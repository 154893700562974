<template>
  <div class="main-content">
    <div class="container" >
      <h1>Kullanım Şartları</h1>
      <div v-html="getHtml && getHtml.value ? getHtml.value : ''"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KullanimSartlari',
  computed:{
      getHtml: {
      get() {
        return this.$store.getters.getSettings("terms_of_use");
      },
    },
  }
}
</script>


<template>
  <div class="main-content">
    <div class="container">
      <h2 class="gradient-color mb-4">
        Bildirim Ayarları
      </h2>
      <div class="row">
        <div 
          class="col-12 col-md-6 mb-4 pr-md-5"
          v-for="(item, index) in settings" :key="index" 
        >
          <!--
          <h3 class="mb-4">{{ item.type }}</h3>
          -->
          <h3 class="mb-4">
            {{ fieldNameTr[index] }}
          </h3>
          <div class="form-group custom-control custom-switch pl-0">
            <label :for="item.type + 'email'" class="mr-3 pt-1 text-label">
              E-Posta
            </label>
            <input 
              :id="item.type + 'email'" 
              v-model="item.value.send_email" 
              true-value="on"
              false-value="off"
              @input="saveSettings(item, 'send_email', item.value.send_email)"
              type="checkbox" class="custom-control-input" 
            >
            <label :for="item.type + 'email'" class="custom-control-label"></label>
          </div>
          <div class="form-group custom-control custom-switch pl-0">
            <label :for="item.type + 'sms'" class="mr-3 pt-1 text-label">
              Sms
            </label>
            <input 
              :id="item.type + 'sms'" 
              v-model="item.value.send_sms" 
              true-value="on"
              false-value="off"
              @input="saveSettings(item, 'send_sms', item.value.send_sms)"
              type="checkbox" class="custom-control-input"
            >
            <label :for="item.type + 'sms'" class="custom-control-label"></label>
          </div>
          <div class="form-group custom-control custom-switch pl-0">
            <label :for="item.type + 'activity'" class="mr-3 pt-1 text-label">
              Bildirim
            </label>
            <input 
              :id="item.type + 'activity'"
              v-model="item.value.instantly_send"  
              true-value="on"
              false-value="off"
              @input="saveSettings(item, 'instantly_send', item.value.instantly_send)"
              type="checkbox" class="custom-control-input"
            >
            <label :for="item.type + 'activity'" class="custom-control-label"></label>
          </div>
        </div>
      </div>
    </div>
    <DangerModal></DangerModal>
    <SuccessModal></SuccessModal>
  </div>
</template>

<script>

export default {

  name: "BildirimAyarlari",
  data() {
    return {
      settings: null,
      fieldNameTr: ['Yorum', 'Hatırlatma', 'Video', 'Genel'],
    }
  },
  created() {
    this.fetchSettings()
  },
  methods: {
    async fetchSettings() {
      await this.axios('/notification/settings')
        .then(response => {
          this.settings = response.data.data
        })
        .catch(error => {
          console.log(error.data)
        })
    },
    successModal() {
      this.$bvModal.show("SuccessModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("SuccessModal");
      }, modalTimeoutSeconds * 1000);
    },
    dangerModal(){
      this.$bvModal.show("DangerModal");
      const modalTimeoutSeconds = 3;
      let modalSetTimeout = null;
      clearTimeout(modalSetTimeout);

      modalSetTimeout = setTimeout(() => {
        this.$bvModal.hide("DangerModal");
      }, modalTimeoutSeconds * 1000);
    },
    async saveSettings(item, field, value) {
      await this.axios.post('/notification/settings', {
        'type': item.type,
        'field': field,
        'value': value == 'on' ? 'off' : 'on'
      })
        .then(response => {
          console.log(response.data)
          this.fetchSettings()
         this.successModal()
        })
        .catch(error => {
          console.log(error.data)
          this.dangerModal()
        })
    }
  }
}
</script>





<template>
  <div class="row mb-5">
    <div class="col-12 col-sm-3 mb-3 mb-sm-0 pr-3 pr-sm-1 pr-md-3">
      <a @click="otherPageBtn()">
        <div
          class="bg-image embed-responsive embed-responsive-16by9 rounded"
          :style="{
            backgroundImage: 'url(' + this.course.picture_link + ')',
          }"
        ></div>
      </a>
    </div>
    <div class="col-12 col-sm-9 d-md-flex justify-content-between">
      <div class="cart-item-info d-flex flex-column pr-md-4">
        <div class="font-weight-bold ellipsis-2 mb-2">
          <a @click="otherPageBtn()" class="d-block">{{ course.title }}</a>
        </div>
        <div
          class="
            text-capitalize text-truncate
            ellipsis-2
            mb-2
            color-second
            white-space
            text-small
          "
          :class="{ 'ellipsis-2': !later, 'ellipsis-1': later }"
        >
          Eğitmen:
          <a
            class="color-second"
            >{{(courseData.instructor&& courseData.instructor.user && courseData.instructor.user.full_name) ?  courseData.instructor.user.full_name : ""}}
          </a>
        </div>
        <div class="cart-items-actions mt-2 mt-sm-auto">
          <favorite-button
            v-if="user && user.access_token"
            class="mr-5 color-second mb-2 mb-sm-0 d-inline-block"
            :course="course"
          ></favorite-button>
          <a
            v-if="later"
            href="#"
            class="mr-5 color-second hover-none"
            @click="moveBasketBtn"
            ><i
              class="ri-shopping-cart-2-fill mr-3 align-middle top-minus-1"
            ></i
            >Sepete Taşı</a
          >
          <a
            v-if="!later"
            href="#"
            class="mr-5 color-second d-inline-block"
            @click="addLaterCourseBtn"
            ><i
              class="
                ri-bookmark-fill
                mr-3
                color-second
                align-bottom
                top-minus-1
              "
            >
            </i
            >Sonrası İçin Kaydet</a
          >
          <a
            v-if="!later"
            @click="deleteCourseBtn(course.id)"
            style="cursor: pointer"
            class="color-second hover-none d-inline-block"
            ><i class="ri-close-fill mr-2 align-bottom"></i>Sil</a
          >
        </div>
      </div>
      <div class="cart-items-price pr-0 pr-md-3">
        <div class="align-items-center d-flex d-md-block mt-2 mt-sm-3 mt-md-0">
          <!-- <div class="h3 color-second mb-0 mr-3 mr-md-0">
                        <s>59,99₺</s>
                      </div> -->
          <div class="h3 mb-0">{{ course.price }}₺</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userOrAnonymousFavMixin from "../../mixin/userOrAnonymousFav";
import favoriteButton from "../ui/FavoriButton.vue";
export default {
  components: { favoriteButton },
  mixins: [userOrAnonymousFavMixin],
  props: {
    addLaterCourse: Function,
    moveBasket: Function,
    deleteCourse: Function,
    later: { defautValue: true },
  },
  mounted() {
    this.$store.commit("SET_LOADER", false);
  },
  data() {
    return {};
  },
  methods: {
    addLaterCourseBtn() {
      this.addLaterCourse(this.course);
    },
    deleteCourseBtn() {
      this.deleteCourse(this.course.id);
    },
    moveBasketBtn() {
      this.moveBasket(this.course);
    },
    anonymousUser() {
      this.anonymous();
      this.key++;
    },
  },
  computed: {
    courseData: {
      get() {
        return this.course;
      },
    },
    user() {
        return this.$store.state.user;
      },
  },
};
</script>
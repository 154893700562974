<template>
  <div>
    <h3 class="mb-4">Bu Kurs Hakkında</h3>
    <p>
      Vue Router & Vuex dahildir! Angular ve React 'ın en modern özelliklerine
      sahip JavaScript framework'ü VueJS 'i öğrenin!
    </p>
    <div class="row mt-4">
      <div class="col-12 col-sm-6 mb-2">
        <b>Yetkinlik düzeyi</b>
        <p>Tüm Düzeyler</p>
      </div>
      <div class="col-12 col-sm-6 mb-2">
        <!--<b>Öğrenci Sayısı</b>
        <p>5112</p>-->
      </div>
      <div class="col-12 col-sm-6 mb-2">
        <!--<b>Diller</b>
        <p>{{ language }}</p>-->
      </div>
      <div class="col-12 col-sm-6 mb-2">
        <!--<b>Dersler</b>
        <p>397</p>-->
      </div>
      <div class="col-12 col-sm-6 mb-2">
        <!--<b>Kazanım</b>
        <p>{{ earnings }}</p>-->
      </div>
      <div class="col-12 col-sm-6 mb-2">
        <!--<b>Sertifika</b>
        <div v-html="certificates || ''"></div>-->
      </div>
      <div class="col-12 col-md-2 mb-4">
        <b>Açıklama</b>
      </div>
      <div
        class="col-12 col-md-10 mb-4"
        v-html="description || ''"
      ></div>
      <!--
                      <div class="col-12 col-md-2 mb-4 mt-4">
                        <b>Eğitmen</b>
                      </div>
                      <div class="col-12 col-md-10 mb-4 mt-4">
                        <a href="#">
                          <div class="d-flex align-items-center">
                            <div
                              class="rounded-circle bg-image mr-4 wh-64"
                              style="background-image: url(http://ui.ubit.com.tr/profile-picture.jpg);"
                            ></div>
                            <div class="educator-info">
                              <div class="text-capitalize text-truncate h4 mb-1">
                                Cihan Yılmaz
                              </div>
                              <div class="color-second text-capitalize text-truncate font-weight-normal h4 mb-0">
                                Bilgisayar Mühendisi
                              </div>
                            </div>
                          </div>
                        </a>
                      </div> 
                      -->
    </div>
  </div>
</template>

<script>
export default {
    props:{
        language:{default:""},
        earnings:{default:""},
        certificates:{default:""},
        description:{default:""}
    }
}
</script>